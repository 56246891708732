import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const StyledTextWrapper = withResponsive(styled.a`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-basis: 33%;
		max-width: ${({ isThree }) => (isThree ? '33%' : '50%')};
		margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}
	}
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		position: absolute;
		width: 100%;
		right: ${({ isFirstSlide, theme }) =>
			isFirstSlide && theme.spacings[4]};
	}
	@media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme }) => theme.spacings[6]};
	}

	padding: ${({ theme }) => `${theme.spacings[8]} ${theme.spacings[12]}`};
	flex-grow: ${({ isThree }) => !isThree && 1};
	background: ${({ theme }) => theme.colors.primary};
	flex-direction: column;
	transition: ${({ theme }) => theme.transitions.button};
	color: ${({ theme }) => theme.colors.white};

	width: auto;
	&:hover {
		box-shadow: ${({ theme }) =>
			`${theme.boxShadows.linkShadow} ${theme.colors.linkShadow}`};

		transform: translateY(-${({ theme }) => theme.spacings[1]});
	}
`);

export const SliderWrapper = withResponsive(styled.div`
	height: 100%;
	.slider-slide,
	.slider,
	.slider-list,
	.slider-frame {
		min-height: 300px;
		outline: none;
	}
	.slider-list {
		/* left: ${({ leftMargin }) => leftMargin && '4.5%'}; */
	}

	.slider-control-bottomcenter {
		& > ul {
			flex-wrap: wrap;
			top: 0 !important;
		}
	}

	.slider-slide {
		&:before {
			content: none;
		}
	}

	.slide-current {
		& > div {
			margin-left: 0 !important;
		}
	}
	.slider-list {
		transition: height 0.2s ease;
	}

	.slider-control-bottomcenter {
		& {
			margin-top: ${({ theme }) => theme.spacings[6]};
			position: relative !important;
			display: flex;
			justify-content: center;
		}
	}
	.paging-item {
		margin: ${({ theme }) => `0 ${theme.spacings[2]}`};
		button {
			outline: none;
			opacity: 1 !important;
			&[style] {
				fill: ${({ theme }) => theme.colors.white} !important;
			}
			svg {
				border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
				border-radius: 100%;
				background-color: ${({ theme }) => theme.colors.white};
				width: 16px;
				height: 16px;
			}
		}
		&.active button[style] {
			fill: ${({ theme }) => `${theme.colors.primary} !important`};
			svg {
				background-color: ${({ theme }) => theme.colors.primary};
			}
		}
		&:before {
			content: none;
		}
	}
`);
