import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'shared/layout';
import { withTheme } from 'styled-components';
import { Input, SearchButton } from 'shared/components';
const SearchBar = ({
	theme = {},
	userInput = '',
	handleSearch = () => {},
	handleInputChange = () => {},
	children,
	...rest
}) => {
	return (
		<Input
			value={userInput}
			onChange={(e) => handleInputChange(e)}
			height={theme.spacings[16]}
			onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
			{...rest}
		>
			<Box position="absolute" right={theme.spacings[5]}>
				<SearchButton
					fill={theme.colors.white}
					stroke={theme.colors.primary}
					isOpen={false}
					as="button"
					flex-direction="row"
					isSearchBar
					handleMenuClick={() => handleSearch()}
				/>
			</Box>
			{children}
		</Input>
	);
};

SearchBar.propTypes = {
	theme: PropTypes.object,
	userInput: PropTypes.string,
	handleSearch: PropTypes.func,
	handleInputChange: PropTypes.func,
};

export default withTheme(SearchBar);
