import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { concatenateIfMany, push } from 'shared/helpers';
import { MaxWidthContainer, Spacer, Flex, Box } from 'shared/layout';

import {
	H1,
	MainBody,
	Breadcrumbs,
	RteContent,
	BlockList,
	Contact,
	ShareComponent,
	Caption,
	Video,
	EmphasizedContent,
	MainBody2,
} from 'shared/components';
import { withTheme } from 'styled-components';
import { InformationPageMeta } from './InformationPageMeta';
import { PuffBlock } from 'shared/blocks';
import { useRouter } from 'next/router';

const InformationPage = ({
	Heading = '',
	Preamble = '',
	BottomBlocks = [],
	MainBlocks = [],
	MainContent = '',
	Contacts = [],
	DocumentType = '',
	BreadcrumbLinks = [],
	ShareComponent: shareComponentData = {},
	SearchEngineSettings = {},
	EmphasizedHeading = '',
	EmphasizedIsOpen = false,
	EmphasizedMainContent = null,
	Metadata = [],
	Categories = [],
	theme = {},
	VideoLink = '',
	VideoType = 'youtube',
	VideoText = '',
	hasAcceptedCookies = true,
	RelatedContent = {},
	LastPublishedDate = '',
}) => {
	const { asPath } = useRouter();
	useEffect(() => {
		push({
			event: 'tags_inbedded',
			inbeddedValue: Categories,
			url: asPath,
		});
	}, [Categories, asPath]);
	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							InformationPageMeta({ ...Metadata, asPath }),
						),
					}}
				/>
				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>

			<MaxWidthContainer noGutters>
				<Breadcrumbs breadcrumbLinks={BreadcrumbLinks}></Breadcrumbs>
			</MaxWidthContainer>
			<Box
				id="information-page-top"
				noGutters
				display={{ base: 'flex', lg: 'block' }}
				flex-direction={{ base: 'column-reverse', lg: 'column' }}
			>
				<ShareComponent {...shareComponentData} mailTitle={Heading} />
				<MaxWidthContainer narrow>
					<Spacer spacing={{ base: 8, md: 10 }}>
						<H1>{Heading}</H1>
					</Spacer>

					{(!!Categories?.length || !!LastPublishedDate) && (
						<Spacer spacing={{ base: 6, md: 8 }}>
							<Flex flex-direction="row" align-items="center">
								{!!Categories?.length && (
									<Caption
										margin-right={theme.spacings[4]}
										color={theme.colors.pink}
									>
										{concatenateIfMany(Categories)}
									</Caption>
								)}
								{!!DocumentType && (
									<Caption
										margin-right={theme.spacings[4]}
										color={theme.colors.secondary}
									>
										{DocumentType}
									</Caption>
								)}
								{!!LastPublishedDate && (
									<MainBody2 textTransform="none">
										{LastPublishedDate}
									</MainBody2>
								)}
							</Flex>
						</Spacer>
					)}
					<Spacer spacing={{ base: 4, md: 6 }}>
						<MainBody color={theme?.colors?.primary}>
							{Preamble}
						</MainBody>
					</Spacer>
					<EmphasizedContent
						EmphasizedHeading={EmphasizedHeading}
						EmphasizedIsOpen={EmphasizedIsOpen}
						EmphasizedMainContent={EmphasizedMainContent}
					/>
					<Spacer spacing={{ base: 6 }}>
						<RteContent text={MainContent} />
					</Spacer>
					{!!Contacts?.length &&
						!MainBlocks &&
						Contacts.map((item, ix) => {
							if (ix === 0)
								return (
									<Spacer
										key={ix}
										spacing={{ base: 10, md: 14 }}
									>
										<Contact {...item} />
									</Spacer>
								);
							return (
								<Spacer key={ix} spacing={10}>
									<Contact {...item} />
								</Spacer>
							);
						})}
					<Spacer spacing={8}>
						{!!VideoLink.length && hasAcceptedCookies && (
							<Video
								VideoType={VideoType}
								VideoLink={VideoLink}
								VideoText={VideoText}
							/>
						)}
					</Spacer>
				</MaxWidthContainer>
			</Box>

			{MainBlocks && <BlockList blocks={MainBlocks} />}
			{!!Contacts?.length && MainBlocks && (
				<MaxWidthContainer narrow>
					{Contacts.map((item, ix) => {
						if (ix === 0)
							return (
								<Spacer key={ix} spacing={{ base: 10, md: 14 }}>
									<Contact {...item} />
								</Spacer>
							);
						return (
							<Spacer key={ix} spacing={10}>
								<Contact {...item} />
							</Spacer>
						);
					})}
				</MaxWidthContainer>
			)}
			{RelatedContent && (
				<Spacer spacing={8}>
					<PuffBlock {...{ Categories }} {...RelatedContent} />
				</Spacer>
			)}
			<BlockList blocks={BottomBlocks} />
		</>
	);
};

export default withTheme(InformationPage);

InformationPage.propTypes = {
	Heading: PropTypes.string,
	Contacts: PropTypes.array,
	BottomBlocks: PropTypes.array,
	DocumentType: PropTypes.string,
	MainContent: PropTypes.string,
	Preamble: PropTypes.string,
	Categories: PropTypes.array,
	EmphasizedHeading: PropTypes.string,
	EmphasizedIsOpen: PropTypes.bool,
	EmphasizedMainContent: PropTypes.string,
	RelatedContent: PropTypes.shape({
		Puffs: PropTypes.array,
		theme: PropTypes.object,
		Heading: PropTypes.string,
		Preamble: PropTypes.string,
	}),
	LastPublishedDate: PropTypes.string,
};
