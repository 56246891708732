import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import FooterSocials from './FooterSocials';
import { Column, Flex, MaxWidthContainer } from 'shared/layout';
import { StyledFooter, StyledParagraph } from './Footer.styles';
import {
	ArrowLink,
	BaseLink,
	UnorderedList,
	ListItem,
	H4,
	Logo,
} from 'shared/components';

const Footer = ({
	theme = '',
	Telephone = '',
	TelephoneLabel = '',
	FooterInformation = '',
	ContactInvoiceLabel = '',
	ContactInvoice = '',
	EmailLabel = '',
	Email = '',
	ColumnHeader = '',
	FacebookLink = {},
	InstagramLink = {},
	LinkedInLink = {},
	XSocialLink = {},
	YoutubeLink = {},
	FooterLinks = [],
}) => {
	const linkList = FooterLinks.map((link, i) => (
		<ListItem
			list-style-type="none"
			margin-left="0"
			key={`footer-links-${i}`}
		>
			<ArrowLink
				className="white-tab"
				font-size={theme.typography.fontSizes.desktop.body}
				href={link.Url}
				title={link.Name}
			>
				{link.Name}
			</ArrowLink>
		</ListItem>
	));

	return (
		<StyledFooter
			background={theme.colors.primary}
			color={theme.colors.white}
		>
			<MaxWidthContainer>
				<Flex
					padding={{
						base: `${theme.spacings[14]} 0 0`,
						md: `${theme.spacings[14]} 0`,
					}}
					flex-direction="column"
				>
					<Logo className="footer-logo" />
					<Flex margin={`${theme.spacings[12]} 0`}>
						<Column flex-basis="60%" noGutters>
							<StyledParagraph
								dangerouslySetInnerHTML={{
									__html: FooterInformation,
								}}
							/>

							{Telephone && (
								<StyledParagraph>
									{`${TelephoneLabel}: `}
									<BaseLink
										className="white-tab"
										inverted
										href={`tel:${Telephone}`}
									>
										{Telephone}
									</BaseLink>
								</StyledParagraph>
							)}
							{ContactInvoice && (
								<StyledParagraph>
									{`${ContactInvoiceLabel}: `}
									<BaseLink
										className="white-tab"
										inverted
										href={`mailto:${ContactInvoice}`}
									>
										{ContactInvoice}
									</BaseLink>
								</StyledParagraph>
							)}
							{Email && (
								<StyledParagraph>
									{`${EmailLabel}: `}
									<BaseLink
										className="white-tab"
										inverted
										href={`mailto:${Email}`}
									>
										{Email}
									</BaseLink>
								</StyledParagraph>
							)}
						</Column>
						<Column
							flex-direction="column"
							flex-basis="40%"
							width="100%"
							margin={{ base: '4rem 0 0', md: 0 }}
							noGutters
						>
							<H4 color={theme.colors.white}>{ColumnHeader}</H4>
							<UnorderedList
								margin-bottom={theme.spacings[8]}
								color={theme.colors.white}
							>
								{linkList}
							</UnorderedList>

							<FooterSocials
								facebookLink={FacebookLink}
								instagramLink={InstagramLink}
								linkedInLink={LinkedInLink}
								xSocialLink={XSocialLink}
								youtubeLink={YoutubeLink}
							/>
						</Column>
					</Flex>
				</Flex>
			</MaxWidthContainer>
		</StyledFooter>
	);
};

Footer.propTypes = {
	theme: PropTypes.object,
	adress: PropTypes.string,
	adressLabel: PropTypes.string,
	city: PropTypes.string,
	telephone: PropTypes.string,
	telephoneLabel: PropTypes.string,
	postAdressLabel: PropTypes.string,
	postAdress: PropTypes.string,
	invoiceLabel: PropTypes.string,
	invoice: PropTypes.string,
	emailLabel: PropTypes.string,
	email: PropTypes.string,
	columnHeader: PropTypes.string,
};

export default withTheme(Footer);
