import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { H1, RteContent } from 'shared/components';
import { Spacer } from 'shared/layout';
import {
	CampaignContentBlockContainer,
	CampaignContentBlockText,
	CampaignContentBlockImage,
} from './CampaignContentBlock.styles';
import {
	FullWidthColoredBackground,
	CampaignButton,
} from '../../pages/CampaignPage/CampaignPage.styles';
import Link from 'next/link';

const DesktopCampaignBlock = ({
	Heading = '',
	Text = '',
	Image = {},
	RightText = false,
	ContentLink = {},
}) => {
	const theme = useContext(ThemeContext);

	return (
		<FullWidthColoredBackground $color={theme.color[1]}>
			<CampaignContentBlockContainer
				$theme={theme}
				$extraWide
				$rightText={RightText}
			>
				<CampaignContentBlockText $theme={theme}>
					{!!Heading && (
						<H1
							word-break={{ base: 'break-word', md: 'unset' }}
							as="h2"
							color={theme.color[3]}
						>
							{Heading}
						</H1>
					)}
					{!!Text && (
						<>
							<Spacer spacing={{ base: 6.5, md: 8 }} />
							<RteContent
								as="div"
								text={Text}
								color={theme.color[3]}
							/>{' '}
						</>
					)}
					{!!ContentLink && ContentLink.Url && (
						<Spacer>
							<Link href={ContentLink?.Url} passHref>
								<CampaignButton
									target={ContentLink?.Target}
									$theme={theme}
									$altVersion
									as="button"
								>
									{ContentLink?.Name}
								</CampaignButton>
							</Link>
						</Spacer>
					)}
				</CampaignContentBlockText>
				{Image && <CampaignContentBlockImage src={Image.Url} />}
			</CampaignContentBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default DesktopCampaignBlock;
