import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const ContactWrapper = withResponsive(styled.div`
	border: 1px solid ${({ theme }) => theme.colors.borderGrey};
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
	padding: ${({ theme }) => theme.spacings[3]};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.contact} ${theme.colors.shadowGrey}`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => theme.spacings[6]};
		text-align: left;
		flex-direction: row;
		justify-content: space-between;
	}
`);

export const ContactSocials = styled.a`
	color: ${({ theme }) => theme.colors.secondary};
	font-size: ${({ theme }) => theme.spacings[8]};
	transition: all 0.3s ease;
	&:hover,
	&:active,
	&:focus {
		color: ${({ theme }) => theme.colors.primary};
	}
`;
