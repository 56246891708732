// ------------------------------------------------------------
// This file was auto generated by componentRegistryGenerator.js
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// DO NOT MAKE MANUAL CHANGES TO THIS FILE
//
// ------------------------------------------------------------

import { default as BannerBlock } from '../shared/blocks/BannerBlock/BannerBlock';
import { default as CampaignContentBlock } from '../shared/blocks/CampaignContentBlock/CampaignContentBlock';
import { default as CampaignCtaBlock } from '../shared/blocks/CampaignCtaBlock/CampaignCtaBlock';
import { default as CampaignHeroBlock } from '../shared/blocks/CampaignHeroBlock/CampaignHeroBlock';
import { default as CampaignInfogramBlock } from '../shared/blocks/CampaignInfogramBlock/CampaignInfogramBlock';
import { default as CampaignPdfBlock } from '../shared/blocks/CampaignPdfBlock/CampaignPdfBlock';
import { default as CampaignSubscriptionBlock } from '../shared/blocks/CampaignSubscriptionBlock/CampaignSubscriptionBlock';
import { default as CampaignTimelineBlock } from '../shared/blocks/CampaignTimelineBlock/CampaignTimelineBlock';
import { default as CampaignVideoBlock } from '../shared/blocks/CampaignVideoBlock/CampaignVideoBlock';
import { default as ContentBlock } from '../shared/blocks/ContentBlock/ContentBlock';
import { default as HeroBlock } from '../shared/blocks/HeroBlock/HeroBlock';
import { default as ListBlock } from '../shared/blocks/ListBlock/ListBlock';
import { default as PageListingBlock } from '../shared/blocks/PageListingBlock/PageListingBlock';
import { default as PdfBlock } from '../shared/blocks/PdfBlock/PdfBlock';
import { default as PuffBlock } from '../shared/blocks/PuffBlock/PuffBlock';
import { default as ScriptBlock } from '../shared/blocks/ScriptBlock/ScriptBlock';
import { default as SubscriptionBlock } from '../shared/blocks/SubscriptionBlock/SubscriptionBlock';
import { default as VideoBlock } from '../shared/blocks/VideoBlock/VideoBlock';
import { default as CampaignPage } from '../shared/pages/CampaignPage/CampaignPage';
import { default as CookiePage } from '../shared/pages/CookiePage/CookiePage';
import { default as CouncilPage } from '../shared/pages/CouncilPage/CouncilPage';
import { default as EventPage } from '../shared/pages/EventPage/EventPage';
import { default as InformationPage } from '../shared/pages/InformationPage/InformationPage';
import { default as NotFoundPage } from '../shared/pages/NotFoundPage/NotFoundPage';
import { default as SearchPage } from '../shared/pages/SearchPage/SearchPage';
import { default as StartPage } from '../shared/pages/StartPage/StartPage';
import { default as Subject } from '../shared/pages/Subject/Subject';
import { default as SubscriptionPage } from '../shared/pages/SubscriptionPage/SubscriptionPage';
import { default as TagListPage } from '../shared/pages/TagListPage/TagListPage';
import { default as ThankYouPage } from '../shared/pages/ThankYouPage/ThankYouPage';

export const componentRegistry = {
  BannerBlock,
  CampaignContentBlock,
  CampaignCtaBlock,
  CampaignHeroBlock,
  CampaignInfogramBlock,
  CampaignPdfBlock,
  CampaignSubscriptionBlock,
  CampaignTimelineBlock,
  CampaignVideoBlock,
  ContentBlock,
  HeroBlock,
  ListBlock,
  PageListingBlock,
  PdfBlock,
  PuffBlock,
  ScriptBlock,
  SubscriptionBlock,
  VideoBlock,
  CampaignPage,
  CookiePage,
  CouncilPage,
  EventPage,
  InformationPage,
  NotFoundPage,
  SearchPage,
  StartPage,
  Subject,
  SubscriptionPage,
  TagListPage,
  ThankYouPage,
};
