import axios from 'axios';

/* ValidateStatus to always return true in order to handle httpResponse as 404/500 */
export const umbraco = axios.create({
	baseURL: process.env.NEXT_PUBLIC_BASE_UMBRACO_API_URL,
	validateStatus: () => {
		return true;
	},
});

export const apiPost = async (query, options) => {
	const data = await axios
		.post(
			`${process.env.NEXT_PUBLIC_BASE_UMBRACO_API_URL}${query}`,
			options,
		)
		.catch((e) => {
			console.error(`Error post: ${query}`);
			throw new Error(e);
		});

	return data;
};

export const getPageByUrl = async (url) => {
	const { data, status } = await umbraco.get(`/page?path=${url}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (status >= 400) {
		return status;
	}

	if (data?.ResponseStatus && data?.ResponseStatus.Code === 302) {
		return {
			redirect: true,
			redirectUrl: data.ResponseStatus.RedirectUrl,
		};
	}

	return data;
};

export const getMetadataByUrl = async (url) => {
	const { data } = await umbraco.get(`/page/getmetadata?path=${url}`);

	return data;
};

export const getError = async () => {
	const { data } = await umbraco.get(`/page/NotFoundPage`);
	return data;
};

export const getHeaderByUrl = async (url) => {
	const res = await umbraco.get(`/global/getnavigation?path=${url}`);
	if (res?.status === 404 || res?.status === 500) {
		const { data } = await umbraco.get(`/global/getnavigation?path=/`);
		return data;
	} else {
		return res.data;
	}
};

export const getFooter = async () => {
	const { data } = await umbraco.get(`/global/getfooter`);
	return data;
};

export const getSearchSuggestions = async (data) => {
	const { data: res } = await axios.post(
		`//${window.location.host}/api/search/getsearchsuggestions`,
		data,
	);
	return res;
};

export const getSearchResults = async (data) => {
	const { data: res } = await axios.post(
		`//${window.location.host}/api/search`,
		data,
	);

	return res;
};

export const getSubscription = async (id = '') => {
	const { data } = await umbraco.get(`/Subscription?id=${id}`);
	return data;
};

export const deleteSubscription = async (id = '') => {
	const { data } = await umbraco.delete(`/Subscription?id=${id}`);
	return data;
};

export const getSitemap = async () => {
	const { data } = await umbraco.get(`/Sitemap`);
	return data;
};

export const getMedia = async (path) => {
	const data = await fetch(
		`${process.env.NEXT_PUBLIC_UMBRACO_MEDIA_URL}/media/${path}`,
	);
	return data;
};
