import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import {
	CheckboxContainer,
	HiddenCheckbox,
	StyledCheckbox,
} from './Checkbox.styles';
import { Caption, Icon } from 'shared/components';

const Checkbox = ({
	theme = {},
	label = '',
	isChecked = false,
	name = '',
	leftLabel = false,
	isControlled = false,
	onClick = () => {},
	containerStyles = {},
	textTransform = 'uppercase',
	fontWeight = 'normal',
	color = '',

	...rest
}) => {
	const handleOnClick = (e) => {
		e.preventDefault();

		if (!isControlled) {
			onClick(e, !isChecked, { label });
		}
	};

	const handleKeyPress = (e) => {
		if (!isControlled) {
			if (e.key === 'Enter' || e.key === ' ') {
				handleOnClick(e);
			}
		}
	};
	return (
		<label style={containerStyles}>
			{leftLabel && (
				<Caption
					color={color}
					font-weight={fontWeight}
					textTransform={textTransform}
					margin-right={theme.spacings[2]}
					as="span"
				>
					{label}
				</Caption>
			)}
			<CheckboxContainer>
				<HiddenCheckbox
					onKeyPress={(e) => handleKeyPress(e)}
					onChange={(e) => handleKeyPress(e)}
					tabIndex="0"
					type="checkbox"
					id={name}
					name={name}
					checked={isChecked}
					{...rest}
				/>
				<StyledCheckbox checked={isChecked}>
					<Icon name="checkmark" />
				</StyledCheckbox>
			</CheckboxContainer>
			{!leftLabel && (
				<Caption
					color={color}
					textTransform={textTransform}
					font-weight={fontWeight}
					margin-left={theme.spacings[2]}
					as="span"
				>
					{label}
				</Caption>
			)}
		</label>
	);
};

Checkbox.propTypes = {
	label: PropTypes.string,
	checked: PropTypes.bool,
	isChecked: PropTypes.bool,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	leftLabel: PropTypes.bool,
	isControlled: PropTypes.bool,
	onClick: PropTypes.func,
	containterStyles: PropTypes.object,
	textTransform: PropTypes.string,
	fontWeight: PropTypes.string,
};

export default withTheme(Checkbox);
