import { colors } from 'shared/core';
const whiteTheme = {
	colors: {
		white: colors.navy,
		text: colors.white,
		primary: colors.white,
		documentLink: colors.tcoBlue,
	},
};
export default whiteTheme;
