import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled, { withTheme, css } from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import withResponsive from 'shared/helpers/withResponsive';
import { Box, Spacer } from 'shared/layout';

export const SkeletonWrapper = withResponsive(styled.div`
	${({ isRow }) =>
		isRow &&
		css`
			display: flex;
		`}
`);

const BaseSkeleton = ({
	rowCount = 1,
	spacing = 4,
	width = '100%',
	color = '',
	height = '',
}) => {
	return (
		<Box width={width}>
			<Spacer spacing={spacing}>
				<Skeleton height={height} baseColor={color} count={rowCount} />
			</Spacer>
		</Box>
	);
};

export default withTheme(BaseSkeleton);

BaseSkeleton.propTypes = {
	rowCount: PropTypes.number,
	padding: PropTypes.number,
	itemCount: PropTypes.number,
	itemSpacing: PropTypes.number,
};
