import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'shared/layout';
import { withTheme } from 'styled-components';
import Link from 'next/link';
import { Icon } from 'shared/components';
const Logo = ({ theme = {}, type = 'footer-logo', padding = '', ...rest }) => {
	return (
		<Box padding={padding} z-index="1" width="fit-content">
			<Link passHref href="/">
				<a href="/">
					<Icon
						className={type}
						justify-content="flex-start"
						name="logo"
						{...rest}
						transition={theme.transitions.default}
					/>
				</a>
			</Link>
		</Box>
	);
};

Logo.propTypes = {
	type: PropTypes.oneOf(['footer-logo', 'header-logo']),
	theme: PropTypes.object,
};

export default withTheme(Logo);
