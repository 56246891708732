import PropTypes from 'prop-types';
import React from 'react';
import { MapTheme } from 'shared/helpers';
import { withTheme } from 'styled-components';
import DesktopPdfBlock from './DesktopPdfBlock';

const PdfBlock = ({
	Heading = '',
	MainContent = '',
	Image = {},
	PdfHeading = '',
	Pdf = {},
}) => {
	return (
		<MapTheme theme={'white'}>
			<DesktopPdfBlock
				Heading={Heading}
				MainContent={MainContent}
				Image={Image}
				PdfHeading={PdfHeading}
				Pdf={Pdf}
			/>
		</MapTheme>
	);
};

PdfBlock.propTypes = {
	Heading: PropTypes.string,
	MainContent: PropTypes.string,
	Image: PropTypes.object,
	PdfHeading: PropTypes.string,
	PDF: PropTypes.object,
};

export default withTheme(PdfBlock);
