import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Flex, Spacer, Column, ColumnContainer } from 'shared/layout';
import { RteContent, ArrowLink, FillerImage, H1 } from 'shared/components';
import { StyledTextWrapper } from './HeroBlock.styles';

import { useQuery } from 'react-query';
import { useRouter } from 'next/router';

import { removeQueryString } from 'shared/helpers';
import { getPageByUrl } from 'api';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const HeroBlock = ({
	theme,
	HeroImageDesktop = {},
	HeroImagePhone = {},
	Heading = '',
	Preamble = ' ',
	HeroLinks = [],
	IsFirstBlock = true,
}) => {
	const { asPath } = useRouter();
	const urlToGet = removeQueryString(asPath);
	const { data: page } = useQuery(urlToGet, () => getPageByUrl(urlToGet));

	const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

	const transparentBackground = page?.NavigationWhiteBackground
		? false
		: IsFirstBlock;
	const marginTop = transparentBackground
		? {
				base: `-${theme.spacings.headerHeight.base}`,
				lg: `-${theme.spacings.headerHeight.large}`,
			}
		: 0;

	return (
		<Flex
			margin-top={marginTop}
			justify-content={{ base: 'flex-end', md: 'flex-start' }}
			position="relative"
			min-height={{ base: theme.spacings[180], lg: '40vw' }}
		>
			{!isMobile && (
				<FillerImage
					priority
					src={HeroImageDesktop.Url}
					alt="This is the desktop image"
				/>
			)}
			{isMobile && (
				<FillerImage
					priority
					src={HeroImagePhone.Url}
					alt="This is the mobile image"
				/>
			)}
			<StyledTextWrapper width="100%">
				<ColumnContainer
					max-width={theme.spacings.siteWidth}
					margin="0 auto"
					width="100%"
					padding={{
						base: `${theme.spacings[4]} ${theme.spacings[6]}`,
						md: `${theme.spacings[8]} ${theme.spacings[4]}`,
						lg: `${theme.spacings[12]} 0`,
					}}
				>
					<Column
						padding={{
							base: 0,
							md: `${theme.spacings[4]}`,
						}}
						flex-basis={{ base: '100%', md: '50%', lg: '45%' }}
					>
						<H1 noColor>
							<RteContent
								color={theme.colors.interaction}
								text={Heading}
							/>
						</H1>
						<Spacer spacing={4}>
							<RteContent text={Preamble} />
						</Spacer>
						<Spacer spacing={4}>
							<Flex
								align-items="flex-end"
								justify-content="flex-end"
							>
								<ArrowLink
									className="white-tab"
									href={HeroLinks[0]?.Url}
								>
									{HeroLinks[0]?.Name}
								</ArrowLink>
							</Flex>
						</Spacer>
					</Column>
				</ColumnContainer>
			</StyledTextWrapper>
		</Flex>
	);
};

export default withTheme(HeroBlock);

const SimpleLinkType = PropTypes.shape({
	Title: PropTypes.string.isRequired,
	Url: PropTypes.string.isRequired,
});
HeroBlock.propTypes = {
	Header: PropTypes.string,
	Preamble: PropTypes.string,
	Link: SimpleLinkType,
	Image: PropTypes.string,
	theme: PropTypes.object,
};
