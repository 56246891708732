import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const VideoWrapper = withResponsive(styled.div`
	position: relative;
	height: ${({ theme, isVimeo }) => !isVimeo && theme.spacings[96]};
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	${({ videoEnded }) =>
		videoEnded &&
		css`
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				height: 340px;
				width: 100%;
				background-color: black;
			}
		`};
`);
