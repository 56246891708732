import React from 'react';
import PropTypes from 'prop-types';
import { MainBody } from 'shared/components';
import { Spacer, Flex } from 'shared/layout';
import { SimpleButton } from './';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { withTheme } from 'styled-components';

const SimpleButtons = ({
	ResetFiltersText = '',
	clearFilters = () => {},
	FilterHeading = '',
	showButton = false,
	categoryTypes = [],
	handleCategoryFilterClick = () => {},
	currentCategory = '',
	Categories = [],
	theme,
	...rest
}) => {
	const isMediumUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.md})`,
		true,
	);
	return (
		<>
			{isMediumUp && !!FilterHeading?.length && (
				<MainBody {...rest}>{FilterHeading}</MainBody>
			)}
			<Spacer spacing={7}>
				<Flex
					align-items="flex-start"
					justify-content={{
						base: 'flex-start',
						md: 'space-between',
					}}
				>
					<Flex
						flex-direction="row"
						flex-wrap={{ base: 'nowrap', md: 'wrap' }}
						align-items="flex-start"
						width={{ base: '100%', md: 'unset' }}
						border-bottom={{
							base: `1px solid ${theme.colors.text2}`,
							md: 'none',
						}}
					>
						{categoryTypes.map((item, i) => {
							//If a category only has one choice, remove the choice
							if (
								Categories.filter((x) => x.Type.Id === item.Id)
									.length === 1
							)
								return null;
							return (
								<SimpleButton
									isActive={currentCategory.Id === item.Id}
									onClick={() =>
										handleCategoryFilterClick(item)
									}
									key={`categorytypes-${i}`}
								>
									{item.Name}
								</SimpleButton>
							);
						})}
					</Flex>
					{showButton && (
						<SimpleButton onClick={clearFilters}>
							{ResetFiltersText}
						</SimpleButton>
					)}
				</Flex>
			</Spacer>
		</>
	);
};
export default withTheme(SimpleButtons);
SimpleButtons.propTypes = {
	ResetFiltersText: PropTypes.string,
	clearFilters: PropTypes.func,
	FilterHeading: PropTypes.string,
	showButton: PropTypes.bool,
	categoryTypes: PropTypes.array,
	handleCategoryFilterClick: PropTypes.func,
	currentCategory: PropTypes.object,
};
