import {
	breakpoints,
	breakpointsForResponsiveProps,
	colors,
	spacings,
	zIndexes,
	boxShadows,
	typography,
	borderRadius,
	translates,
	transitions,
} from 'shared/core';

const defaultTheme = {
	colors: {
		white: colors.white,
		primary: colors.navy,
		primaryThin: colors.navyThin,
		primaryDark: colors.navyDark,
		primaryLight: colors.oceanBlue,
		text: colors.darkGrey,
		textThin: colors.darkGreyThin,
		text2: colors.grey,
		puffText: colors.frost,
		secondary: colors.tcoBlue,
		secondaryThin: colors.tcoBlueThin,
		secondaryThinner: colors.tcoBlueThinner,
		error: colors.orange,
		success: colors.sky,
		bg: colors.frost,
		pink: colors.pink,
		interaction: colors.fire,
		disabled: colors.asphalt,
		textBlack: colors.black,
		highlight: colors.frost,
		backgroundGrey: colors.backgroundGrey,
		lightGrey: colors.lightGrey,
		shadowGrey: colors.shadowGrey,
		borderGrey: colors.borderGrey,
		breaklineGrey: colors.breaklineGrey,
		linkShadow: colors.primaryLinkShadow,
		thinLinkShadow: colors.frostLinkShadow,
		banner: colors.powder,
		documentLink: colors.tcoBlue,
		eventShadow: colors.primaryBackgroundEvent,
	},
	responsiveProps: {
		breakpoints: breakpointsForResponsiveProps,
	},
	boxShadows,
	spacings,
	translates,
	zIndexes,
	breakpoints,
	typography,
	borderRadius,
	transitions,
};

export default defaultTheme;
