import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { concatenateIfMany, push } from 'shared/helpers';
import { MaxWidthContainer, Spacer, Flex, Box } from 'shared/layout';
import { SimpleButton } from '../../components/Search/Search.styles';

import {
	H1,
	H5,
	MainBody,
	Breadcrumbs,
	RteContent,
	BlockList,
	Contact,
	ShareComponent,
	Caption,
	Video,
	EmphasizedContent,
	MainBody2,
} from 'shared/components';
import { withTheme } from 'styled-components';
import { CouncilPageMeta } from './CouncilPageMeta';
import { PuffBlock } from 'shared/blocks';
import { useRouter } from 'next/router';

const CouncilPage = ({
	Heading = '',
	Preamble = '',
	BottomBlocks = [],
	MainBlocks = [],
	MainContent = '',
	Contacts = [],
	DocumentType = '',
	BreadcrumbLinks = [],
	ShareComponent: shareComponentData = {},
	SearchEngineSettings = {},
	EmphasizedHeading = '',
	EmphasizedIsOpen = false,
	EmphasizedMainContent = null,
	Metadata = [],
	Categories = [],
	theme = {},
	VideoLink = '',
	VideoType = 'youtube',
	VideoText = '',
	hasAcceptedCookies = true,
	RelatedContent = {},
	LastPublishedDate = '',
}) => {
	const { asPath } = useRouter();

	useEffect(() => {
		push({
			event: 'tags_inbedded',
			inbeddedValue: Categories,
			url: asPath,
		});
	}, [Categories, asPath]);

	const primaryCategories = Array.from(
		new Set(Contacts?.map((contact) => contact.PrimaryCategory).sort()),
	);

	const [selectedCategory, setSelectedCategory] = useState(null);

	const sortedAndFilteredContacts = Contacts?.filter(
		(contact) =>
			selectedCategory === null ||
			contact.PrimaryCategory === selectedCategory,
	).sort((a, b) => {
		// Place all null last
		if (a.SecondaryCategory === null && b.SecondaryCategory !== null) {
			return 1;
		} else if (
			a.SecondaryCategory !== null &&
			b.SecondaryCategory === null
		) {
			return -1;
		} else if (
			a.SecondaryCategory === null &&
			b.SecondaryCategory === null
		) {
			return 0;
		} else {
			// Sort the remaining
			return a.SecondaryCategory.localeCompare(b.SecondaryCategory);
		}
	});

	const handleClick = (index) => {
		setSelectedCategory(index);
	};

	const [isListOpen, setIsListOpen] = useState(false);

	const filterSectionRef = useRef(null);

	const handleClickOutside = (event) => {
		if (
			filterSectionRef.current &&
			!filterSectionRef.current.contains(event.target)
		) {
			setIsListOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							CouncilPageMeta({ ...Metadata, asPath }),
						),
					}}
				/>

				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>

			<MaxWidthContainer noGutters>
				<Breadcrumbs breadcrumbLinks={BreadcrumbLinks}></Breadcrumbs>
			</MaxWidthContainer>
			<Box
				id="information-page-top"
				noGutters
				display={{ base: 'flex', lg: 'block' }}
				flex-direction={{ base: 'column-reverse', lg: 'column' }}
			>
				<ShareComponent {...shareComponentData} mailTitle={Heading} />
				<MaxWidthContainer narrow>
					<Spacer spacing={{ base: 8, md: 10 }}>
						<H1>{Heading}</H1>
					</Spacer>

					{(!!Categories?.length || !!LastPublishedDate) && (
						<Spacer spacing={{ base: 6, md: 8 }}>
							<Flex flex-direction="row" align-items="center">
								{!!Categories?.length && (
									<Caption
										margin-right={theme.spacings[4]}
										color={theme.colors.pink}
									>
										{concatenateIfMany(Categories)}
									</Caption>
								)}
								{!!DocumentType && (
									<Caption
										margin-right={theme.spacings[4]}
										color={theme.colors.secondary}
									>
										{DocumentType}
									</Caption>
								)}
								{!!LastPublishedDate && (
									<MainBody2 textTransform="none">
										{LastPublishedDate}
									</MainBody2>
								)}
							</Flex>
						</Spacer>
					)}
					<Spacer spacing={{ base: 4, md: 6 }}>
						<MainBody color={theme?.colors?.primary}>
							{Preamble}
						</MainBody>
					</Spacer>
					<EmphasizedContent
						EmphasizedHeading={EmphasizedHeading}
						EmphasizedIsOpen={EmphasizedIsOpen}
						EmphasizedMainContent={EmphasizedMainContent}
					/>
					<Spacer spacing={{ base: 6 }}>
						<RteContent text={MainContent} />
					</Spacer>
					{!!Contacts?.length &&
						!MainBlocks &&
						Contacts?.map((item, ix) => {
							if (ix === 0)
								return (
									<Spacer
										key={ix}
										spacing={{ base: 10, md: 14 }}
									>
										<Contact {...item} />
									</Spacer>
								);
							return (
								<Spacer key={ix} spacing={10}>
									<Contact {...item} />
								</Spacer>
							);
						})}
					<Spacer spacing={8}>
						{!!VideoLink.length && hasAcceptedCookies && (
							<Video
								VideoType={VideoType}
								VideoLink={VideoLink}
								VideoText={VideoText}
							/>
						)}
					</Spacer>
				</MaxWidthContainer>
			</Box>
			<div
				className={`filter-section ${
					isListOpen && 'filter-section--open'
				}`}
				style={{
					width: '100%',
					backgroundColor: 'rgb(251, 251, 251)',
					zIndex: 1,
					position: 'relative',
				}}
			>
				<MaxWidthContainer narrow>
					<H5 className="filter-section__title">Filtrera</H5>
					<SimpleButton
						className="filter-section__reset"
						style={{ fontSize: '1.125rem' }}
						onClick={() => handleClick(null)}
					>
						Rensa mina val
					</SimpleButton>
					<div
						className="filter-section__filters"
						ref={filterSectionRef}
					>
						<div
							className={`filter-section__open-button ${
								selectedCategory
									? 'filter-section__open-button--active'
									: ''
							}`}
							onClick={() =>
								setIsListOpen((prevState) => !prevState)
							}
						>
							{selectedCategory ? selectedCategory : 'Kategorier'}
						</div>

						<div className={`filter-section__list`}>
							{primaryCategories.map(
								(category, index) =>
									category !== null && (
										<button
											className={`toggle-button ${
												selectedCategory === category
													? 'toggle-button--active'
													: ''
											}`}
											key={index}
											onClick={() =>
												handleClick(category)
											}
										>
											{category}
										</button>
									),
							)}
							<span onClick={() => setIsListOpen(false)}>
								Stäng
							</span>
						</div>
					</div>
				</MaxWidthContainer>
			</div>

			{MainBlocks && <BlockList blocks={MainBlocks} />}
			{!!Contacts?.length && MainBlocks && (
				<MaxWidthContainer narrow>
					{sortedAndFilteredContacts.map((item, ix) => (
						<Spacer
							key={ix}
							spacing={ix === 0 ? { base: 10, md: 14 } : 10}
						>
							<Contact {...item} />
						</Spacer>
					))}
				</MaxWidthContainer>
			)}
			{RelatedContent && (
				<Spacer spacing={8}>
					<PuffBlock {...{ Categories }} {...RelatedContent} />
				</Spacer>
			)}
			<BlockList blocks={BottomBlocks} />
		</>
	);
};

export default withTheme(CouncilPage);

CouncilPage.propTypes = {
	Heading: PropTypes.string,
	Contacts: PropTypes.array,
	BottomBlocks: PropTypes.array,
	DocumentType: PropTypes.string,
	MainContent: PropTypes.string,
	Preamble: PropTypes.string,
	Categories: PropTypes.array,
	EmphasizedHeading: PropTypes.string,
	EmphasizedIsOpen: PropTypes.bool,
	EmphasizedMainContent: PropTypes.string,
	RelatedContent: PropTypes.shape({
		Puffs: PropTypes.array,
		theme: PropTypes.object,
		Heading: PropTypes.string,
		Preamble: PropTypes.string,
	}),
	LastPublishedDate: PropTypes.string,
};
