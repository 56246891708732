import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const DesktopBoxWrapper = withResponsive(styled.div`
	padding-right: ${({ theme }) => theme.spacings[4]};
	max-width: ${({ theme }) => `calc(${theme.spacings.siteWidth}/2)`};
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, left }) =>
			left
				? `${theme.spacings[8]} 0 ${theme.spacings[8]} ${theme.spacings[12]}`
				: `${theme.spacings[8]} ${theme.spacings[12]} ${theme.spacings[8]} 0`};
	}
`);

export const HighlightWrapper = styled.div`
	&& {
		.highlight {
			color: ${({ theme }) => theme.colors.pink};
		}
	}
`;
