import React from 'react';
import { withTheme } from 'styled-components';

import { Spacer, Box } from 'shared/layout';

import { RteContent, H2, LinkButton, ImageComponent } from 'shared/components';

const ContentBlockMobile = ({
	theme = {},
	Heading = '',
	ContentLink = {},
	Preamble = '',
	Image = {},
}) => {
	return (
		<Spacer spacing={12} height="100%" display="flex">
			<Box
				background={theme.colors.backgroundGrey}
				height="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
			>
				<>
					<ImageComponent
						src={Image?.Url}
						width={Image?.Width}
						height={Image?.Height}
						alt={Image?.AltText}
						objectFit="cover"
					/>
					<Box
						padding={{
							base: `0 ${theme.spacings[6]} ${theme.spacings[6]}`,
							md: `0 ${theme.spacings[8]} ${theme.spacings[8]}`,
						}}
						display="flex"
						flex-direction="column"
						justify-content="space-between"
					>
						<Box>
							<Spacer spacing={4}>
								<H2>
									<RteContent forceh2 text={Heading} />
								</H2>
							</Spacer>
							<Spacer spacing={4}>
								<RteContent text={Preamble} />
							</Spacer>
						</Box>
					</Box>
				</>
				<Spacer
					padding={{
						base: `0 ${theme.spacings[6]} ${theme.spacings[6]}`,
						md: `0 ${theme.spacings[8]} ${theme.spacings[8]}`,
					}}
					display="flex"
					align-items="flex-end"
				>
					<LinkButton
						target={ContentLink?.Target}
						href={ContentLink?.Url}
						variant="ghost"
						width="100%"
						max-width="none"
					>
						{ContentLink?.Name}
					</LinkButton>
				</Spacer>
			</Box>
		</Spacer>
	);
};

export default withTheme(ContentBlockMobile);
