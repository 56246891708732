import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import {
	ButtonStyle,
	primaryStyle,
	secondaryStyle,
	ghostStyle,
	textStyle,
} from '../Button/Button.styles';

export const BaseLinkButton = styled.a`
	${ButtonStyle}
`;

export const Primary = withResponsive(styled(BaseLinkButton)`
	${primaryStyle}
`);

export const Secondary = withResponsive(styled(BaseLinkButton)`
	${secondaryStyle}
`);

export const Ghost = withResponsive(styled(BaseLinkButton)`
	${ghostStyle}
`);

export const Text = withResponsive(styled(BaseLinkButton)`
	${textStyle}
`);

export const Campaign = withResponsive(styled(BaseLinkButton)`
	background: ${({ theme }) => theme.colors.primary};
	border: ${({ theme }) => `2px solid ${theme.colors.white}`};
	color: ${({ theme }) => theme.colors.white};

	&:hover,
	&:active {
		&& {
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.primary};
		}
	}

	.is-tabbed &:focus {
		background: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.primary};
	}
`);
