import React, { useState, useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'shared/layout';
import { useDebounce } from 'shared/hooks';
import { withTheme, keyframes } from 'styled-components';
import { SearchBar, BaseLink, FadeIn, ListItem } from 'shared/components';
import { useQuery } from 'react-query';
import { getSearchSuggestions } from 'api';
import { useRouter } from 'next/router';
import { push } from 'shared/helpers';

export const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
`;

const moveToTheLeft = keyframes`
  from {
    opacity: 0;
	transform: translateX(20px);

  }
  to {
	transform: translateY(0);
    opacity: 1;


  }
`;

const HeaderSearch = forwardRef(
	(
		{
			theme = {},
			isSearchOpen = false,
			SearchPageUrl = '',
			SearchPlaceholder = 'Vad söker du?',
		},
		ref,
	) => {
		const router = useRouter();
		const [userInput, setUserInput] = useState('');
		const inputRef = useRef(undefined);
		const debouncedInput = useDebounce(userInput, 500);
		useEffect(() => {
			if (isSearchOpen && !!inputRef.current) {
				inputRef.current.focus();
			}
		});

		const { data } = useQuery(
			['header-search', debouncedInput],
			() =>
				getSearchSuggestions({
					Query: debouncedInput,
					Categories: [],
					DocumentTypes: [],
					CurrentCulture: '',
					Contacts: [],
					Years: [],
					Take: 3,
					Skip: 0,
					SortBy: '',
					IsEmptySearch: true,
				}),
			{
				staleTime: 1000,
				refetchOnWindowFocus: false,
				keepPreviousData: true,
				enabled: debouncedInput.length > 2,
				select: (data) =>
					data?.SearchHits.map((hit) => {
						return { Heading: hit.Heading, Url: hit.Url };
					}),
			},
		);

		const handleSearch = () => {
			push({
				event: 'search',
				search_type: 'global',
				search_term: userInput,
			});
			router.push(`${SearchPageUrl}?query=${userInput}`);
		};
		const handleInputChange = (e) => {
			setUserInput(e.target.value);
		};
		return (
			<div ref={ref}>
				<FadeIn
					background={theme.colors.primary}
					z-index="0"
					width="100%"
					overflow="hidden"
					position="absolute"
					delay={150}
					keyFrames={fadeIn}
				>
					<Flex
						max-width={theme.spacings[212]}
						width={{ base: 'calc(100% - 2rem)', md: '100%' }}
						margin="0 auto"
						align-items="center"
						height={theme.spacings[60]}
						flex-direction="column"
						position="relative"
						margin-top={{
							base: theme.spacings[24],
							md: theme.spacings[32],
						}}
					>
						<SearchBar
							handleInputChange={handleInputChange}
							handleSearch={handleSearch}
							userInput={userInput}
							placeholder={SearchPlaceholder}
							inputRef={inputRef}
							autoFocus // eslint-disable-line
							noFocus
							isSearchBar
						/>
						<Box width="100%">
							{!!debouncedInput.length && (
								<FadeIn
									border={`${theme.spacings[0.5]} solid ${theme.colors.primary}`}
									as="ul"
									background={theme.colors.white}
									position="absolute"
									width="100%"
									display="flex"
									flex-direction="column"
									delay={0}
								>
									{data?.map(({ Heading, Url }, key) => (
										<FadeIn
											key={`${Heading}-${key}`}
											as={ListItem}
											delay={key * 100 + 20}
											duration={500}
											padding={`${theme.spacings[2]} ${theme.spacings[8]}`}
											keyFrames={moveToTheLeft}
										>
											<BaseLink href={Url}>
												{Heading}
											</BaseLink>
										</FadeIn>
									))}
								</FadeIn>
							)}
						</Box>
					</Flex>
				</FadeIn>
			</div>
		);
	},
);

HeaderSearch.propTypes = {
	SearchPageUrl: PropTypes.string,
	isSearchOpen: PropTypes.bool,
	theme: PropTypes.object,
};

export default withTheme(HeaderSearch);
