import React from 'react';
import { Icon } from 'shared/components';
import { withTheme } from 'styled-components';
import { Box, Flex } from '../layout';
const Loader = ({ theme, ...rest }) => {
	return (
		<Flex
			position="relative"
			align-items="center"
			justify-content="center"
			height="70vh"
		>
			<Box position="absolute">
				<Icon
					{...rest}
					name="loader"
					font-size={theme.spacings[4]}
					color={theme.colors.primary}
				/>
			</Box>
		</Flex>
	);
};

export default withTheme(Loader);
Loader.propTypes = {};
