import React from 'react';
import { DropDownOption } from './DropDownOption';
import { DropDownOptionsBox } from './DropDownOptionsBox';
import { DropDownInput } from './DropDownInput';
import { DropDownButton } from './DropDownButton';
import { useDropDown } from './useDropDown';
import { Box } from '../../layout';
import { MainBody } from '../Text/Text';
import _ from 'lodash';
import { DropDownContainer } from './DropDown.styles';
import Button from '../Button/Button';

export const DropDown = ({
	identifier,
	options,
	placeholder,
	fullWidth = false,
	isMultiChoice = false,
	initialSelected = null,
	anySelectedValue = true,
	searchPlaceholder = '',
	onChange,
}) => {
	const {
		filteredOptions,
		current,
		selected,
		inputValue,
		isOpen,
		handlers,
		refs,
	} = useDropDown({
		options,
		isMultiChoice,
		initialSelected,
		onChange,
		anySelectedValue,
	});
	const optionsList = filteredOptions?.map((option, index) => {
		const withCurrentRef =
			index === current ? { innerRef: refs.currentOption } : {};
		const handleClick = isMultiChoice ? () => null : handlers.close;

		return (
			<DropDownOption
				key={`${identifier}-${index}`}
				value={option.value}
				title={option.title}
				onClick={handleClick}
				onChange={() => handlers.selectedChange(option)}
				name={identifier}
				optionId={`${identifier}-${index}`}
				isCurrent={current === index}
				isSelected={selected.find((it) => it.value === option.value)}
				{...{ isMultiChoice }}
				{...withCurrentRef}
			/>
		);
	});
	const selectedText =
		selected.length > 0 && options?.length > 0
			? _.truncate(
					selected
						.map(
							(value) =>
								options?.find(
									(option) => option.value === value.value,
								)?.title,
						)
						.join(', '),
					{
						length: 30,
					},
				)
			: null;

	const checkBlur = (e) => {
		if (e.shiftKey) return e;
		if (e.keyCode === 9) {
			handlers.close();
			return e;
		}
	};

	return (
		<DropDownContainer
			aria-expanded={isOpen}
			aria-controls={`${identifier}-options`}
			ref={refs.component}
			{...{ fullWidth, selected, isOpen }}
		>
			<Box display="flex" overflow="hidden">
				<Box
					position="relative"
					background="white"
					flex="1"
					tabIndex={0}
					onKeyPress={(e) => e.key === 'Enter' && handlers.open()}
					onClick={() =>
						isOpen ? handlers.close() : handlers.open()
					}
				>
					<Box
						padding="0 12px"
						flex="1"
						position="absolute"
						display="flex"
						height="100%"
						width="100%"
						align-items="center"
					>
						<MainBody overflow="hidden">
							{selectedText || placeholder}
						</MainBody>
					</Box>
				</Box>
				<DropDownButton
					{...{ isOpen }}
					onClick={() =>
						isOpen ? handlers.close() : handlers.open()
					}
				/>
			</Box>
			<DropDownOptionsBox
				innerRef={refs.optionsBox}
				id={`${identifier}-options`}
				{...{ isOpen }}
			>
				{options?.length > 9 && (
					<DropDownInput
						innerRef={refs.input}
						value={inputValue}
						onChange={handlers.inputChange}
						onKeyUp={handlers.keyUp}
						placeholder={searchPlaceholder}
					/>
				)}
				{optionsList}
				<Box
					position="sticky"
					bottom="0"
					padding="0 12px 12px"
					background="white"
				>
					<Button
						width="100%"
						max-width="none"
						onClick={() => handlers.close()}
						onKeyDown={(e) => checkBlur(e)}
					>
						Stäng
					</Button>
				</Box>
			</DropDownOptionsBox>
		</DropDownContainer>
	);
};
