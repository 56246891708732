import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const PageListingBlockContainer = withResponsive(styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	row-gap: ${({ theme }) => theme.spacings[2]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: ${({ theme }) => theme.spacings.default};
		column-gap: ${({ theme }) => theme.spacings.default};
		width: 100%;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.siteWidth}) {
		column-gap: ${({ theme }) => theme.spacings[8]};
		row-gap: ${({ theme }) => theme.spacings[8]};
	}
`);

export const BlockItemArrow = styled.div`
	color: ${({ theme }) => theme.colors.primary};
	font-size: ${({ theme }) => theme.spacings[6]};
	min-width: ${({ theme }) => theme.spacings[6]};
	height: ${({ theme }) => theme.spacings[6]};
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	margin-top: ${({ theme }) => theme.spacings[1]};
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: 0;
		font-size: ${({ theme }) => theme.spacings[9]};
		min-width: ${({ theme }) => theme.spacings[9]};
		height: 100%;
		align-self: flex-end;

		align-items: flex-end;
	}
`;

export const BlockItem = withResponsive(styled.a`
	display: flex;
	flex-direction: row;
	border: 2px solid ${({ theme }) => theme.colors.primary};
	transition: all ${({ theme }) => theme.transitions.default};
	padding: ${({ theme }) =>
		`${theme.spacings.default} ${theme.spacings[6]} `};
	justify-content: space-between;
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: ${({ theme, isTwo }) =>
			isTwo ? 'calc(50% - 18px)' : theme.spacings[88]};
		flex-direction: column;
		height: ${({ theme }) => theme.spacings[60]};
		padding: ${({ theme }) =>
			`0 ${theme.spacings[6]} ${theme.spacings.default}`};
		justify-content: flex-start;
	}

	&:hover,
	&:focus {
		background: ${({ theme }) => theme.colors.primary};
		box-shadow: ${({ theme }) =>
			`${theme.boxShadows.linkShadow} ${theme.colors.thinLinkShadow}`};
		* {
			transition: all ${({ theme }) => theme.transitions.default};
			color: ${({ theme }) => theme.colors.white};
		}
	}
`);
