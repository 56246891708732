import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { ButtonStyle } from '../Button/Button.styles';

export const Circle = withResponsive(styled.span`
	content: '';
	width: ${({ theme }) => theme.spacings[3]};
	height: ${({ theme }) => theme.spacings[3]};
	border-radius: 50%;
	margin: ${({ theme }) => `0 ${theme.spacings[1]}`};
`);

export const StyledFilterButton = withResponsive(styled.button.attrs(
	({ isExpanded }) => ({
		'aria-expanded': isExpanded ? 'true' : 'false',
	}),
)`
	${ButtonStyle}
	background-color: ${({ theme, isExpanded }) =>
		!isExpanded ? theme.colors.primary : 'transparent'};
	color: ${({ theme, isExpanded }) =>
		!isExpanded ? theme.colors.white : theme.colors.primary};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.md} ${theme.colors.primary}`};
	-web-kit-box-shadow: ${({ theme }) =>
		`${theme.boxShadows.md} ${theme.colors.primary}`};
	height: ${({ theme }) => theme.spacings[12]};
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		height: ${({ theme }) => theme.spacings[10]};
	}

	${Circle} {
		background-color: ${({ theme, isExpanded }) =>
			!isExpanded ? theme.colors.white : theme.colors.primary};
	}
	padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[8]}`};

	&:hover,
	&:active {
		background-color: ${({ theme, isExpanded }) =>
			!isExpanded ? theme.colors.white : theme.colors.primary};
		${Circle} {
			background-color: ${({ isExpanded, theme }) =>
				!isExpanded ? theme.colors.primary : theme.colors.white};
		}

		&& {
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`);
