import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '../../layout';
import Icon from '../Icon';

const StyledInput = styled.input`
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	padding: ${({ theme }) => theme.spacings[2]};
	padding-right: ${({ theme }) => theme.spacings[12]};
	border-radius: 2px;
	border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
`;

export const DropDownInput = ({
	innerRef,
	value,
	onChange,
	onKeyUp,
	placeholder,
}) => {
	const theme = useTheme();
	return (
		<Box
			padding="12px"
			position="sticky"
			z-index="1"
			top="0"
			background="white"
		>
			<Box position="relative">
				<StyledInput
					ref={innerRef}
					value={value}
					{...{ onChange, onKeyUp }}
					placeholder={placeholder ? placeholder : 'Sök...'}
					height="40px"
				/>
				<Icon
					position="absolute"
					top={{ base: '4px', lg: '5px' }}
					right="8px"
					name="search"
					fill={theme.colors.white}
					stroke={theme.colors.primary}
					font-size={theme.spacings[6]}
					size={theme.spacings[6]}
				/>
			</Box>
		</Box>
	);
};
