import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const SimpleButton = withResponsive(styled.button`
	background-color: ${({ theme, isActive }) =>
		isActive ? theme.colors.lightGrey : theme.colors.backgroundGrey};
	text-decoration: ${({ isActive }) => (isActive ? 'none' : 'underline')};

	border: none;
	color: ${({ theme }) => theme.colors.primary};
	padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[4]}`};
	font-size: ${({ theme }) => theme.spacings[4]};
	&:hover {
		text-decoration: none;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-grow: 1;

		border-top: ${({ theme, isActive }) =>
			isActive && `5px solid ${theme.colors.primary}`};

		height: ${({ theme }) => theme.spacings[16]};
		flex-basis: 33%;
		width: 100%;
	}
`);

export const FilterText = withResponsive(styled.span`
	&:after {
		content: ', ';
	}

	&:last-of-type {
		&:after {
			content: '';
		}
	}
`);
