export const getYoutubeVideo = (url = '', clean = false) => {
	let cleanString = clean ? '&controls=0&modestbranding=1' : '';

	return `//www.youtube.com/embed/${getYoutubeId(
		url,
	)}?enablejsapi=1&rel=0${cleanString}`;
};
export const getYoutubeId = (url) => {
	let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	let match = url.match(regExp);

	return match && match[2].length == 11 ? match[2] : '';
};
export const getVimeoVideo = (url) => {
	return `https://player.vimeo.com/video/${getVimeoId(url)}?rel-0&showinfo=0`;
};

let getVimeoId = (url) => {
	var re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
	var matches = re.exec(url);
	return matches && matches[1];
};
