import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const Button = withResponsive(styled.button`
	color: ${({ theme }) => theme.colors.interaction};
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: ${({ theme }) => theme.zIndexes.progressBar};
`);
export const Line = withResponsive(styled.span`
	width: ${({ theme }) => theme.spacings[8]};
	height: ${({ theme }) => theme.spacings[0.5]};
	background-color: ${({ theme }) => theme.colors.interaction};
	display: block;
	margin: 3px auto;
	transition: all 0.3s ease-in-out;
`);

export const Hamburger = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: ${({ theme }) => theme.spacings[8]};

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		height: ${({ theme }) => theme.spacings[10]};
	}

	${Line} {
		&:nth-of-type(1) {
			transform: ${({ isOpen }) =>
				isOpen ? 'translateY(8px) rotate(45deg)' : ''};
		}
		&:nth-of-type(2) {
			opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
		}
		&:nth-of-type(3) {
			transform: ${({ isOpen }) =>
				isOpen ? 'translateY(-8px) rotate(-45deg)' : ''};
		}
	}
`;
