import React from 'react';
export const concatenateIfMany = (array) =>
	array?.map((item, i) => {
		const label = item?.Name ? item?.Name : item;
		return (
			<span key={`${label}-${i}`}>
				{label}
				{i + 1 != array.length && ', '}
			</span>
		);
	});
