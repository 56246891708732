import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { Spacer } from 'shared/layout';
import { MainBody, H1, H2 } from 'shared/components';
import {
	FullWidthColoredBackground,
	CampaignBlockContainer,
	PrefixHeader,
	PrefixText,
	TextWidthLimiter,
} from '../../pages/CampaignPage/CampaignPage.styles';
import {
	Line,
	YearButton,
	ArrowButtonsContainer,
	ArrowButton,
	ButtonsContainer,
	ContentContainer,
} from './CampaignTimelineBlock.styles';

const CampaignTimelineBlock = ({ Heading = '', ListItems = [] }) => {
	const theme = useContext(ThemeContext);

	const [selectedItem, setSelectedItem] = useState(0);
	const [buttonContainerOffset, setButtonContainerOffset] = useState(0);

	const buttonWidth = window.innerWidth > 480 ? 160 : 90;
	const columnGap = window.innerWidth > 480 ? 24 : 20;

	const handleItemClick = (index) => {
		setSelectedItem(index);
		const offset = -(index * (buttonWidth + columnGap));
		setButtonContainerOffset(offset);
	};

	const handlePrevClick = () => {
		setSelectedItem((prevItem) => Math.max(prevItem - 1, 0));
		setButtonContainerOffset(
			(prevOffset) => prevOffset + (buttonWidth + columnGap),
		);
	};

	const handleNextClick = () => {
		setSelectedItem((prevItem) =>
			Math.min(prevItem + 1, ListItems.length - 1),
		);
		setButtonContainerOffset(
			(prevOffset) => prevOffset - (buttonWidth + columnGap),
		);
	};

	return (
		<FullWidthColoredBackground $color={theme.color[1]}>
			<CampaignBlockContainer $theme={theme}>
				<ContentContainer>
					<TextWidthLimiter alt2 style={{ gridArea: 'text' }}>
						<div className="timeline-heading">
							<Spacer>
								<PrefixHeader as="h2" $theme={theme}>
									{Heading}
								</PrefixHeader>
							</Spacer>
						</div>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
							}}
						>
							<div style={{ alignSelf: 'start' }}></div>
						</div>
						<div className="timeline-content">
							<div key={ListItems} className="timeline-item">
								<Spacer>
									<H1 as="h3" color={theme.color[3]}>
										{ListItems[selectedItem].Heading}
									</H1>
								</Spacer>
								<Spacer>
									<MainBody color={theme.color[3]}>
										{ListItems[selectedItem].Preamble}
									</MainBody>
								</Spacer>
							</div>
						</div>
					</TextWidthLimiter>
					<ArrowButtonsContainer $theme={theme}>
						<PrefixText
							$noUppercase
							as="span"
							$color={theme.color[3]}
						>
							{`${selectedItem + 1} av ${ListItems.length}`}
						</PrefixText>
						<ArrowButton
							$direction="left"
							alt="Button to move timeline left"
							onClick={handlePrevClick}
							disabled={selectedItem === 0}
							$theme={theme}
						></ArrowButton>
						<ArrowButton
							$direction="right"
							alt="Button to move timeline right"
							onClick={handleNextClick}
							disabled={selectedItem === ListItems.length - 1}
							$theme={theme}
						></ArrowButton>
					</ArrowButtonsContainer>
					<ButtonsContainer
						style={{ left: `${buttonContainerOffset}px` }}
					>
						{ListItems.map((item, index) => (
							<YearButton
								$theme={theme}
								key={index}
								onClick={() => handleItemClick(index)}
								active={selectedItem === index}
							>
								{selectedItem === index ? (
									<H1 as="h3" color={theme.color[1]}>
										{item.ButtonText}
									</H1>
								) : (
									<H2 color={theme.color[1]}>
										{item.ButtonText}
									</H2>
								)}
							</YearButton>
						))}
						<Line $theme={theme} />
					</ButtonsContainer>
				</ContentContainer>
			</CampaignBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignTimelineBlock;

// CampaignVideoBlock.propTypes = {
//	Heading: PropTypes.string,
//	ListItems: PropTypes.array,
//};
