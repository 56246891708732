import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { BaseLink } from 'shared/components';

export const Container = withResponsive(styled.div`
	box-shadow: ${({ theme }) => `${theme.boxShadows.contact}
		${theme.colors.lightGrey}`};
	border: 1px solid ${({ theme }) => theme.colors.borderGrey};
	display: flex;
	margin: ${({ theme }) => theme.spacings[8]} auto 0;
	max-width: ${({ theme }) => theme.spacings[128]};
`);
export const StickyContainer = withResponsive(styled(Container)`
	position: relative;
	flex-direction: column;
	padding: ${({ theme }) => theme.spacings[6]};
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme }) => theme.spacings[8]};
		justify-content: center;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
		position: sticky;
		float: right;
		max-width: ${({ theme }) => theme.spacings[40]};
		top: ${({ theme }) => theme.spacings[60]};
	}
`);

export const SocialLink = withResponsive(styled(BaseLink)`
	color: ${({ theme }) => theme.colors.secondary};
	transition: 0.3s ease all;
	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.primary};
	}
`);

export const CopyDialog = withResponsive(styled.div.attrs(({ show }) => ({
	'aria-hidden': !show ? 'true' : 'false',
}))`
	transition: opacity 0.5s;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ theme }) => theme.spacings[8]};

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		right: 18px;
		/* top: 5px; */
		bottom: -2px;
	}
`);
