import React, { useEffect, useState } from 'react';
import { getYoutubeVideo, getVimeoVideo, getYoutubeId } from 'shared/helpers';
import { Caption } from 'shared/components';
import { MaxWidthContainer, Spacer } from 'shared/layout';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { VideoWrapper } from './Video.styles';
import { videoVariants } from 'shared/consts';

const Video = ({
	VideoType = 'youtube',
	VideoLink = '',
	VideoText = '',
	theme = {},
	noPadding = false,
}) => {
	let [videoEnded, setVideoEnded] = useState(false);
	const [videoUrl, setVideoUrl] = useState('');
	const [loaded, setLoaded] = useState(false);
	function onYouTubeIframeAPIReady() {
		if (loaded) {
			new window.YT.Player('TCO-video', {
				events: {
					onStateChange: onPlayerStateChange,
				},
				videoId: getYoutubeId(VideoLink),
			});
		}
	}

	function onPlayerStateChange({ data = 1 }) {
		let isVideoEnded = videoEnded;
		if (data == window.YT.PlayerState.ENDED) {
			isVideoEnded = true;
		} else if (data === window.YT.PlayerState.PLAYING) {
			if (isVideoEnded) videoEnded = false;
		}
		setVideoEnded(isVideoEnded);
	}

	useEffect(() => {
		switch (VideoType) {
			case videoVariants.YOUTUBE:
				window.YT?.ready(() => {
					onYouTubeIframeAPIReady();
				});
				setVideoUrl(getYoutubeVideo(VideoLink));
				break;
			case videoVariants.VIMEO:
				setVideoUrl(getVimeoVideo(VideoLink));
				break;
		}
	}, [loaded]);
	return (
		<>
			<MaxWidthContainer noGutters noPadding={noPadding}>
				{VideoType === videoVariants.YOUTUBE && (
					<Script
						strategy="afterInteractive"
						src="https://www.youtube.com/iframe_api"
						onLoad={() => {
							setLoaded(true);
						}}
					/>
				)}

				<VideoWrapper
					width="100%"
					videoEnded={videoEnded}
					isVimeo={VideoType === videoVariants.VIMEO}
				>
					{videoUrl && (
						<iframe
							id="TCO-video"
							enablejsapi="1"
							frameBorder="0"
							width="100%"
							height="100%"
							src={videoUrl}
							loading="lazy"
							title="Video"
						/>
					)}
				</VideoWrapper>
				{!!VideoText.length && (
					<Spacer spacing={5}>
						<Caption
							color={theme.colors.text}
							font-weight={theme.typography.fontWeights.light}
							textTransform="none"
						>
							{VideoText}
						</Caption>
					</Spacer>
				)}
			</MaxWidthContainer>
		</>
	);
};

export default withTheme(Video);
Video.proptypes = {
	VideoTypes: PropTypes.string,
	VideoLink: PropTypes.string,
	theme: PropTypes.object,
};
