import styled from 'styled-components';
import { breakpoints, breakpointsForResponsiveProps } from '../core';

const mixins = [
	'flex',
	'flex-basis',
	'flex-direction',
	'align-items',
	'align-self',
	'flex-wrap',
	'justify-content',
	'order',
	'display',
	'background',
	'color',
	'margin',
	'max-width',
	'max-height',
	'min-height',
	'min-width',
	'padding',
	'padding-top',
	'padding-bottom',
	'padding-left',
	'padding-right',
	'margin',
	'margin-top',
	'margin-right',
	'margin-bottom',
	'border',
	'margin-left',
	'border-left',
	'border-radius',
	'border-top',
	'border-bottom',
	'cursor',
	'content',
	'position',
	'top',
	'right',
	'font-size',
	'font-weight',
	'width',
	'height',
	'z-index',
	'box-shadow',
	'text-align',
	'line-height',
	'gap',
	'overflow',
	'overflow-y',
	'overflow-x',
	'order',
	'list-style-type',
	'word-break',
	'bottom',
];

const withResponsive = (StyledComponent) => {
	return styled(StyledComponent)`
		&& {
			${LoopThroughStyles};
		}
	`;
};

export default withResponsive;

const GetStyleArray = (styleKey, styleObj, props) => {
	const isObject = typeof styleObj === 'object' && styleObj !== null;
	const styleKeys = isObject ? Object.keys(styleObj) : ['base'];
	const breakpointKeys = Object.keys(breakpoints);
	let styleArray = [];

	breakpointKeys.forEach((breakpoint) => {
		const style = props[styleKey];

		if (!isObject && breakpoint === 'base') {
			styleArray.push(styleObj);
		} else {
			styleArray.push(
				!styleKeys.includes(breakpoint)
					? breakpoint === 'base' && styleKeys.includes('sm')
						? style['sm']
						: null
					: style[breakpoint],
			);
		}
	});
	return styleArray;
};

const LoopThroughStyles = (props) => {
	const keys = Object.keys(props);
	let styleArray = [];
	keys.forEach((styleKey) => {
		if (!mixins.includes(styleKey)) {
			return;
		}
		styleArray.push({
			property: styleKey,
			values: GetStyleArray(styleKey, props[styleKey], props),
		});
	});
	return styleArray.length ? createMediaQueries(styleArray) : undefined;
};

export const createMediaQueries = (cssObj) => {
	const cssKeyValuePairs = cssObj.reduce((items, item) => {
		const { property, values } = item;

		items.push(
			Array.isArray(item.values)
				? values.map((value) => ({
						[property]: value,
					}))
				: [{ [property]: values }],
		);
		return items;
	}, []);

	const cssToBreakpoints = [0, ...breakpointsForResponsiveProps]
		.map((breakpoint, index) => ({
			breakpoint: breakpoint,
			cssObj: cssKeyValuePairs
				.map((array) => array[index])
				.filter(Boolean)
				.reduce((items, item) => {
					items[`${Object.keys(item)}`] = `${Object.values(item)}`;
					return items;
				}, {}),
		}))
		.slice(0, -1);

	const cssMediaQueries = cssToBreakpoints.reduce((items, item) => {
		const { breakpoint, cssObj } = item;

		breakpoint
			? (items[`@media screen and (min-width: ${breakpoint})`] = {
					...cssObj,
				})
			: (items = { ...cssObj });

		return items;
	}, {});

	return {
		...cssMediaQueries,
	};
};
