import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { MaxWidthContainer, Spacer, Box } from 'shared/layout';
import { H2, RteContent } from 'shared/components';
import PropTypes from 'prop-types';

import { ScriptBlockWrapper } from './ScriptBlock.styles';

const ScriptBlock = ({
	Heading = '',
	Preamble = '',
	theme = {},
	ScriptId = '',
	isCampaignPage = false,
}) => {
	const [iframeHeight, setIframeHeight] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			const iframeWidth = document.getElementById(ScriptId).offsetWidth;
			setIframeHeight(iframeWidth * 1.2);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [ScriptId]);

	return (
		<>
			<MaxWidthContainer narrow>
				<ScriptBlockWrapper
					flex-direction={{
						base: 'column',
					}}
					align-items="left"
					hasMargin={!isCampaignPage}
					justify-content="space-between"
				>
					<Box
						flex-basis={{ base: '100%', lg: '35%' }}
						flex-direction="column"
						max-width={theme.spacings[180]}
						position="relative"
						padding={`0 ${theme.spacings[4]} 0 ${theme.spacings[4]}`}
					>
						{!!Heading?.length && (
							<Spacer spacing={{ base: 14, md: 0 }}>
								<H2>{Heading}</H2>
							</Spacer>
						)}

						{!!Preamble?.length && (
							<Spacer spacing={3}>
								<RteContent text={Preamble} />
							</Spacer>
						)}
						<Spacer spacing={6}></Spacer>
					</Box>
					<Box
						position="relative"
						flex-basis={{ base: '100%', lg: '65%' }}
						max-width={{ lg: '100%' }}
						width="100%"
						padding={`0 ${theme.spacings[0]} 0 ${theme.spacings[4]}`}
					>
						<iframe
							id={ScriptId}
							src={`https://e.infogram.com/${ScriptId}?src=embed`}
							title="infogram"
							width="100%"
							height={iframeHeight}
							scrolling="no"
							frameBorder="0"
							style={{ border: 'none' }}
							allowFullScreen
							loading="lazy"
						></iframe>
					</Box>
				</ScriptBlockWrapper>
			</MaxWidthContainer>
		</>
	);
};

export default withTheme(ScriptBlock);
ScriptBlock.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	FullWidth: PropTypes.bool,
	IframeHTML: PropTypes.string,
	AlignedRight: PropTypes.bool,
	ScriptId: PropTypes.string,
};
