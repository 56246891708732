import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '..';
import { Box } from 'shared/layout/Box';
import { StyledInput } from './Input.styles';

const Input = ({ theme = {}, children, inputRef = {}, ...props }) => {
	return (
		<Box
			width="100%"
			position="relative"
			display="flex"
			align-items="center"
		>
			<>
				<StyledInput
					ref={inputRef}
					icon={props.valid || props.disabled}
					{...props}
				/>
				{children}
				{(props.disabled || props.valid) && (
					<Icon
						color={
							props.disabled
								? theme.colors.disabled
								: props.valid
									? theme.colors.secondary
									: ''
						}
						name={
							props.disabled
								? 'disabled'
								: props.valid
									? 'checkmark'
									: ''
						}
					/>
				)}
			</>
		</Box>
	);
};

export default withTheme(Input);

Input.propTypes = {
	disabled: PropTypes.bool,
	valid: PropTypes.bool,
	error: PropTypes.bool,
	iconSize: PropTypes.string,
	icon: PropTypes.string,
	inputRef: PropTypes.object,
};
