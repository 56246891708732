import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Spacer } from 'shared/layout';
import { H2, Caption, Icon } from 'shared/components';
import { BlockItem, BlockItemArrow } from './PageListingBlock.styles';
import Link from 'next/link';

const PageListingBlockItem = ({
	theme = {},
	Heading = '',
	Preamble = '',
	Url = '',
	isTwo = false,
}) => {
	return (
		<Link passHref href={Url}>
			<BlockItem isTwo={isTwo} className="white-tab" title={Heading}>
				<Spacer spacing={{ base: 0, md: 6 }}>
					<H2>{Heading}</H2>
				</Spacer>
				<Spacer spacing={{ base: 0, md: 4 }}>
					<Caption
						display={{ base: 'none', md: 'block' }}
						textTransform="unset"
						font-weight={theme.typography.fontWeights.normal}
						line-height={theme.spacings[6]}
					>
						{Preamble}
					</Caption>
				</Spacer>
				<BlockItemArrow>
					<Icon name="special-arrow" />
				</BlockItemArrow>
			</BlockItem>
		</Link>
	);
};

PageListingBlockItem.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	Url: PropTypes.string,
	isTwo: PropTypes.bool,
};

export default withTheme(PageListingBlockItem);
