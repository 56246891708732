import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const StyledTextWrapper = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		background: ${({ theme, campaignHero }) =>
			campaignHero
				? `linear-gradient(to right, ${theme.colors.primary} 70% , transparent 0%)`
				: `linear-gradient(to right, ${theme.colors.primary} 65% , transparent 0%)`};
		height: fit-content;
		margin-bottom: ${({ theme }) => theme.spacings[12]};
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		background: ${({ theme, campaignHero }) =>
			campaignHero
				? `linear-gradient(to right, ${theme.colors.primary} 70% , transparent 0%)`
				: `linear-gradient(to right, ${theme.colors.primary} 50% , transparent 0%)`};
	}

	background: ${({ theme }) => theme.colors.primary};

	margin-top: ${({ theme }) => theme.spacings[64]};

	flex-direction: column;
	color: ${({ theme }) => theme.colors.white};
	z-index: ${({ theme }) => theme.zIndexes.base};
	min-height: 50%;
	width: auto;
`);
