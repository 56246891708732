import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Spacer } from 'shared/layout';
import { RteContent, Caption, H2, H3, MainBody } from 'shared/components';
import { StyledTextWrapper } from './';
import { concatenateIfMany } from 'shared/helpers';

import { useMediaQuery } from 'shared/hooks/useMediaQuery';

import Link from 'next/link';
const Puff = ({
	Heading = '',
	Preamble = '',
	Categories = [],
	theme = {},
	Url = '',
	isFirstSlide = false,
	isThree = false,
}) => {
	const HeadingComponent = useMediaQuery(
		`(min-width: ${theme.breakpoints.md})`,
		true,
	)
		? isThree
			? H3
			: H2
		: H3;

	return (
		<Link
			passHref
			{...(process.env.STORYBOOK === 'true' ? { prefetch: false } : {})}
			href={Url}
		>
			<StyledTextWrapper
				isFirstSlide={isFirstSlide}
				min-height={theme.spacings[76]}
				isThree={isThree}
			>
				<Caption color={theme.colors.puffText}>
					{concatenateIfMany(Categories)}
				</Caption>
				<Spacer spacing={4}>
					<HeadingComponent
						as="h3"
						color={theme.colors.interaction}
						noColor
					>
						<RteContent
							color={theme.colors.interaction}
							text={Heading}
						/>
					</HeadingComponent>
				</Spacer>
				<Spacer spacing={4}>
					<MainBody
						line-height={{ md: isThree ? theme.spacings[6] : '' }}
						font-size={{
							md: isThree
								? theme.typography.fontSizes.desktop.body2
								: '',
						}}
						color={theme.colors.puffText}
					>
						{Preamble}
					</MainBody>
				</Spacer>
			</StyledTextWrapper>
		</Link>
	);
};

Puff.propTypes = {
	Header: PropTypes.string,
	Preamble: PropTypes.string,
	Categories: PropTypes.array,
	Url: PropTypes.string,
	isFirstSlide: PropTypes.bool,
};

export default withTheme(Puff);
