import React from 'react';
import { FilterText } from './Search.styles';

const cats = {
	1: 'categories',
	2: 'contacts',
	3: 'documentTypes',
	4: 'years',
};

export const mapCategories = (type, distinctCats) => {
	const category = distinctCats.find((x) => x.Id === type?.Id);
	if (category && category.Id) {
		return cats[category.Id] || 'categories';
	}
	return 'categories';
};

export const CategoryListing = ({
	categories = [],
	contacts = [],
	years = [],
	documentTypes = [],
}) => {
	const allFilters = [...categories, ...contacts, ...years, ...documentTypes];
	return allFilters.map((filter, index) => (
		<FilterText key={`filter-cat-${index}`}>{filter.Name}</FilterText>
	));
};

export const dataToDropdown = (buttons) => {
	return buttons.map(({ Name, Id }) => ({ value: Id, title: Name }));
};

export const fromDropdownData = (items) => {
	return items.map(({ value, title }) => ({ Id: value, Name: title }));
};

export const filterCategories = (cats, cat) => {
	return cats.filter((item) => {
		return item?.Type?.Id == cat?.Id;
	});
};

export const getFilterOrInitial = (filt, init) => {
	//If there is only one documentType as initial, never let it be []
	if (init.find((x) => x.Type.Id === '3') && init.length === 1)
		return init.map((x) => x.Id);

	//If any filters are chosen, return all selected for current filter type
	if (filt?.length) return filt.map((x) => x.Id);

	//if no filters are selected, return inital data
	return init.map((x) => x.Id);
};
