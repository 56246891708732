import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import {
	StickyContainer,
	SocialLink,
	CopyDialog,
} from './ShareComponent.styles';

import { Caption, Icon, FadeIn } from 'shared/components';

import { Box } from 'shared/layout';
const handleKeyPress = (e) => {
	e.preventDefault();

	e.key === 'Enter' && navigator.clipboard.writeText(window.location.href);
};

const ShareComponent = ({
	ShareComponentHeading = 'Dela innehållet',
	ShareComponentHideAll = false,
	ShareComponentHideFacebook = false,
	ShareComponentHideLinkedin = false,
	ShareComponentHideMail = false,
	ShareComponentHideX = false,
	ShareComponentShareUrl = false,
	mailTitle = '',
	CopiedLabel = 'Kopierad',
	theme = {},
	isCampaignPage = false,
}) => {
	if (
		ShareComponentHideAll ||
		(ShareComponentHideFacebook &&
			ShareComponentHideLinkedin &&
			ShareComponentHideMail &&
			ShareComponentHideX &&
			ShareComponentShareUrl)
	)
		return null;
	const [topMargin, setTopMargin] = useState(0);
	const [showCopyDialog, setShowCopyDialog] = useState(false);
	const [render, setRender] = useState(false);
	useEffect(() => {
		setRender(true);
	}, []);

	useEffect(() => {
		setTopMargin(
			document.querySelector('#information-page-top')?.clientHeight / 4,
		);
		return () => {
			setTopMargin(0);
		};
	}, []);
	if (!topMargin && !isCampaignPage) return null;

	const openDialog = () => {
		setShowCopyDialog(true);

		setTimeout(() => {
			setShowCopyDialog(false);
		}, 2000);
	};

	const ShareList = () => {
		return (
			<Box
				display="flex"
				flex-direction={{ base: 'row', lg: 'column' }}
				padding-top={{ base: theme.spacings[4], lg: 0 }}
				id="share-component"
				justify-content="center"
			>
				{!ShareComponentHideFacebook && render && (
					<SocialLink
						target="_blank"
						href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
						margin={{
							base: `0 ${theme.spacings[4]} 0 0 `,
							lg: `${theme.spacings[4]} 0 0`,
						}}
						aria-label={`Dela sida på Facebook`}
					>
						<Icon font-size="3rem" name="facebook" />
					</SocialLink>
				)}

				{!ShareComponentHideLinkedin && render && (
					<SocialLink
						target="_blank"
						href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
						margin={{
							base: `0 ${theme.spacings[4]} 0 0 `,
							lg: `${theme.spacings[4]} 0 0`,
						}}
						aria-label={`Dela sida på Linkedin`}
					>
						<Icon font-size="3rem" name="linkedIn" />
					</SocialLink>
				)}
				{!ShareComponentHideMail && render && (
					<SocialLink
						target="_blank"
						href={`mailto:?body=${window.location.href}&subject=${mailTitle}`}
						margin={{
							base: `0 ${theme.spacings[4]} 0 0 `,
							lg: `${theme.spacings[4]} 0 0`,
						}}
						aria-label={`Dela sida via Mail`}
					>
						<Icon font-size="3rem" name="mail" />
					</SocialLink>
				)}
				{!ShareComponentShareUrl && render && (
					<>
						<SocialLink
							href={window.location.href}
							margin={{
								base: `0 `,
								lg: `${theme.spacings[4]} 0 0`,
							}}
							aria-label={`Kopiera sidans url`}
							onClick={(e) => {
								e.preventDefault();

								openDialog();
								navigator.clipboard.writeText(
									window.location.href,
								);
							}}
							onKeyPress={(e) => handleKeyPress(e)}
						>
							<Icon font-size="3rem" name="link" />
						</SocialLink>
						{showCopyDialog && (
							<FadeIn display="flex" justify-content="center">
								<CopyDialog>
									<Caption textTransform="none">
										{CopiedLabel}
									</Caption>
								</CopyDialog>
							</FadeIn>
						)}
					</>
				)}
			</Box>
		);
	};
	return (
		<StickyContainer
			marginTop={topMargin}
			style={{
				backgroundColor: '#ffffff',
				zIndex: 2,
			}}
		>
			<Caption text-align="center">{ShareComponentHeading}</Caption>
			<ShareList />
		</StickyContainer>
	);
};

export default withTheme(ShareComponent);
