import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../../layout';

export const DropDownOptionsBox = ({ id, innerRef, isOpen, ...props }) => {
	const theme = useTheme();
	return (
		<Box
			ref={innerRef}
			position="absolute"
			width="100%"
			max-height="380px"
			overflow-y="scroll"
			z-index="9999"
			id={id}
			display={isOpen ? 'flex' : 'none'}
			flex-direction="column"
			top="calc(100% + 12px)"
			background="white"
			box-shadow={`0 0 0 2px ${
				isOpen ? theme.colors.secondary : theme.colors.primary
			}`}
		>
			{props.children}
		</Box>
	);
};
