import PropTypes from 'prop-types';
import React from 'react';
import { Icon, MainBody } from 'shared/components';
import { Spacer } from 'shared/layout';
import styled, { css, withTheme } from 'styled-components';
import { Button } from './SearchButton.styles';
import Link from 'next/link';

const IconWrapper = styled.div`
	${({ isSearchBar, theme }) =>
		isSearchBar &&
		css`
			&:hover {
				use {
					transition: ${theme.transitions.button};

					fill: ${theme.colors.primary};
					stroke: ${theme.colors.white};
				}
			}
			use {
				transition: ${theme.transitions.button};

				fill: ${theme.colors.white};
				stroke: ${theme.colors.primary};
			}
		`}
`;
const SearchButton = ({
	title = '',
	theme = {},
	href = '/',
	handleMenuClick = () => {},
	isSearchBar = false,
	isOpen = false,
	...rest
}) => {
	const renderButton = (
		<Button onClick={handleMenuClick} {...rest} title={title ?? 'Sök'}>
			<IconWrapper isSearchBar={isSearchBar}>
				<Icon
					// color={color || theme.colors.interaction}
					fill={
						!isOpen
							? theme.colors.primary
							: theme.colors.interaction
					}
					stroke={
						isOpen ? theme.colors.primary : theme.colors.interaction
					}
					name="search"
					// name={!isOpen ? 'search' : 'search-filled'}
					font-size={{
						base: theme.spacings[11],
						lg: theme.spacings[11],
					}}
				/>
			</IconWrapper>
			<Spacer spacing={1} />
			<MainBody
				font-size={theme.typography.fontSizes.desktop.caption}
				color={{
					base: theme.colors.white,

					sm: theme.colors.interaction,
				}}
			>
				{title}
			</MainBody>
		</Button>
	);
	return rest.as != 'button' ? (
		<Link passHref href={href ? href : '/'}>
			{renderButton}
		</Link>
	) : (
		renderButton
	);
};

export default withTheme(SearchButton);
SearchButton.propTypes = {
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	handleMenuClick: PropTypes.func,
	handleKeyPress: PropTypes.func,
};
