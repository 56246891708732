import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

const Flex = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: row;
	}
`;

export default withResponsive(Flex);
