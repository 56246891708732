export const SearchPageMeta = ({
	MetaTitle = '',
	MetaDescription = '',
	ModifiedDate = '',
	PublishedDate = '',
	asPath = '',
}) => {
	//Alla kommentarer är saker som behöver komma från backend/inte säker på var dom kommer ifrån
	return {
		'@context': 'https://schema.org/',
		'@type': 'Search',
		mainEntityOfPage: 'https://www.tco.se',
		datePublished: `${PublishedDate}`, //YYYY-MM-DDTHH:MM:SS
		dateModified: `${ModifiedDate}`, //YYYY-MM-DDTHH:MM:SS
		url: process.env.NEXT_PUBLIC_FRONTEND_URL
			? process.env.NEXT_PUBLIC_FRONTEND_URL + asPath
			: '',
		headline: `${MetaTitle}`,
		description: `${MetaDescription}`,
		publisher: {
			'@type': 'Organization',
			name: 'TCO',
			telephone: '', //08-782 91 00
			email: '', //info@tco.se
			description: '', //Beskrivande text om TCO
			foundingDate: '1944',
		},
		address: {
			'@type': 'PostalAddress',
			streetAddress: '', //Linnégatan 14
			postalCode: '', //11494
			addressLocality: '', //Stockholm
			addressCountry: {
				'@type': 'Country',
				name: 'SE',
			},
		},
		logo: {
			'@type': 'ImageObject',
			url: '', //Url till loggan
			width: '', //Loggans width
			height: '', //Loggans höjd
		},
	};
};
