import { createGlobalStyle } from 'styled-components';
import _normalize from './_normalize';
import _icons from './_icons';
import { mainBody } from '../components/Text/Text';

const GlobalStyle = createGlobalStyle`
    ${_normalize};

    .is-tabbed *:focus {
        outline: 2px solid ${({ theme }) => theme.colors.primary};
        outline-offset: 5px;
    }
    .is-tabbed .white-tab:focus {
        outline: 2px solid ${({ theme }) => theme.colors.white};
        outline-offset: 5px;
        text-decoration: underline;
    }
    body {
      ${mainBody}
    }

    body { overflow-x: hidden; }

    main {
      min-height: 70vh;
    }

    a {
      color: inherit;
      line-height: inherit;
    }

    ${_icons};
`;

export default GlobalStyle;
