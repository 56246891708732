import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

const ColumnContainer = styled.div.attrs(
	({ largeMargin, noGutters, theme, smallMargin }) => ({
		smallMargin: smallMargin
			? smallMargin
			: noGutters
				? undefined
				: theme.spacings.gutterMobile,
		largeMargin: largeMargin
			? largeMargin
			: noGutters
				? undefined
				: theme.spacings.gutter,
	}),
)`
	display: flex;
	flex-wrap: ${({ noWrap = false }) => (noWrap ? 'nowrap' : 'wrap')};
	margin-left: ${({ noGutters, smallMargin }) =>
		noGutters ? undefined : `-${smallMargin}`};
	margin-right: ${({ noGutters, smallMargin }) =>
		noGutters ? undefined : `-${smallMargin}`};
	width: ${({ noGutters, smallMargin }) =>
		noGutters ? undefined : `calc(100% + ${smallMargin} * 2)`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-left: ${({ noGutters, largeMargin }) =>
			noGutters ? undefined : `-${largeMargin}`};
		margin-right: ${({ noGutters, largeMargin }) =>
			noGutters ? undefined : `-${largeMargin}`};
		width: ${({ noGutters, largeMargin }) =>
			noGutters ? undefined : `calc(100% + ${largeMargin} * 2)`};
	}
`;

export default withResponsive(ColumnContainer);
