import { ThemeContext, ThemeProvider } from 'styled-components';
import { themeVariants } from 'shared/consts';
import {
	primaryTheme,
	frostTheme,
	whiteTheme,
	defaultTheme,
} from 'shared/themes';
import React, { useContext } from 'react';
export const MapTheme = ({ theme: newTheme, children }) => {
	const currentTheme = useContext(ThemeContext);

	const mapPropToTheme = (themeKey) => {
		switch (themeKey) {
			case themeVariants.FROST:
				return frostTheme;
			case themeVariants.WHITE:
				return whiteTheme;
			case themeVariants.PRIMARY:
				return primaryTheme;
			default:
				return defaultTheme;
		}
	};
	return (
		<ThemeProvider
			theme={mergeThemes(currentTheme, mapPropToTheme(newTheme))}
		>
			{children}
		</ThemeProvider>
	);
};

const merge = (old, cur) => {
	const res = { ...old };
	for (const key of Object.keys(cur)) {
		const val = cur[key];
		if (
			res[key] === null ||
			typeof val !== 'object' ||
			typeof res[key] !== 'object' ||
			Array.isArray(val)
		) {
			res[key] = val;
		} else {
			res[key] = merge(res[key], val);
		}
	}
	return res;
};

const mergeThemes =
	(...themes) =>
	(parent) => {
		for (const theme of themes) {
			parent = merge(parent, theme);
		}
		return parent;
	};
