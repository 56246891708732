import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { Spacer, Box } from 'shared/layout';
import PropTypes from 'prop-types';
import { H1, MainBody } from 'shared/components';
import {
	FullWidthColoredBackground,
	CampaignBlockContainer,
} from '../../pages/CampaignPage/CampaignPage.styles';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const CampaignInfogramBlock = ({
	Heading = 'Vikten av kompoetensutveckling',
	Preamble = '',
	ScriptDesktopId = '',
	ScriptMobileId = '',
}) => {
	const theme = useContext(ThemeContext);
	const [iframeHeight, setIframeHeight] = useState(0);

	const isSiteWidthUp = useMediaQuery(`(min-width: ${theme.size.siteWidth})`);

	let scriptId = ScriptDesktopId;

	if (!isSiteWidthUp && ScriptMobileId != null) {
		scriptId = ScriptMobileId;
	}

	useEffect(() => {
		const handleResize = () => {
			const iframeWidth = document.getElementById(scriptId).offsetWidth;

			if (isSiteWidthUp) setIframeHeight(iframeWidth / 1.7);
			else setIframeHeight(iframeWidth);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [scriptId]);

	return (
		<FullWidthColoredBackground $color={theme.color[1]}>
			<CampaignBlockContainer $theme={theme}>
				{!!Heading && (
					<H1
						word-break={{ base: 'break-word', md: 'unset' }}
						as="h2"
						color={theme.color[3]}
					>
						{Heading}
					</H1>
				)}
				{!!Preamble && (
					<>
						<Spacer spacing={{ base: 6.5, md: 8 }}>
							<MainBody as="p" color={theme.color[3]}>
								{Preamble}
							</MainBody>
						</Spacer>
						<Spacer spacing={{ base: 4, lg: 8 }} />
					</>
				)}
				{!!scriptId && (
					<div
						flex-direction={{
							base: 'column',
						}}
						align-items="center"
						justify-content="space-between"
					>
						<Box
							position="relative"
							flex-basis={{ base: '100%', lg: '65%' }}
							max-width={{ lg: '100%' }}
							width="100%"
						>
							<iframe
								id={scriptId}
								src={`https://e.infogram.com/${scriptId}?src=embed`}
								title="infogram"
								width="100%"
								height={iframeHeight}
								scrolling="no"
								frameBorder="0"
								style={{ border: 'none' }}
								allowFullScreen
							></iframe>
						</Box>
					</div>
				)}
			</CampaignBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignInfogramBlock;

CampaignInfogramBlock.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	theme: PropTypes.object,
	ScriptDesktopId: PropTypes.string,
	ScriptMobileId: PropTypes.string,
};
