import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import AnimateHeight from 'react-animate-height';

import { Container, ShadowBox } from './EmphasizedContent.styles';

import { H5, RteContent, Icon } from 'shared/components';

import { Flex } from 'shared/layout';

const EmphasizedContent = ({
	theme = {},
	EmphasizedMainContent = '',
	EmphasizedHeading = '',
	EmphasizedIsOpen = false,
}) => {
	if (!EmphasizedMainContent) return null;

	const [isOpen, setIsOpen] = useState(EmphasizedIsOpen);

	return (
		<Container isOpen={isOpen}>
			<Flex
				justify-content="space-between"
				flex-direction="row"
				align-items="flex-start"
				onClick={() => setIsOpen(!isOpen)}
				cursor="pointer"
			>
				<H5
					as="h2"
					max-width={{ base: '90%', md: '80%' }}
					border-bottom={`1px solid ${theme.colors.breaklineGrey}`}
					padding-bottom={theme.spacings[2]}
					onKeyPress={(e) => e.key === 'Enter' && setIsOpen(!isOpen)}
					tabIndex="0"
					aria-expanded={isOpen}
				>
					{EmphasizedHeading}
				</H5>
				<Icon
					name={isOpen ? 'minus' : 'plus'}
					font-size={theme.spacings[5]}
					color={theme.colors.primary}
				/>
			</Flex>
			<ShadowBox isOpen={isOpen}>
				<AnimateHeight height={isOpen ? 'auto' : 30} duration={500}>
					<RteContent
						text={EmphasizedMainContent}
						forceTabIndex={isOpen}
					/>
				</AnimateHeight>
			</ShadowBox>
		</Container>
	);
};

EmphasizedContent.propTypes = {
	EmphasizedHeading: PropTypes.string,
	EmphasizedMainContent: PropTypes.string,
	EmphasizedIsOpen: PropTypes.bool,
};

export default withTheme(EmphasizedContent);
