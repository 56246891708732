import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { h2, mainBody } from 'shared/components';
export const MenuWrapper = withResponsive(styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
	width: 100%;
	left: 0;
	background: transparent;
	z-index: ${({ theme, show }) =>
		show ? theme.zIndexes.overlay : theme.zIndexes.hide};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	overflow-y: ${({ noScroll }) => !noScroll && 'scroll'};
	transition: all 1s ease;
	overflow-x: hidden;
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		transition: all 0.5s ease;
		backdrop-filter: ${({ show }) => (show ? 'blur(1px)' : 'none')};
	}
`);

export const MenuContainer = styled.div`
	background: ${({ theme }) => theme.colors.primary};
	width: 100%;
	height: 100vh;
	right: 0;
	transform: ${({ show }) => (show ? `translateX(0)` : `translateX(100%)`)};
	transition: transform 1s ease;

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		max-width: 50%;
		transform: ${({ show }) =>
			show ? `translateX(0)` : `translateX(75vw)`};
	}
`;

export const MenuFill = styled.div`
	min-height: 100vh;
	height: 100%;
	width: 50vw;
	position: absolute;
	top: 0;
	z-index: ${({ theme }) => theme.zIndexes.hide};
	background: ${({ theme }) => theme.colors.primary};
`;

export const LanguageLinkWrapper = styled.div`
	padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[4]}`};
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[18]}`};
	}
`;

export const MenuLinkText = styled.p`
	${({ child }) => (child ? mainBody : h2)};
	text-decoration: ${({ child }) => (child ? 'underline' : 'none')};
	display: flex;
	align-items: center;
	&& {
		padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[4]}`};
		color: ${({ theme }) => theme.colors.white};
		@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
			padding: ${({ theme }) =>
				`${theme.spacings[2]} ${theme.spacings[18]}`};
		}
	}
	background: ${({ theme, active }) =>
		active ? theme.colors.secondaryThinner : theme.colors.primary};
	transition: background 0.3s ease;
	&:hover,
	&:focus {
		background: ${({ theme }) => theme.colors.primaryDark};
		cursor: pointer;
	}
`;

export const MenuLanguageLinkText = styled.p`
	${({ child }) => (child ? mainBody : h2)};
	text-decoration: ${({ child }) => (child ? 'none' : 'none')};
	display: flex;
	align-items: center;
	&& {
		padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[4]}`};
		color: ${({ theme }) => theme.colors.white};
		@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
			padding: ${({ theme }) =>
				`${theme.spacings[2]} ${theme.spacings[18]}`};
		}
	}
	background: ${({ theme, active }) =>
		active ? theme.colors.secondaryThinner : theme.colors.primary};
	transition: background 0.3s ease;
	&:hover,
	&:focus {
		background: ${({ theme }) => theme.colors.primaryDark};
		cursor: pointer;
	}
`;

export const ExpandButton = styled.button`
	position: absolute;
	right: ${({ theme }) => theme.spacings[6]};
	font-size: ${({ theme }) => theme.spacings[5]};
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		right: ${({ theme }) => theme.spacings[9]};
	}
`;

export const TopMenuColorFill = styled.div`
	position: sticky;
	top: 0;
	width: 100%;
	height: ${({ theme }) => theme.spacings.headerHeight.base};
	background: ${({ theme }) => theme.colors.primary};
	z-index: ${({ theme }) => theme.zIndexes.overlay};

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		height: ${({ theme }) => theme.spacings.headerHeight.large};
	}
`;
