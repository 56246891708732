const typography = {
	fonts: {
		heading: 'WorkSans',
		text: 'Brioni Sans Std',
	},
	fontSizes: {
		desktop: {
			captionSmall: '0.75rem',
			caption: '0.875rem',
			body2: '1.125rem',
			body: '1.25rem',
			h5: '1.25rem',
			h4: '1.25rem',
			h3: '1.5rem',
			h2: '2rem',
			h1: '3rem',
			headline: '5rem',
		},
		mobile: {
			captionSmall: '0.75rem',
			caption: '0.875rem',
			body: '1rem',
			h5: '1.125rem',
			h4: '1.25rem',
			h3: '1.25rem',
			h2: '1.5rem',
			h1: '2rem',
			headline: '2.375rem',
		},
	},
	fontWeights: {
		light: 300,
		normal: 400,
		medium: 500,
		bold: 700,
		heading: 900,
	},
	lineHeights: {
		desktop: {
			caption: '1.125rem',
			body2: '1.5rem',
			body: '1.75rem',
			h5: '1.375rem',
			h4: '1.75rem',
			h3: '2rem',
			h2: '2.5rem',
			h1: '3.5rem',
			headline: '5.25rem',
		},
		mobile: {
			caption: '1.125rem',
			body2: '1.25rem',
			body: '1.375rem',
			h5: '1.375rem',
			h4: '1.75rem',
			h3: '1.75rem',
			h2: '2rem',
			h1: '2.625rem',
			headline: '2.875rem',
		},
	},
	letterSpacings: {
		1: '0.1px',
		2: '0.2px',
		3: '0.3px',
		4: '0.4px',
	},
};

export default typography;
