import Parser from 'html-react-parser';
import React from 'react';
import {
	headline,
	h1,
	h2,
	h3,
	h4,
	mainBody,
	mainBody2,
	orderedList,
	unorderedList,
} from 'shared/components';
import styled from 'styled-components';
import { tinyToReact } from './RteDomHelper';

const RteContents = styled.div`
	max-width: 42em;

	h1 {
		${({ headLine, forceh2 }) => (headLine ? headline : forceh2 ? h2 : h1)};
	}

	h2 {
		${h2};
	}

	h3 {
		${h3};
	}

	h4 {
		${h4};
	}

	p {
		${mainBody2};
	}

	a {
		text-decoration: underline;
		transition: 0.15s ease all;
		&:hover,
		&:focus {
			color: ${({ theme }) => theme.colors.secondary};
			text-decoration: none;
		}
	}

	blockquote {
		${mainBody};
	}

	ul {
		${unorderedList}
	}

	ol {
		${orderedList}
	}

	b,
	strong {
		font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	}

	i,
	em {
		font-style: italic;
	}

	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + blockquote {
		margin-top: ${({ theme }) => theme.spacings[6]};
	}

	* + p,
	* + li,
	* + ul,
	* + ol,
	h3 + ul,
	h3 + ol,
	h4 + ul,
	h4 + ol {
		margin-top: ${({ theme }) => theme.spacings[2]};
	}

	.highlight {
		color: ${({ theme }) => theme.colors.highlight};
	}
	h1,
	h2,
	h3,
	h4,
	p {
		color: ${({ color }) => color};
	}
`;

export const RteContent = (props) => {
	const { text, forceTabIndex: tabIndex, linkButtonStyle } = props;
	return (
		<RteContents {...props}>
			{Parser(text, tinyToReact(tabIndex, linkButtonStyle))}
		</RteContents>
	);
};
