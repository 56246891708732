import styled, { css } from 'styled-components';
import { mainBody2 } from 'shared/components';

export const CampaignLink = styled.a`
	text-decoration: underline;
`;

export const CampaignInput = styled.input`
	height: 40px;
	background: transparent;
	${mainBody2};
	margin: 0;
	color: ${({ $textColorFocus, $theme }) =>
		$textColorFocus || $theme.color[1]};
	transition: all 0.3s ease;
	transition-property: border, box-shadow;
	border: 0;
	outline: 0;
	border-bottom: ${({ $borderColor, $theme }) =>
			$borderColor || $theme.color[1]}
		solid 1px;
	border-radius: 0;
	padding: 5px 5px 5px 0;
	width: 100%;

	&:disabled {
		color: ${({ $placeholderTextColor, $theme }) =>
			$placeholderTextColor || $theme.color[2]};
		border-bottom: ${({ $borderColor, $theme }) =>
				$borderColor || $theme.color[2]}
			solid 1px;
		cursor: not-allowed;
	}

	&:focus {
		${({ noFocus }) =>
			!noFocus
				? css`
						outline: none;
						border: none;
						border-bottom: ${({ $borderColorFocus, $theme }) =>
								$borderColorFocus || $theme.color[2]}
							solid 1px;
					`
				: css`
						outline: none;
					`}
	}

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		margin: 0;
		color: ${({ $placeholderTextColor, $theme }) =>
			$placeholderTextColor || $theme.color[1]};
	}

	~ i {
		position: absolute;
	}
`;

export const ButtonAndLinkContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr auto;
	column-gap: 1rem;
	align-items: end;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		grid-template-columns: 1fr;
	}
`;
