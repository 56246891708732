import React, { useContext } from 'react';
import { H2, RteContent } from 'shared/components';
import { Spacer } from 'shared/layout';
import { ThemeContext } from '../../themes/campaignThemes';
import { FullWidthColoredBackground } from '../../pages/CampaignPage/CampaignPage.styles';
import {
	MainContainer,
	PdfImage,
	PdfTextArea,
	PdfLink,
} from './PdfBlock.styles';

const max400Characters = function (markupString) {
	if (markupString.length <= 407) {
		return markupString;
	}

	const openTag = markupString.slice(0, 3);
	const closeTag = markupString.slice(-4);
	const textContent = markupString.slice(3, markupString.length - 4);

	if (textContent.length <= 400) {
		return openTag + textContent + closeTag;
	} else {
		const trimmedTextContent = textContent.slice(0, 399);
		return openTag + trimmedTextContent + '…' + closeTag;
	}
};

const CampaignPdfBlock = ({
	Heading = '',
	MainContent = '',
	Image = {},
	PdfHeading = '',
	Pdf = {},
}) => {
	const theme = useContext(ThemeContext);
	return (
		<FullWidthColoredBackground $color={theme.color[1]}>
			<MainContainer>
				{!!Image && <PdfImage src={Image.Url} alt="PDF omslag" />}
				<PdfTextArea>
					{!!Heading && (
						<>
							<H2 color={theme.color[3]}>{Heading}</H2>
							<Spacer spacing={{ base: 6.5, md: 8 }} />
						</>
					)}
					{!!MainContent && (
						<>
							<RteContent
								text={max400Characters(MainContent)}
								color={theme.color[3]}
							/>
							<Spacer spacing={{ base: 6.5, md: 8 }} />
						</>
					)}
					<PdfLink
						$color={theme.color[3]}
						style={{
							color: theme.color[3],
						}}
						href={Pdf?.Url}
						target={Pdf?.Url}
					>
						{!!PdfHeading && PdfHeading}
					</PdfLink>
				</PdfTextArea>
			</MainContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignPdfBlock;
