import { useState } from 'react';

const useBlogItem = () => {
	const [selectedIds, setSelectedIds] = useState([]);
	const [expandedIds, setExpandedIds] = useState([]);

	const handleExpand = (Heading = '') => {
		if (expandedIds.includes(Heading))
			return setExpandedIds(expandedIds.filter((x) => x != Heading));
		setExpandedIds([...expandedIds, Heading]);
	};

	const handleSelect = (id = '') => {
		if (selectedIds.includes(id))
			return setSelectedIds(selectedIds.filter((x) => x != id));
		setSelectedIds([...selectedIds, id]);
	};

	return {
		selectedIds,
		expandedIds,
		handleSelect,
		handleExpand,
	};
};

export default useBlogItem;
