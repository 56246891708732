import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Box } from 'shared/layout/Box';
import PropTypes from 'prop-types';

const ImageContainer = styled(Box)`
	> div {
		&:first-child {
		}
		position: relative !important;
		height: 100%;
		width: 100%;
	}
	position: absolute;
	width: 100%;
	height: 100%;
`;

const BackgroundImage = styled(Image)`
	object-fit: cover;
	width: 100% !important;
	position: absolute !important;
	height: unset !important;
	transform: translate(-50%, -50%) !important;
	left: 50% !important;
	top: 100% !important;
`;

const FillerImage = ({ src, objectFit, priority, ...props }) => {
	return (
		<ImageContainer {...props}>
			<BackgroundImage
				blurDataURL={`${src}?width=10`}
				placeholder="blur"
				layout="fill"
				priority={priority}
				{...{ src, objectFit }}
			/>
		</ImageContainer>
	);
};
export default FillerImage;
FillerImage.propTypes = {
	src: PropTypes.string,
	objectFit: PropTypes.oneOf(['contain', 'cover']),
};
