import { css } from 'styled-components';

const _icons = css`
	.icon {
		display: inline-block;
		max-width: 100%;
		pointer-events: none;

		&--block {
			display: block;
		}
	}

	/* Setting default sizes for all icons in the icon sheet. */
	.icon-arrow,
	.icon-disabled,
	.icon-checkmark,
	.icon-arrow-right,
	.icon-arrow-left,
	.icon-facebook,
	.icon-instagram,
	.icon-youtube,
	.icon-x,
	.icon-linkedIn,
	.icon-link,
	.icon-plus,
	.icon-minus,
	.icon-phone,
	.icon-mail,
	.icon-arrow-up,
	.icon-arrow-down,
	.icon-link,
	.icon-external-link,
	.icon-notification,
	.icon-help,
	.icon-cancel,
	.icon-close,
	.icon-document,
	.icon-file,
	.icon-clock,
	.icon-download,
	.icon-search,
	.icon-special-arrow {
		width: 1em;
		height: 1em;
	}
	.icon-loader {
		width: 110px;
	}

	.icon-logo {
		width: 51px;
		height: 31px;

		@media (min-width: 992px) {
			width: 75px;
			height: 45px;
		}
		&.footer-logo {
			width: 127px;
			height: 77px;
			margin: ${({ theme }) => `0 ${theme.spacings[4]}`};
		}
	}
`;

export default _icons;
