import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Button, Hamburger, Line } from './HamburgerButton.styles';
import { MainBody } from 'shared/components';
import { Spacer } from 'shared/layout';
const HamburgerButton = ({
	title = '',
	closeTitle = '',
	theme = {},
	isOpen = false,
	handleMenuClick = () => {},
	...rest
}) => {
	return (
		<Button
			id="hamburgerMenuButton"
			aria-expanded={isOpen ? 'true' : 'false'}
			{...rest}
			onClick={handleMenuClick}
			aria-controls="navigation"
		>
			<Hamburger {...{ isOpen }}>
				<Line /> <Line /> <Line />
			</Hamburger>
			<Spacer spacing={1} />
			<MainBody
				font-size={theme.typography.fontSizes.desktop.caption}
				color={{
					base: theme.colors.white,

					sm: theme.colors.interaction,
				}}
			>
				{isOpen ? closeTitle : title}
			</MainBody>
		</Button>
	);
};

export default withTheme(HamburgerButton);
HamburgerButton.propTypes = {
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	handleMenuClick: PropTypes.func,
};
