import React from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Box, Spacer } from 'shared/layout';

import { Caption, H5, MainBody2, Checkbox } from 'shared/components';

const BlogItem = ({
	Heading = '',
	Id = '',
	Preamble = '',
	handleSelect = () => {},
	handleExpand = () => {},
	theme = {},
	selectedIds = [],
	expandedIds = [],
	first = false,
}) => {
	return (
		<Box
			display="flex"
			padding-top={
				first
					? theme.spacings[5]
					: { base: theme.spacings[6], md: theme.spacings[8] }
			}
		>
			<Checkbox
				name={Id}
				isChecked={selectedIds.includes(Id)}
				onChange={() => handleSelect(Id)}
			/>
			<Box margin-left={{ md: theme.spacings.default }}>
				<Box
					display="flex"
					flex-direction={{ base: 'column', md: 'row' }}
					gap={{ base: theme.spacings[2], md: theme.spacings[6] }}
				>
					<H5>{Heading}</H5>
					{!!Preamble && (
						<Caption
							as="button"
							onClick={() => handleExpand(Heading)}
							color={theme.colors.primaryLight}
							width="fit-content"
							aria-controls={Id}
						>
							Läs mer
						</Caption>
					)}
				</Box>
				<AnimateHeight
					height={expandedIds.includes(Heading) ? 'auto' : 0}
					duration={300}
					id={Id.toString()}
				>
					<Spacer>
						<MainBody2
							margin-bottom={theme.spacings.default}
							color={theme.colors.primary}
						>
							{Preamble}
						</MainBody2>
					</Spacer>
				</AnimateHeight>
			</Box>
		</Box>
	);
};

BlogItem.propTypes = {
	Heading: PropTypes.string,
	Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	Preamble: PropTypes.string,
	handleSelect: PropTypes.func,
	handleExpand: PropTypes.func,
	theme: PropTypes.object,
	selectedIds: PropTypes.array,
	expandedIds: PropTypes.array,
};

export default withTheme(BlogItem);
