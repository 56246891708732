import { useState, useLayoutEffect, useEffect } from 'react';

const getMatches = (query) => {
	// Prevents SSR issues
	if (typeof window !== 'undefined') {
		return window.matchMedia(query).matches;
	}
	return false;
};

/**
 * Returns true if the given media query matches.
 * Returns undefined until the first render occured to prevent hydration errors.
 * @param query A media query as string
 * @returns
 */
export function useMediaQuery(query) {
	const [hasMatch, setHasMatches] = useState(getMatches(query));
	const [initialLoad, setInitialLoad] = useState(true);

	const useIsomorphicLayoutEffect =
		typeof window !== 'undefined' ? useLayoutEffect : useEffect;

	useIsomorphicLayoutEffect(() => {
		if (initialLoad) {
			setInitialLoad(false);
		}
	}, []);

	function handleChange() {
		setHasMatches(getMatches(query));
	}

	useEffect(() => {
		const matchMedia = window.matchMedia(query);
		handleChange();
		matchMedia.addEventListener('change', handleChange);
		return () => {
			matchMedia.removeEventListener('change', handleChange);
		};
	}, [query]);

	return initialLoad ? undefined : hasMatch;
}
