import styled from 'styled-components';
import breakpoints from '../../core/breakpoints';

export const Line = styled.div`
	position: absolute;
	height: 4px;
	background-color: ${({ $theme }) => $theme.color[3]};
	width: 500dvw;
	left: -100dvw;
	z-index: -1;
`;

export const YearButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.625rem;
	height: 3.75rem;
	position: relative;
	background-color: ${({ active, $theme }) =>
		active ? $theme.color[4] : $theme.color[3]};
	border-radius: 5rem;
	border: 4px solid ${({ $theme }) => $theme.color[3]};
	cursor: pointer;
	transition:
		background-color 0.3s ease,
		border-color 0.3s ease,
		height 0.3s ease,
		width 0.3s ease;
	text-wrap: nowrap;
	flex-shrink: 0;

	@media (min-width: ${breakpoints.sm}) {
		width: 10rem;
		height: 6.25rem;
	}

	&:hover {
		background-color: ${({ $theme }) => $theme.color[4]};
	}

	&::after {
		content: '';
		height: 50px;
		width: 4px;
		transition: transform 0.3s ease;
		background-color: ${({ $theme }) => $theme.color[3]};
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: -1;

		@media (min-width: ${breakpoints.sm}) {
			height: 80px;
		}
	}

	&::before {
		content: '';
		height: 20px;
		width: 20px;
		transition: transform 0.3s ease;
		background-color: ${({ $theme }) => $theme.color[3]};
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: -1;
		border-radius: 50%;
	}

	${({ active }) =>
		active &&
		`
    width: 8.75rem;
    height: 6.25rem;
    border-radius: 5.5rem;
    margin-right: 2rem;

		&:hover {
		cursor: default; 
		}

		@media (min-width: ${breakpoints.sm}) {
			width: 15rem;
			height: 11rem;
		}
    
    &::after {
			transform: translate(-50%, -40px); 

			@media (min-width: ${breakpoints.sm}) {
				transform: translate(-50%, -80px); 
			}
    }

		&::before {
			transform: translate(-50%, -58px); 

			@media (min-width: ${breakpoints.sm}) {
				transform: translate(-50%, -80px); 
			}
    }

  `}

	${({ direction }) =>
		direction === 'right' &&
		`
    transform: rotate(180deg);
  `}
`;

const rgbToHex = (rgb) => {
	const [r, g, b] = rgb.match(/\d+/g);
	const hexR = (+r).toString(16).padStart(2, '0');
	const hexG = (+g).toString(16).padStart(2, '0');
	const hexB = (+b).toString(16).padStart(2, '0');
	return `${hexR}${hexG}${hexB}`;
};

export const ArrowButton = styled.button`
	padding: 0;
	width: 1.75rem;
	height: 1.75rem;
	background-image: ${({ $theme }) =>
		`url('data:image/svg+xml,<svg width="6" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 1 1 5l4 4" stroke="%23${rgbToHex(
			$theme.color[1],
		)}" stroke-linecap="round" stroke-linejoin="round"/></svg>')`};

	background-repeat: no-repeat;
	background-position: center;
	background-color: ${({ $theme }) => $theme.color[3]};
	border: none;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	transition:
		transform 0.3s ease,
		opacity 0.3s ease;

	${({ $direction }) =>
		$direction === 'right' &&
		`
    transform: rotate(180deg);
  `}

	@media (min-width: ${breakpoints.sm}) {
		width: 2rem;
		height: 2rem;
	}
`;

export const ButtonsContainer = styled.div`
	position: relative;
	display: flex;
	column-gap: 1.25rem;
	align-items: center;
	transition: left 0.3s ease;
	height: 176px;
	z-index: 2;
	margin-top: 0.5rem;
	grid-area: years;

	@media (min-width: ${breakpoints.sm}) {
		margin-top: 8rem;
		column-gap: 1.5rem;
	}
`;

export const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		'text text'
		'years years'
		'. arrows';
	grid-gap: 1rem;

	@media (min-width: ${breakpoints.sm}) {
		grid-template-columns: 1fr auto;
		grid-template-areas:
			'text arrows'
			'years years';
	}
`;

export const ArrowButtonsContainer = styled.div`
	display: flex;
	position: relative;
	column-gap: 1rem;
	color: ${({ $theme }) => $theme.color[3]};
	grid-area: arrows;

	@media (min-width: ${breakpoints.sm}) {
		top: 14.25rem;
	}
`;
