import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import breakpoints from '../../core/breakpoints';
import { ButtonStyle } from '../../components/Button/Button.styles';
import { themes } from '../../themes/campaignThemes';

export const CampaignHeader = withResponsive(styled.a`
	padding-top: ${({ theme }) => theme.spacings[8]};
	position: absolute;
	top: 0;
	z-index: ${({ theme }) => theme.zIndexes.aboveBase};
`);

// Common component styles

export const FullWidthColoredBackground = styled.div`
	position: relative;
	width: 100%;
	background-color: ${({ $color }) => $color};
	overflow-x: hidden;
`;

export const CampaignBlockContainer = styled.div`
	position: relative;
	width: calc(100% - 2rem);
	max-width: ${({ $theme, $extraWide }) =>
		$extraWide ? $theme.size.siteWidth : '58rem'};
	margin: 0 auto;
	padding: ${({ $theme }) =>
		$theme ? `${$theme.size.small} 0` : `${themes.commonSizes.small} 0`};

	@media (min-width: ${breakpoints.sm}) {
		padding: ${({ $theme }) =>
			$theme
				? `${$theme.size.medium} 0`
				: `${themes.commonSizes.medium} 0`};
	}

	@media (min-width: ${breakpoints.md}) {
		padding: ${({ $theme }) =>
			$theme
				? `${$theme.size.large} 0`
				: `${themes.commonSizes.large} 0`};
	}

	& p {
		margin-bottom: ${({ $theme }) =>
			$theme ? `${$theme.size.small}` : `${themes.commonSizes.small}`};
	}
`;

export const PrefixHeader = styled.div`
	font-family: 'WorkSans', sans-serif;
	font-size: 1.125rem;
	letter-spacing: 0.00625rem;
	font-weight: 500;
	color: ${({ $color, $theme }) => $color || $theme.color[3]};
	text-transform: ${({ $noUppercase }) =>
		$noUppercase ? 'none' : 'uppercase'};

	@media (min-width: ${breakpoints.md}) {
		font-size: 1.75rem;
	}
`;

export const PrefixText = styled(PrefixHeader)`
	font-size: 1rem;

	@media (min-width: ${breakpoints.md}) {
		font-size: 1.5rem;
	}
`;

export const TextWidthLimiter = styled.div`
	max-width: ${({ alt2, alt3 }) =>
		alt3 ? '31rem' : alt2 ? '37rem' : '45rem'};
`;

export const BaseLinkButton = styled.a`
	${ButtonStyle}
`;

export const CampaignButton = styled(BaseLinkButton)`
	background-color: ${({ $theme, $altVersion, $backgroundColor }) =>
		$backgroundColor
			? $backgroundColor
			: $altVersion
				? $theme.color[3]
				: $theme.color[1]};
	border: ${({ $theme, $altVersion, $backgroundColor }) =>
			$backgroundColor
				? $backgroundColor
				: $altVersion
					? $theme.color[3]
					: $theme.color[1]}
		solid 4px;
	color: ${({ $theme, $altVersion, $textColor }) =>
		$textColor
			? $textColor
			: $altVersion
				? $theme.color[1]
				: $theme.color[3]};
	transition: ease background-color 0.25s;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: none;
	}

	&:hover,
	&:active {
		background-color: transparent;
		color: ${({ $theme, $altVersion, $textColor, $backgroundColor }) =>
			$textColor
				? $backgroundColor
				: $altVersion
					? $theme.color[3]
					: $theme.color[1]} !important;
		cursor: pointer;
	}

	.is-tabbed &:focus {
		background-color: ${({ $theme, $backgroundColor }) =>
			$backgroundColor ? $backgroundColor : $theme.color[1]};
		color: ${({ $theme, $altVersion, $textColor }) =>
			$textColor
				? $textColor
				: $altVersion
					? $theme.color[1]
					: $theme.color[3]};
		box-shadow: none;
	}
`;

const screenReaderOnlyStyles = css`
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
`;

export const ScreenReaderOnly = styled.div`
	${({ $inactive }) => !$inactive && screenReaderOnlyStyles}
	${({ $inactive }) => $inactive && 'display: none'}
`;
