import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { h5 } from 'shared/components';

export const CookieWrapper = withResponsive(styled.div`
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.body};
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.body2};
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
			color: ${({ theme }) => theme.colors.secondary};
			transition: ${({ theme }) => theme.transitions.default};
		}
	}

	#CookieDeclarationChangeConsent {
		margin-top: ${({ theme }) => theme.spacings[8]};
	}

	th {
		${h5};
	}
`);
