import React from 'react';
import { withTheme } from 'styled-components';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { H1, MainBody, BaseLink, H2 } from 'shared/components';
import { MaxWidthContainer, Spacer } from 'shared/layout';

const Subject = ({
	Heading = '',
	Preamble = '',
	Link = {
		Url: '',
		Name: '',
	},
	LinkButtonText = '',
	theme = {},
}) => {
	return (
		<>
			<Head>
				<title>{'404'}</title>
				<meta name="robots" content="noindex,nofollow" />
			</Head>
			<MaxWidthContainer narrow>
				<Spacer spacing={8}>
					<H1>{Heading}</H1>
				</Spacer>
				<Spacer spacing={8}>
					<MainBody color={theme.colors.primary}>{Preamble}</MainBody>
				</Spacer>
				<Spacer spacing={8}>
					<BaseLink href={Link?.Url}>
						<H2>{LinkButtonText ?? Link?.Name}</H2>
					</BaseLink>
				</Spacer>
			</MaxWidthContainer>
		</>
	);
};
export default withTheme(Subject);
Subject.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	Link: PropTypes.shape({
		Url: PropTypes.string,
		Name: PropTypes.string,
	}),
	LinkButtonText: PropTypes.string,
	theme: PropTypes.object,
};
