import React from 'react';
import { ImageComponent, BaseLink, Icon, Video } from 'shared/components';

import { MaxWidthContainer } from 'shared/layout';

const fileTypes = ['.pdf', '.doc', '.xlsx'];

const renderImage = (domNode = {}) => {
	const { alt, height, src, width } = domNode.attribs;
	return (
		<ImageComponent
			src={`${process.env.NEXT_PUBLIC_UMBRACO_MEDIA_URL}${src}`}
			alt={alt}
			height={height}
			width={width}
		/>
	);
};

const DocumentLink = ({ text, title, href, tabIndex }) => {
	return (
		<BaseLink
			isDocument
			display="flex"
			href={href}
			title={title}
			tabIndex={tabIndex ? '0' : '-1'}
			width="fit-content"
			rel="noopener noreferrer"
			target="_blank"
		>
			<Icon height="3rem" width="3rem" name="document" />
			<span>{text}</span>
		</BaseLink>
	);
};

const isInternalLink = (str) => {
	return str && str.charAt(0) == '/';
};

const isMediaLink = (str) => {
	return str && str.includes('/media/');
};

const renderLink = (domNode = {}, tabIndex = true) => {
	const { href, title } = domNode.attribs;
	const text = domNode?.children[0]?.data;
	const isDocument = fileTypes.some((type) => href?.includes(type)); // i think this is case sensetive

	//check if image is wrapped in a link, add correct media-url to link
	if (domNode?.children[0]?.name === 'img') {
		const wrapHref = isMediaLink(href)
			? `${process.env.NEXT_PUBLIC_UMBRACO_MEDIA_URL}${href}`
			: href;
		return (
			<a href={wrapHref} title={title}>
				{tinyToReact().replace(domNode.children[0])}
			</a>
		);
	}

	if (isInternalLink(href)) {
		if (isDocument) {
			return <DocumentLink {...{ tabIndex, href, text, title }} />;
		}

		return (
			<BaseLink
				display="inline-flex"
				href={href}
				title={title}
				tabIndex={tabIndex ? '0' : '-1'}
				width="fit-content"
			>
				<span>{text}</span>
			</BaseLink>
		);
	}

	if (!tabIndex) domNode.attribs.tabIndex = '-1';
	return domNode;
};

const renderVideo = (domNode = {}) => {
	return (
		<MaxWidthContainer noGutters={false}>
			<Video {...domNode}></Video>
		</MaxWidthContainer>
	);
};

export const tinyToReact = (tabIndex = true) => {
	return {
		replace: (domNode) => {
			switch (domNode.name) {
				case 'img':
					return renderImage(domNode);
				case 'a':
					return renderLink(domNode, tabIndex);
				case 'video':
					// This won't work until RTE stops delivering the node as "text".
					return renderVideo(domNode);
				default:
					return domNode;
			}
		},
	};
};
