import React from 'react';
import { withTheme } from 'styled-components';
import { Primary, Secondary, Ghost, Text, Campaign } from './LinkButton.styles';
import PropTypes from 'prop-types';
import Link from 'next/link';

const LinkButton = ({
	variant = 'primary',
	href = '/',
	children,
	target = '',
	...props
}) => {
	const components = {
		SECONDARY: Secondary,
		GHOST: Ghost,
		TEXT: Text,
		CAMPAIGN: Campaign,
		PRIMARY: Primary,
	};

	const Component = components[variant] || Primary;

	return (
		<Link href={href} passHref>
			<Component target={target} {...props}>
				{children}
			</Component>
		</Link>
	);
};

export default withTheme(LinkButton);

LinkButton.propTypes = {
	variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'text']),
};
