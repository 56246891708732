import React from 'react';
import { withTheme } from 'styled-components';
import { H2, RteContent } from 'shared/components';

const max400Characters = function (markupString) {
	if (markupString.length <= 407) {
		return markupString;
	}

	const openTag = markupString.slice(0, 3);
	const closeTag = markupString.slice(-4);
	const textContent = markupString.slice(3, markupString.length - 4);

	if (textContent.length <= 400) {
		return openTag + textContent + closeTag;
	} else {
		const trimmedTextContent = textContent.slice(0, 399);
		return openTag + trimmedTextContent + '…' + closeTag;
	}
};
const DesktopPdfBlock = ({
	theme = {},
	Heading = '',
	MainContent = '',
	Image = {},
	PdfHeading = '',
	Pdf = {},
}) => {
	return (
		<div className="pdf-block">
			<div className="main-container">
				{!!Image && (
					<img
						src={Image.Url}
						alt="PDF omslag"
						className="pdf-block__img"
					/>
				)}

				<div className="pdf-block__text-area">
					{!!Heading && (
						<H2 color={theme.colors.primary}>{Heading}</H2>
					)}

					{!!MainContent && (
						<RteContent
							text={max400Characters(MainContent)}
							color={theme.colors.black}
						/>
					)}
					<a
						href={Pdf?.Url}
						target={Pdf?.Url}
						className="pdf-block__link"
					>
						{!!PdfHeading && PdfHeading}
					</a>
				</div>
			</div>
		</div>
	);
};
export default withTheme(DesktopPdfBlock);
