import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { ButtonStyle } from '../Button/Button.styles';
export const StyledFilterTextButton = withResponsive(styled.button.attrs(
	({ isPressed }) => ({
		'aria-pressed': isPressed ? 'true' : 'false',
	}),
)`
	${ButtonStyle}
	background-color: ${({ theme, isPressed }) =>
		isPressed ? theme.colors.primary : theme.colors.white};
	color: ${({ theme, isPressed }) =>
		isPressed ? theme.colors.white : theme.colors.primary};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.sm} ${theme.colors.primary}`};
	height: ${({ theme }) => theme.spacings[12]};
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		height: ${({ theme }) => theme.spacings[10]};
	}
	padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};

	&:hover,
	&:active {
		&&& {
			background-color: ${({ theme }) => theme.colors.primary};
			color: ${({ theme }) => theme.colors.white};
		}
	}
`);
