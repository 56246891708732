import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import {
	MenuWrapper,
	MenuContainer,
	MenuFill,
	TopMenuColorFill,
	MenuLanguageLinkText,
} from './HamburgerMenu.styles';
import { MaxWidthContainer, Flex, Box } from 'shared/layout';
import { default as HamburgerMenuItem } from './HamburgerMenuItem';
import FocusLock from 'react-focus-lock';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import LanguageLink from './LanguageLink';

const HamburgerMenu = ({
	isOpen = false,
	links = [],
	handleMenuClick = () => {},
	theme = {},
	EnglishSiteLabel = '',
	EnglishSiteUrl = '',
}) => {
	const isLargeUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.lg})`,
		true,
	);

	const menuRef = useRef(null);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
			if (isLargeUp) {
				document.body.style.paddingRight = '17px';
			}

			const handleClickOutside = (event) => {
				const buttonElement = document.getElementById(
					'hamburgerMenuButton',
				);
				if (
					menuRef.current &&
					!menuRef.current.contains(event.target) &&
					buttonElement &&
					!buttonElement.contains(event.target)
				) {
					handleMenuClick();
				}
			};

			document.addEventListener('mousedown', handleClickOutside);

			return () => {
				document.body.style.overflow = 'unset';
				document.body.style.paddingRight = '0px';
				document.removeEventListener('mousedown', handleClickOutside);
			};
		} else {
			document.body.style.overflow = 'unset';
			document.body.style.paddingRight = '0px';
		}
	}, [isOpen, isLargeUp, handleMenuClick]);

	return (
		<>
			<MenuWrapper show={isOpen} color={theme.colors.white}>
				<MaxWidthContainer noGutters>
					<Flex justify-content="flex-end">
						<MenuContainer
							ref={menuRef}
							show={isOpen}
							aria-hidden={isOpen ? 'false' : 'true'}
							id="navigation"
						>
							<FocusLock group="hamburgerMenu">
								<Box position="relative">
									<TopMenuColorFill />
									{!!links?.length &&
										links.map((item, ix) => {
											return (
												<HamburgerMenuItem
													items={item}
													key={ix}
													handleMenuClick={
														handleMenuClick
													}
												/>
											);
										})}
									{EnglishSiteUrl &&
										!!EnglishSiteUrl.length && (
											<MenuLanguageLinkText child>
												<LanguageLink
													href={EnglishSiteUrl}
												>
													{EnglishSiteLabel}
												</LanguageLink>
											</MenuLanguageLinkText>
										)}

									<MenuFill show={isOpen} />
								</Box>
							</FocusLock>
						</MenuContainer>
					</Flex>
				</MaxWidthContainer>
			</MenuWrapper>
		</>
	);
};

HamburgerMenu.propTypes = {
	isOpen: PropTypes.bool,
	links: PropTypes.array,
	EnglishSiteUrl: PropTypes.string,
	EnglishSiteLabel: PropTypes.string,
};

export default withTheme(HamburgerMenu);
