import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { Spacer } from 'shared/layout';
import axios from 'axios';
import { H2, MainBody } from 'shared/components';
import {
	FullWidthColoredBackground,
	CampaignBlockContainer,
	CampaignButton,
} from '../../pages/CampaignPage/CampaignPage.styles';
import Link from 'next/link';
import {
	CampaignInput,
	CampaignLink,
	ButtonAndLinkContainer,
} from './CampaignSubscriptionBlock.styles';

const CampaignSubscriptionBlock = ({
	Heading = '',
	ButtonText = '',
	GdprLink = {},
	ActivityKey = '',
	TopicId = '',
	Preamble = '',
	ThankYouMessage = '',
}) => {
	const theme = useContext(ThemeContext);
	const [email, setEmail] = useState('');
	const [isSubscribed, setIsSubscribed] = useState(false);
	const handleSubscribe = () => {
		if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
			alert('E-postadressen är inte giltig.');
			return;
		}

		if (ActivityKey != null) {
			const postingUrl = `https://trk.idrelay.com/2952/act/${ActivityKey}`;
			let requestData;
			if (TopicId) {
				requestData = `f1=${encodeURIComponent(
					email,
				)}&f6=${encodeURIComponent(TopicId)}`;
			} else {
				requestData = `f1=${encodeURIComponent(email)}`;
			}
			const axHeader = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			};

			axios
				.post(postingUrl, requestData, axHeader)
				.then(() => {
					setEmail('');
					setIsSubscribed(true);
				})
				.catch((error) => {
					console.error(`Error making API request: ${error}`);
					alert('Något gick fel. Försök igen senare.');
				});
		}
	};

	return (
		<FullWidthColoredBackground $color={theme.color[3]}>
			<CampaignBlockContainer $theme={theme}>
				{!isSubscribed ? (
					<>
						{!!Heading && (
							<H2
								word-break={{ base: 'break-word', md: 'unset' }}
								color={theme.color[1]}
							>
								{Heading}
							</H2>
						)}

						{!!Preamble && (
							<>
								<Spacer spacing={{ base: 2, md: 4 }}></Spacer>
								<MainBody as="p" color={theme.color[1]}>
									{Preamble}
								</MainBody>
							</>
						)}

						<Spacer>
							<CampaignInput
								$theme={theme}
								type="email"
								value={email}
								placeholder={'Mejladress'}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</Spacer>

						{!!ButtonText && (
							<ButtonAndLinkContainer>
								<Spacer>
									<CampaignButton
										onClick={() => handleSubscribe()}
										$theme={theme}
										as="button"
									>
										{ButtonText}
									</CampaignButton>
								</Spacer>
								{!!GdprLink && (
									<Spacer
										style={{
											color: theme.color[1],
											fontSize: '18px',
										}}
									>
										Vi behandlar dina kontaktuppgifter med
										stöd av ett berättigat intresse.{' '}
										<Link href={GdprLink?.Url} passHref>
											<CampaignLink>
												Läs mer om vår hantering här.
											</CampaignLink>
										</Link>
									</Spacer>
								)}
							</ButtonAndLinkContainer>
						)}
					</>
				) : (
					<>
						<H2
							word-break={{ base: 'break-word', md: 'unset' }}
							color={theme.color[1]}
						>
							Tack!
						</H2>
						<Spacer spacing={{ base: 2, md: 4 }}></Spacer>
						<MainBody as="p" color={theme.color[1]}>
							{ThankYouMessage}
						</MainBody>
					</>
				)}
			</CampaignBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignSubscriptionBlock;
