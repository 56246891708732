import React from 'react';
import styled from 'styled-components';
import withResponsive from '../helpers/withResponsive';
import PropTypes from 'prop-types';

export const IconContainer = withResponsive(styled.i`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ color }) => (color ? color : 'inherit')};
	margin: ${({ margin }) => margin};

	width: ${({ size, width }) => (size ? size : width ? width : '')};
	height: ${({ size, height }) => (size ? size : height ? height : '')};
`);

const Icon = ({
	name = '',
	displayAs = 'inline-block',
	className = '',
	stroke,
	fill,
	...props
}) => {
	return (
		<IconContainer {...props}>
			<svg
				stroke={stroke}
				fill={fill}
				className={`${className} icon icon-${name} icon--${displayAs}`}
				focusable="false"
			>
				<use stroke={stroke} fill={fill} xlinkHref={`#icon-${name}`} />
			</svg>
		</IconContainer>
	);
};

Icon.propTypes = {
	name: PropTypes.oneOf([
		'search',
		'arrow-down',
		'arrow-up',
		'arrow-left',
		'arrow-right',
		'arrow',
		'disabled',
		'checkmark',
		'facebook',
		'linkedIn',
		'instagram',
		'x',
		'logo',
		'plus',
		'minus',
		'phone',
		'mail',
		'youtube',
		'link',
		'external-link',
		'warning',
		'notification',
		'check',
		'cancel',
		'download',
		'help',
		'clock',
		'file',
		'document',
		'loader',
		'language',
		'special-arrow',
	]),
	displayAs: PropTypes.string,
	className: PropTypes.string,
	margin: PropTypes.string,
	color: PropTypes.string,
};
export default withResponsive(Icon);
