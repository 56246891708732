import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const Button = withResponsive(styled.button`
	color: ${({ theme }) => theme.colors.interaction};
	display: flex;
	flex-direction: column;
	z-index: 1;
	align-items: center;
`);
