import styled from 'styled-components';

const FullWidthContainer = styled.div`
	width: 100%;
	padding: ${({ theme, withRightGutter = false }) =>
		withRightGutter
			? `0 ${theme.spacings.siteMarginMobile}`
			: `0 0 0 ${theme.spacings.siteMarginMobile}`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, withRightGutter = false }) =>
			withRightGutter
				? `0 ${theme.spacings.siteMarginTablet}`
				: `0 0 0 ${theme.spacings.siteMarginTablet}`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme, withRightGutter = false }) =>
			withRightGutter
				? `0 ${theme.spacings.siteMargin}`
				: `0 0 0 ${theme.spacings.siteMargin}`};
	}
`;

export default FullWidthContainer;
