import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const PdfBlock = withResponsive(styled.div`
	width: 100%;
	padding: 0 1rem;
`);

export const MainContainer = withResponsive(styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	width: 100%;
	max-width: 70rem;
	margin: 0px auto;

	@media (min-width: 48em) {
		grid-template-columns: auto 1fr;
		padding: 0 1rem;
	}
`);

export const PdfImage = withResponsive(styled.img`
	height: 420px;
	width: calc(420px / 1.414);
	object-fit: cover;
	margin: 2rem auto 0px auto;
	padding: 0;

	@media (min-width: 48em) {
		margin: 0;
	}
`);

export const PdfTextArea = withResponsive(styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem 2rem 3rem 2rem;

	@media (min-width: 48em) {
		padding: 1rem 0 1rem 5rem;
	}
`);

export const PdfLink = withResponsive(styled.a`
	display: flex;
	height: 44px;
	align-items: center;
	margin-right: auto;
	margin-top: 1rem;
	font-family: 'Brioni Sans Std', sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
	letter-spacing: 0.3px;
	&:hover {
		text-decoration: underline;
	}
	&::before {
		content: '';
		display: block;
		height: 2.5rem;
		width: 2.5rem;
		margin-right: 0.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml,<svg width="37" height="37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.583 3.083H9.25a3.083 3.083 0 0 0-3.083 3.084v24.666a3.083 3.083 0 0 0 3.083 3.084h18.5a3.083 3.083 0 0 0 3.083-3.084v-18.5l-9.25-9.25Z" stroke="${({
			$color,
		}) =>
			$color}" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.583 3.083v9.25h9.25M24.667 20.042H12.333M24.667 26.208H12.333M15.417 13.875h-3.084" stroke="${({
			$color,
		}) =>
			$color}" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/> strokeLinecap="round"strokeLinejoin="round"/> <path d="M15.417 13.875h-3.084" stroke="${({
			$color,
		}) => $color}"/></svg>');
	}
`);
