import React from 'react';
import PropTypes from 'prop-types';
import { Box, MaxWidthContainer } from 'shared/layout';
import { withTheme } from 'styled-components';
import { H3, RteContent } from 'shared/components';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
const BannerBlock = ({ theme = {}, Heading = '' }) => {
	const isSiteWidthUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.siteWidth})`,
	);
	return (
		<Box background={theme.colors.banner}>
			<MaxWidthContainer noGutters={isSiteWidthUp}>
				{!!Heading && (
					<H3 padding={`${theme.spacings[12]} 0`}>
						<RteContent text={Heading} />
					</H3>
				)}
			</MaxWidthContainer>
		</Box>
	);
};

BannerBlock.propTypes = {
	Heading: PropTypes.string,
	theme: PropTypes.object,
};

export default withTheme(BannerBlock);
