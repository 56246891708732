import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

const Column = styled.div.attrs(
	({ largeMargin, noGutters, theme, smallMargin }) => ({
		smallMargin: smallMargin
			? smallMargin
			: noGutters
				? undefined
				: theme.spacings.gutterMobile,
		largeMargin: largeMargin
			? largeMargin
			: noGutters
				? undefined
				: theme.spacings.gutter,
	}),
)`
	display: ${({ flex }) => (flex ? 'flex' : 'block')};
	padding-left: ${({ noGutters = false, smallMargin }) =>
		noGutters ? undefined : smallMargin};
	padding-right: ${({ noGutters = false, smallMargin }) =>
		noGutters ? undefined : smallMargin};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding-left: ${({ noGutters = false, largeMargin }) =>
			noGutters ? undefined : largeMargin};
		padding-right: ${({ noGutters = false, largeMargin }) =>
			noGutters ? undefined : largeMargin};
	}
`;

export default withResponsive(Column);
