import PropTypes from 'prop-types';
import React from 'react';
import { BasicListItem, Icon, UnorderedList } from 'shared/components';
import { withTheme } from 'styled-components';
import { DotsStyle, PageButton } from './Pagination.styles';
import { DOTS, usePagination } from './usePagination';

const Pagination = ({
	onPageChange,
	totalCount = 1,
	currentPage = 1,
	pageSize = 1,
	siblingCount = 1,
	theme,
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		pageSize,
		siblingCount,
	});

	// If there are less than 2 times in pagination range we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];
	return (
		<nav role="navigation" aria-label="Pagination Navigation">
			<UnorderedList
				display="flex"
				justify-content="center"
				flex-direction="row"
				gap={theme.spacings[2]}
			>
				<BasicListItem>
					<PageButton
						disabled={currentPage === 1}
						isNavButton
						onClick={onPrevious}
						aria-label="Gå till föregående sida"
						title="Gå till föregående sida"
					>
						<Icon name="arrow-left" />
					</PageButton>
				</BasicListItem>
				{paginationRange.map((pageNumber, ix) => {
					if (pageNumber === DOTS) {
						return <DotsStyle key={ix}>{DOTS}</DotsStyle>;
					}
					return (
						<BasicListItem key={ix}>
							<PageButton
								isActive={pageNumber === currentPage}
								onClick={() => onPageChange(pageNumber)}
								aria-label={`Gå till sida ${pageNumber}`}
								aria-current={pageNumber === currentPage}
							>
								{pageNumber}
							</PageButton>
						</BasicListItem>
					);
				})}
				{/*  Right Navigation arrow */}
				<BasicListItem>
					<PageButton
						disabled={currentPage === lastPage}
						isNavButton
						onClick={onNext}
						aria-label="Gå till nästa sida"
						title="Gå till nästa sida"
					>
						<Icon name="arrow-right" />
					</PageButton>
				</BasicListItem>
			</UnorderedList>
		</nav>
	);
};
Pagination.propTypes = {
	onPageChange: PropTypes.func,
	totalCount: PropTypes.number,
	currentPage: PropTypes.number,
	pageSize: PropTypes.number,
};

export default withTheme(Pagination);
