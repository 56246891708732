import styled, { css } from 'styled-components';
import withResponsive from '../../helpers/withResponsive';

/* Responsive and styled exports are at the bottom of the file, these are the pure css-styles */

export const headline = css`
	font-family: ${({ theme }) => theme.typography.fonts.heading};
	font-weight: ${({ theme }) => theme.typography.fontWeights.heading};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.headline};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.headline};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme, noColor }) => !noColor && theme.colors.primary};
	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) =>
			theme.typography.fontSizes.desktop.headline};
		line-height: ${({ theme }) =>
			theme.typography.lineHeights.desktop.headline};
	}
`;

export const h1 = css`
	font-family: ${({ theme }) => theme.typography.fonts.heading};
	font-weight: ${({ theme }) => theme.typography.fontWeights.heading};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.h1};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.h1};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme, noColor }) => !noColor && theme.colors.primary};
	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.h1};
		line-height: ${({ theme }) => theme.typography.lineHeights.desktop.h1};
	}
`;

export const h2 = css`
	font-family: ${({ theme }) => theme.typography.fonts.heading};
	font-weight: ${({ theme }) => theme.typography.fontWeights.heading};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.h2};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.h2};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme, noColor }) => !noColor && theme.colors.primary};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.h2};
		line-height: ${({ theme }) => theme.typography.lineHeights.desktop.h2};
	}
`;

export const h3 = css`
	font-family: ${({ theme }) => theme.typography.fonts.heading};
	font-weight: ${({ theme }) => theme.typography.fontWeights.heading};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.h3};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.h3};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme, noColor }) => !noColor && theme.colors.primary};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.h3};
		line-height: ${({ theme }) => theme.typography.lineHeights.desktop.h3};
	}
`;

export const h4 = css`
	font-family: ${({ theme }) => theme.typography.fonts.heading};
	font-weight: ${({ theme }) => theme.typography.fontWeights.heading};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.h4};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.h4};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme }) => theme.colors.primary};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.h4};
		line-height: ${({ theme }) => theme.typography.lineHeights.desktop.h4};
	}
`;

export const h5 = css`
	font-family: ${({ theme }) => theme.typography.fonts.text};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.h5};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.h5};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[1]};
	color: ${({ theme }) => theme.colors.primary};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.h5};
		line-height: ${({ theme }) => theme.typography.lineHeights.desktop.h5};
	}
`;

export const mainBody = css`
	font-family: ${({ theme }) => theme.typography.fonts.text};
	font-weight: ${({ theme }) => theme.typography.fontWeights.light};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.body};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.body};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[3]};
	color: ${({ theme }) => theme.colors.text};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.body};
		line-height: ${({ theme }) =>
			theme.typography.lineHeights.desktop.body};
	}
`;

export const mainBody2 = css`
	color: ${({ theme, color }) => (color ? color : theme.colors.text)};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[2]};
	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.body2};
		line-height: ${({ theme }) =>
			theme.typography.lineHeights.desktop.body2};
	}
`;

export const caption = css`
	text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
	font-family: ${({ theme }) => theme.typography.fonts.text};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.caption};
	line-height: ${({ theme }) => theme.typography.lineHeights.mobile.caption};
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings[4]};
	color: ${({ theme, color }) => (color ? color : theme.colors.primary)};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.desktop.caption};
		line-height: ${({ theme }) =>
			theme.typography.lineHeights.desktop.caption};
	}
`;

export const unorderedList = css`
	${mainBody2};
	li {
		list-style-type: disc;
		display: list-item;

		margin-left: ${({ theme }) => theme.spacings[5]};
		margin-top: ${({ theme }) => theme.spacings[2]};
		&::marker {
			color: ${({ theme, color }) =>
				color ? color : theme.colors.primary};
		}
	}
`;

export const orderedList = css`
	counter-reset: list-counter;
	${mainBody2};

	li {
		counter-increment: list-counter;
		margin-top: ${({ theme }) => theme.spacings[2]};
		margin-left: ${({ theme }) => theme.spacings[1]};
		&:before {
			content: counter(list-counter) '. ';
			${mainBody2};
			color: ${({ theme, color }) =>
				color ? color : theme.colors.primary};
		}
	}
`;

export const listItem = css`
	padding: ${({ theme, padding }) =>
		padding ? padding : `${theme.spacings[2]} 0`};

	counter-increment: none;
`;

export const basicListItem = css`
	&& {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
`;

export const Headline = withResponsive(styled.h1`
	${headline}
	&& {
		p {
			${headline}
		}
	}
`);

export const H1 = withResponsive(styled.h1`
	${h1}
	&& {
		p {
			${h1}
		}
	}
`);

export const H2 = withResponsive(styled.h2`
	${h2}
	&& {
		p {
			${h2}
		}
	}
`);

export const H3 = withResponsive(styled.h3`
	${h3}
	&& {
		p {
			${h3}
		}
	}
`);

export const H4 = withResponsive(styled.h4`
	${h4}
`);

export const H5 = withResponsive(styled.h5`
	${h5}
`);

export const MainBody = withResponsive(styled.p`
	${mainBody}
`);

export const MainBody2 = withResponsive(styled.p`
	${mainBody2}
`);

export const Caption = withResponsive(styled.p`
	${caption}
`);

export const UnorderedList = withResponsive(styled.ul`
	${unorderedList};
`);

export const OrderedList = withResponsive(styled.ol`
	${orderedList}
`);

export const ListItem = withResponsive(styled.li`
	${listItem}
`);
export const BasicListItem = withResponsive(styled.li`
	${basicListItem}
`);
