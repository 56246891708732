import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { StyledListLink } from './ListLink.styles';
const ListLink = ({ children, ...props }) => {
	return (
		<Link
			passHref
			{...(process.env.STORYBOOK === 'true' ? { prefetch: false } : {})}
			href={props.href}
		>
			<StyledListLink {...props}>{children}</StyledListLink>
		</Link>
	);
};

export default withTheme(ListLink);

ListLink.propTypes = {
	href: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
};
