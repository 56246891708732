const zIndexes = {
	hide: -1,
	auto: 'auto',
	base: 0,
	drag: 3,
	aboveBase: 5,
	docked: 10,
	menuItem: 1,
	dropdown: 1000,
	menu: 1050,
	sticky: 1100,
	fixed: 1150,
	banner: 1200,
	overlay: 1300,
	modal: 1400,
	popover: 1500,
	skipLink: 1600,
	toast: 1700,
	tooltip: 1800,
	progressBar: 1900,
};
export default zIndexes;
