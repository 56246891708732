import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Circle, StyledFilterButton } from './FilterButton.styles';

const FilterButton = ({ isExpanded = false, ...rest }) => {
	return (
		<StyledFilterButton {...{ isExpanded }} {...rest}>
			<Circle />
			<Circle />
			<Circle />
		</StyledFilterButton>
	);
};

export default withTheme(FilterButton);
FilterButton.propTypes = {
	isExpanded: PropTypes.bool,
};
