import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSearchParam } from 'react-use';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { getSearchResults } from 'api';
import {
	H1,
	MainBody,
	FilterSection,
	SearchResults,
	H2,
	Pagination,
	Caption,
	SearchBar,
	ResultSkeleton,
	RteContent,
} from 'shared/components';
import { Box, MaxWidthContainer, Spacer } from 'shared/layout';
import { SimpleButtons, SimpleButton } from './';
import { useRouter } from 'next/router';
import {
	mapCategories,
	dataToDropdown,
	fromDropdownData,
	CategoryListing,
	filterCategories,
	getFilterOrInitial,
} from './Search.helpers.js';
import { DropDown } from '../Dropdown/DropDown';
import { push } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const Search = ({
	Heading = '',
	Preamble = '',
	theme,
	Categories = [],
	ResetFiltersText = '',
	ResultHeading = '',
	ResultText = '',
	FilterHeading = '',
	DistinctCategoryTypes = [],
	PageSize = 10,
	NoHitsText = '',
	NoHitsHeading = '',
	isSearchPage = false,
	SortBy = '',
	IsEmptySearch = false,
}) => {
	DistinctCategoryTypes.forEach((category) => {
		if (
			category.Name === 'Huvudkategorier' ||
			category.Name === 'Kontaktkategorier 2'
		) {
			category.Name = 'Ämne';
		}
	});

	const isMediumUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.md})`,
		true,
	);
	const initCategories = Categories.filter((x) => x.Type.Id === '1');
	const initContacts = Categories.filter((x) => x.Type.Id === '2');
	const initDocumentTypes = Categories.filter((x) => x.Type.Id === '3');
	const initYears = Categories.filter((x) => x.Type.Id === '4');

	const firstCategory =
		initCategories?.length > 0
			? initCategories[0].Type
			: initContacts?.length > 0
				? initContacts[0].Type
				: initDocumentTypes?.length > 0
					? initDocumentTypes[0].Type
					: initYears?.length > 0
						? initYears[0].Type
						: [];

	const [filters, setFilters] = useState({
		categories: [],
		documentTypes: [],
		contacts: [],
		years: [],
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [userInput, setUserInput] = useState('');
	const [queryText, setQueryText] = useState('');
	const [currentCategories, setCurrentCategories] = useState([]);
	const [currentCategory, setCurrentCategory] = useState(firstCategory);
	const [isLoaded, setIsLoaded] = useState(false);
	const [anySelectedValue, setAnySelectedValue] = useState(false);
	const inputRef = useRef(undefined);
	const scrollRef = useRef(undefined);
	const router = useRouter();
	const hasFilters = Object.values(filters).some((d) => d?.length);
	const queryParams = useSearchParam('query');

	//TODO: AND filtreringen fungerar inte via url-parametrarna (Kontakter AND kategorier etc).
	// För att ?categories ersätts med en ny array (currentFilters) varje gång man hoppar mellan kategorier/kontakter osv
	const catParams = useSearchParam('categories');
	const { data, isLoading } = useQuery(
		['search', filters, queryText, currentPage, Heading],
		() =>
			getSearchResults({
				Query: queryText,
				Categories: getFilterOrInitial(
					filters.categories,
					initCategories,
				),
				DocumentTypes: getFilterOrInitial(
					filters.documentTypes,
					initDocumentTypes,
				),
				CurrentCulture: '',
				Contacts: getFilterOrInitial(filters.contacts, initContacts),
				Years: getFilterOrInitial(filters.years, initYears),
				Take: 10,
				Skip: (currentPage - 1) * 10,
				SortBy: SortBy.length ? SortBy : '',
				IsEmptySearch: IsEmptySearch && !hasFilters,
			}),
		{ refetchOnWindowFocus: false, enabled: isLoaded },
	);

	useEffect(() => {
		if (isSearchPage && !!inputRef.current) {
			inputRef.current.focus();
		}
		// set initial categories to query params
		if (queryParams) {
			setUserInput(queryParams);
			setQueryText(queryParams);
			setIsLoaded(true);
		}
		if (catParams) {
			var categories = JSON.parse(catParams);

			categories.forEach((element) => {
				addCategoryToFilter(element);
			});
		}
	}, []);

	useEffect(() => {
		const filters = filterCategories(Categories, currentCategory);
		setCurrentCategories(filters);
		if (!isLoaded) setIsLoaded(true);
	}, [currentCategory]);

	const clearFilters = () => {
		setFilters({
			categories: [],
			documentTypes: [],
			contacts: [],
			years: [],
		});
		setAnySelectedValue(false);
	};

	// Filter out any categories with undefined Name to prevent empty buttons.
	Categories = Categories.filter((category) => category.Name);

	// Mobile filtering (Select)
	const addSelectedToFilter = (selected, catType) => {
		const newFilters = { ...filters };
		newFilters[mapCategories(catType, DistinctCategoryTypes)] =
			selected.length ? fromDropdownData(selected) : [];
		setFilters(newFilters);
		setAnySelectedValue(!!selected?.length);
	};
	// Desktop filtering (Buttons)
	const addCategoryToFilter = (category) => {
		const newFilters = { ...filters };
		const currentFilters =
			newFilters[mapCategories(category.Type, DistinctCategoryTypes)];
		if (currentFilters.some((filter) => filter.Id === category.Id)) {
			currentFilters.splice(
				currentFilters.findIndex((item) => item.Id === category.Id),
				1,
			);
		} else currentFilters.push(category);
		setFilters(newFilters);
		router.push(
			{
				pathname: window.location.pathname,
				query: {
					categories: currentFilters.length
						? JSON.stringify(currentFilters)
						: '',
				},
			},
			undefined,
			{ shallow: true },
		);
		setCurrentPage(1);
	};

	const handleFilterButtonClick = (e, i) => {
		e.preventDefault();
		push({ event: 'filter', filter_category: currentCategory.Name });
		addCategoryToFilter(i);
	};
	const handleCategoryFilterClick = (category) => {
		setCurrentCategory(category);
	};

	const handleSearch = () => {
		setQueryText(userInput);
		if (isSearchPage) {
			push({
				event: 'search',
				search_type: 'search_page',
				search_term: userInput,
			});
		}
	};
	const handleInputChange = (e) => {
		setUserInput(e.target.value);
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
		let scrollToRef = inputRef.current
			? inputRef
			: scrollRef.current
				? scrollRef
				: undefined;
		if (scrollToRef?.current)
			scrollToRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
	};

	return (
		<>
			<MaxWidthContainer>
				<Spacer spacing={4}>
					<Box
						padding-right={{ base: 0, md: theme.spacings[20] }}
						background-color={theme.colors.backgroundGrey}
					>
						{!!Heading?.length && (
							<Spacer spacing={2}>
								<H1 as={!isSearchPage && H2}>{Heading}</H1>
							</Spacer>
						)}
						{!!Preamble?.length && (
							<Spacer spacing={{ base: 3.5, md: 6 }}>
								<RteContent text={Preamble} />
							</Spacer>
						)}
						{isSearchPage && (
							<Spacer spacing={7}>
								<SearchBar
									isSearchBar
									inputRef={inputRef}
									handleSearch={handleSearch}
									userInput={userInput}
									handleInputChange={handleInputChange}
								/>
							</Spacer>
						)}
					</Box>
				</Spacer>
			</MaxWidthContainer>
			<Spacer ref={scrollRef} spacing={{ base: 2, md: 12 }} />
			<Box
				background={theme.colors.backgroundGrey}
				padding-bottom={{
					base: theme.spacings[4],
					md: theme.spacings[15],
				}}
			>
				<MaxWidthContainer>
					<Spacer spacing={{ base: 2, md: 4 }}>
						{isMediumUp && (
							<>
								<Spacer>
									<SimpleButtons
										{...{
											ResetFiltersText,
											clearFilters,
											FilterHeading,
											handleCategoryFilterClick,
											currentCategory,
											Categories,
										}}
										font-weight={
											theme.typography.fontWeights.bold
										}
										color={theme.colors.primary}
										showButton={isMediumUp}
										categoryTypes={DistinctCategoryTypes}
									/>
								</Spacer>

								<Spacer spacing={4}>
									<FilterSection
										handleFilterClick={
											handleFilterButtonClick
										}
										clearFilters={clearFilters}
										Buttons={currentCategories}
										selectedFilters={
											filters[
												mapCategories(
													currentCategory,
													DistinctCategoryTypes,
												)
											]
										}
									/>
								</Spacer>
							</>
						)}
					</Spacer>
					{!isMediumUp && (
						<Spacer>
							<MainBody
								font-size="18px"
								color={theme.colors.primary}
								font-weight="bold"
							>
								{FilterHeading}
							</MainBody>
							{DistinctCategoryTypes.map((cat) => {
								const options = [
									...dataToDropdown(
										filterCategories(Categories, cat),
									),
								];
								return (
									<Spacer key={cat.Id}>
										<DropDown
											name={cat.Name}
											isMultiChoice
											identifier={cat.Id}
											placeholder={cat.Name}
											fullWidth
											onChange={(e) => {
												addSelectedToFilter(e, cat);
											}}
											options={options}
											anySelectedValue={anySelectedValue}
										/>
									</Spacer>
								);
							})}
						</Spacer>
					)}

					{!isMediumUp && !!ResetFiltersText?.length && (
						<Spacer spacing={4}>
							<SimpleButton
								display="flex"
								justify-content="flex-end"
								width="100%"
								onClick={clearFilters}
							>
								{ResetFiltersText}
							</SimpleButton>
						</Spacer>
					)}
				</MaxWidthContainer>
			</Box>

			<MaxWidthContainer>
				{!!ResultHeading?.length && (
					<Spacer spacing={10}>
						<H2>
							{ResultHeading}
							{isSearchPage &&
								!!data?.TotalHits &&
								` (${data?.TotalHits})`}
						</H2>
					</Spacer>
				)}
				{hasFilters && (
					<Spacer spacing={2}>
						<Caption position="absolute" textTransform="none">
							{!!ResultText?.length && ResultText}

							<CategoryListing {...filters} />
						</Caption>
					</Spacer>
				)}
				<Spacer spacing={8}>
					{isLoading ? (
						<ResultSkeleton
							itemSpacing={4}
							padding={{
								base: `${theme.spacings[8]} 0`,
								md: `${theme.spacings[9]} ${theme.spacings[12]}`,
							}}
							itemCount={4}
							rowCount={4}
						/>
					) : (
						<SearchResults
							NoHitsText={NoHitsText}
							NoHitsHeading={NoHitsHeading}
							SearchHits={data?.SearchHits}
						/>
					)}
				</Spacer>

				{data?.TotalHits > 10 && (
					<Spacer spacing={16}>
						<Pagination
							currentPage={currentPage}
							totalCount={data?.TotalHits}
							pageSize={PageSize}
							onPageChange={(page) => handlePageChange(page)}
						/>
					</Spacer>
				)}
				<Spacer spacing={8} />
			</MaxWidthContainer>
		</>
	);
};
export default withTheme(Search);

Search.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	Categories: PropTypes.array,
	SearchHits: PropTypes.array,
	theme: PropTypes.object,
	ResetFiltersText: PropTypes.string,
	HitTypeText: PropTypes.string,
	ResultText: PropTypes.string,
	FilterHeading: PropTypes.string,
	PageSize: PropTypes.number,
	NoHitsText: PropTypes.string,
	NoHitsHeading: PropTypes.string,
	isSearchPage: PropTypes.bool,
	sortBy: PropTypes.string,
	Years: PropTypes.array,
	IsEmptySearch: PropTypes.bool,
};
