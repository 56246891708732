import { useState, useEffect, useRef } from 'react';

const mapFilteredOptions = (options, optionsFilter) =>
	options?.filter((option) => {
		if (optionsFilter.length > 0) {
			if (optionsFilter.indexOf(option) > -1) {
				return true;
			}
			return false;
		}
		return true;
	});

export const useDropDown = ({
	options,
	isMultiChoice,
	onChange,
	initialSelected = null,
	anySelectedValue = true,
}) => {
	const [current, setCurrent] = useState(-1);
	const [selected, setSelected] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [optionsFilter, setOptionsFilter] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const componentRef = useRef(null);
	const inputRef = useRef(null);
	const optionsBoxRef = useRef(null);
	const currentOptionRef = useRef(null);
	useEffect(() => {
		if (initialSelected) {
			setSelected(
				Array.isArray(initialSelected)
					? initialSelected
					: [initialSelected],
			);
		}
	}, [initialSelected]);

	const refs = {
		component: componentRef,
		input: inputRef,
		optionsBox: optionsBoxRef,
		currentOption: currentOptionRef,
	};

	const filteredOptions = mapFilteredOptions(options, optionsFilter);

	useEffect(() => {
		setCurrent(-1);
		setOptionsFilter([]);
		setInputValue('');
	}, [isOpen]);

	useEffect(() => {
		if (isOpen && inputRef !== null && inputRef.current) {
			inputRef.current.focus();
		}
		optionsBoxRef.current.scrollTo({ top: 0 });
	}, [isOpen, inputRef]);

	useEffect(() => {
		if (!anySelectedValue) {
			setSelected([]);
		}
	}, [anySelectedValue]);

	useEffect(() => {
		function handleClickOutside(e) {
			if (
				componentRef.current &&
				!componentRef.current.contains(e.target)
			) {
				setIsOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [componentRef]);

	useEffect(() => {
		if (!isMultiChoice) {
			setCurrent(
				options?.findIndex((option) => option.value === selected[0]),
			);
		}
	}, [selected, options, isMultiChoice]);

	useEffect(() => {
		if (
			current > -1 &&
			currentOptionRef &&
			currentOptionRef.current &&
			optionsBoxRef
		) {
			const { height: optionsBoxHeight } =
				optionsBoxRef.current.getBoundingClientRect();
			const optionsBoxCenter = optionsBoxHeight / 2;
			const { offsetTop } = currentOptionRef.current;
			const { height: optionHeight } =
				currentOptionRef.current.getBoundingClientRect();
			const currentScrollPos = optionsBoxRef.current.scrollTop;

			if (
				currentScrollPos <= offsetTop ||
				currentScrollPos - optionsBoxHeight < offsetTop
			) {
				const top = offsetTop - optionsBoxCenter + optionHeight;
				optionsBoxRef.current.scrollTo({ top });
			}
		}
	}, [current, currentOptionRef, optionsBoxRef]);

	useEffect(() => {
		if (inputValue !== '') {
			const _filteredOptions = [];
			for (const option of options) {
				if (
					option.title
						.toLowerCase()
						.indexOf(inputValue.toLowerCase()) > -1
				) {
					_filteredOptions.push(option);
				}
			}
			setOptionsFilter(_filteredOptions);
		} else {
			setOptionsFilter([]);
		}
	}, [inputValue, options]);

	const handleClick = () => {
		if (!isOpen) setIsOpen(true);
	};

	const handleKeyUp = (e) => {
		const { keyCode } = e;
		const isArrowDown = keyCode === 40;
		const isArrowUp = keyCode === 38;
		const isEnter = keyCode === 13;
		const lastIndex = mapFilteredOptions(options, optionsFilter).length - 1;
		const isCurrentLast = current + 1 > lastIndex;
		const isCurrentFirst = current === 0;

		if (isArrowDown || isArrowUp) {
			e.preventDefault();
			let next = current;

			if (current === -1) {
				if (isArrowDown) {
					next = 0;
				}
				if (isArrowUp) {
					next = lastIndex;
				}
			} else {
				if (isArrowDown) {
					next = !isCurrentLast ? current + 1 : 0;
				}

				if (isArrowUp) {
					next = !isCurrentFirst ? current - 1 : lastIndex;
				}
			}
			setCurrent(next);
		}

		if (isEnter) {
			if (current !== -1) {
				const currentOption = mapFilteredOptions(
					options,
					optionsFilter,
				)[current].value;

				if (isMultiChoice) {
					const shouldAddOptions =
						selected.findIndex(
							(_option) => _option === currentOption,
						) === -1;

					if (shouldAddOptions) {
						setSelected([...selected, currentOption]);
					} else {
						setSelected(
							selected.filter(
								(_option) => _option !== currentOption,
							),
						);
					}
				} else {
					setSelected([currentOption]);
				}

				if (!isMultiChoice) {
					setIsOpen(false);
				}
			}
		}
	};

	const handleSelectedChange = (option = {}) => {
		if (isMultiChoice) {
			const shouldAddOptions =
				selected.findIndex(
					(_option) => _option.value === option.value,
				) === -1;

			let value = selected.filter(
				(_option) => _option.value !== option.value,
			);
			if (shouldAddOptions) {
				value = [...selected, option];
			}
			setSelected(value);
			if (onChange) onChange(value);
		} else {
			setSelected([option]);
			if (onChange) onChange([option]);
		}
	};

	const handleInputChange = (e) => {
		setInputValue(e.currentTarget.value);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleClear = () => {
		setSelected([]);
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handlers = {
		click: handleClick,
		keyUp: handleKeyUp,
		selectedChange: handleSelectedChange,
		inputChange: handleInputChange,
		close: handleClose,
		open: handleOpen,
		clear: handleClear,
	};

	return {
		// States
		isOpen,
		optionsFilter,
		inputValue,
		selected,
		current,
		handlers,
		refs,
		filteredOptions,
	};
};
