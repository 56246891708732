import { colors } from 'shared/core';
const frostTheme = {
	colors: {
		primary: colors.frost,
		interaction: colors.navy,
		white: colors.navy,
		highlight: colors.pink,
		linkShadow: colors.frostLinkShadow,
		puffText: colors.navy,
	},
};
export default frostTheme;
