import React from 'react';
import Head from 'next/head';
import { ThemeContext, themes } from '../../themes/campaignThemes';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { CampaignHeader } from './CampaignPage.styles';
import {
	Icon,
	BlockList,
	Caption,
	CampaignShareComponent,
} from 'shared/components';
import { Flex, Spacer } from 'shared/layout';
import { ScrollButton } from 'shared/blocks/CampaignHeroBlock/CampaignHeroBlock.styles';
import colors from '../../../shared/core/colors';
import zIndexes from '../../../shared/core/zIndexes';

const CampaignPage = ({
	DesignTheme = '',
	Blocks = [],
	ShareComponent: shareComponentData = {},
	SearchEngineSettings = {},
	HideLogo = false,
	HideBottomArrow = false,
	HideTopArrow = false,
	CampaignArrowTopText = '',
	CampaignArrowBottomText = '',
	CampaignArrowBottomHeading = '',
}) => {
	if (typeof window === 'undefined') return <></>;

	const selectedTheme = themes[DesignTheme] || themes.nightFire;

	return (
		<>
			<Head>
				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>
			<ThemeContext.Provider value={selectedTheme}>
				{!HideLogo && (
					<Flex
						position="relative"
						width="100%"
						align-items="center"
						justify-content="center"
					>
						<Link passHref href="/">
							<CampaignHeader title="Start">
								<Icon
									name="logo"
									color={selectedTheme.color.tcoBlue}
								/>
							</CampaignHeader>
						</Link>
					</Flex>
				)}
				<BlockList
					{...{
						HideTopArrow,
						CampaignArrowTopText,
					}}
					isCampaignPage
					blocks={Blocks}
				/>

				{!HideBottomArrow && (
					<Spacer spacing={{ base: 8, md: 32 }}>
						<Flex
							width="100%"
							flex-direction="column"
							align-items="center"
							justify-content="center"
							z-index={zIndexes.aboveBase}
						>
							<ScrollButton
								smallMargin
								onClick={() =>
									window.scrollTo({
										top: 0,
										behavior: 'smooth',
									})
								}
								title={CampaignArrowBottomText}
								aria-label={CampaignArrowBottomText}
								bgcolor={selectedTheme.color[2]}
							>
								<Icon name="arrow-up" color={colors.white} />
							</ScrollButton>
							<Caption textTransform="none">
								{CampaignArrowBottomHeading}
							</Caption>
						</Flex>
					</Spacer>
				)}
				{shareComponentData && (
					<>
						<Spacer spacing={{ base: 4, lg: 12 }}>
							<CampaignShareComponent {...shareComponentData} />
						</Spacer>
						<Spacer spacing={{ base: 9, lg: 14 }} />
					</>
				)}
			</ThemeContext.Provider>
		</>
	);
};

export default CampaignPage;

CampaignPage.propTypes = {
	// DesignTheme: PropTypes.oneOf(['Framåt', 'Nyfiken', 'Trovärdig']),
	Blocks: PropTypes.array,
	ColorTheme: PropTypes.string,
};
