import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Puff, SliderWrapper } from './';
import { MapTheme } from 'shared/helpers';
import { Flex, MaxWidthContainer, Spacer } from 'shared/layout';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import Carousel from 'nuka-carousel';
import { H2, MainBody, LinkButton } from 'shared/components';
import { buttonVariants } from 'shared/consts';

const renderPuffs = (Puffs = [], currentSlide = 0) => {
	return Puffs.map(({ CurrentTheme = 'e4f2e7', ...rest }, i) => (
		<MapTheme key={`mobile-puffs-${i}`} theme={CurrentTheme}>
			<Puff
				isFirstSlide={currentSlide === 0}
				currentTheme={CurrentTheme}
				key={`puff-${i}`}
				{...rest}
				isThree={Puffs?.length === 3}
			/>
		</MapTheme>
	));
};

const ReadMoreButton = ({
	ReadMoreButtonText = '',
	ReadMoreLink = { Url: '/soksidan' },
	Categories = [],
}) => {
	const { Url } = ReadMoreLink;

	return (
		<Flex flex-direction="row" justify-content="center">
			<LinkButton
				href={{
					pathname: Url,
					query: {
						categories: JSON.stringify(Categories),
					},
				}}
				variant={buttonVariants.GHOST}
			>
				{ReadMoreButtonText}
			</LinkButton>
		</Flex>
	);
};
const PuffBlock = ({
	Puffs = [],
	theme = {},
	Heading = '',
	Preamble = '',
	ReadMoreButtonText = 'Läs mer!',
	ReadMore: ShowReadMoreButton = false,
	ReadMoreLink,
	Categories,
}) => {
	const relatedContentProps = {
		ReadMoreButtonText,
		ReadMoreLink,
		Categories,
	};
	const isMobile = useMediaQuery(
		`(max-width: ${theme.breakpoints.md})`,
		false,
	);
	const isDesktop = useMediaQuery(
		`(min-width: ${theme.breakpoints.lg})`,
		true,
	);

	const [currentSlide, setCurrentSlide] = useState(0);
	const isMiddleSlide = currentSlide != 0 && currentSlide < Puffs.length - 1;
	return (
		!!Puffs.length && (
			<>
				<MaxWidthContainer>
					<Flex flex-direction="column" align-items="center">
						{!!Heading?.length && (
							<H2 text-align="center">{Heading}</H2>
						)}

						{!!Preamble?.length && (
							<Spacer spacing={{ base: 9, md: 6 }}>
								<MainBody
									text-align="center"
									max-width={theme.spacings[180]}
								>
									{Preamble}
								</MainBody>
							</Spacer>
						)}
					</Flex>
				</MaxWidthContainer>
				<MaxWidthContainer noGutters={isMobile || isDesktop}>
					<Spacer spacing={{ base: 7, md: 8 }}>
						{isMobile ? (
							<SliderWrapper leftMargin={isMiddleSlide}>
								<Carousel
									slideOffset={10}
									heightMode="max"
									cellSpacing={5}
									slidesToShow={1.1}
									cellAlign="center"
									style={{ height: '100%' }}
									speed={150}
									easing="easeLinear"
									afterSlide={(slideIndex) =>
										setCurrentSlide(slideIndex)
									}
									renderCenterLeftControls={() => null}
									renderCenterRightControls={() => null}
								>
									{renderPuffs(Puffs, currentSlide)}
								</Carousel>
							</SliderWrapper>
						) : (
							<Flex>{renderPuffs(Puffs)}</Flex>
						)}

						{ShowReadMoreButton && (
							<Spacer spacing={8}>
								<ReadMoreButton {...relatedContentProps} />
							</Spacer>
						)}
					</Spacer>
				</MaxWidthContainer>
			</>
		)
	);
};

export default withTheme(PuffBlock);

PuffBlock.propTypes = {
	Puffs: PropTypes.array,
	theme: PropTypes.object,
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
};
