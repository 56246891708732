import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Column, Box, Spacer, Flex } from 'shared/layout';
import { useTheme, withTheme } from 'styled-components';
import { ContactWrapper, ContactSocials } from './Contact.styles';
import {
	Icon,
	Caption,
	MainBody,
	ImageComponent,
	BaseLink,
} from 'shared/components';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import AnimateHeight from 'react-animate-height';

const GenerateSocial = ({ name = '', link = {} }) => {
	if (!link) return null;
	return (
		<ContactSocials
			icon={name}
			href={link.Url}
			key={`contact-${name}`}
			target="_blank"
			title={link.Name}
			aria-label={`Författarens ${name}`}
		>
			<Icon name={name} />
		</ContactSocials>
	);
};

const GeneratePhone = ({ number = '', label = '' }) => {
	const theme = useTheme();
	if (!number) return null;
	const isMediumUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.md})`,
		true,
	);
	return isMediumUp ? (
		<Spacer spacing={2}>
			<Caption color={theme.colors.black}>{label}:</Caption>
			<MainBody> {`${number} `}</MainBody>
		</Spacer>
	) : (
		<Spacer spacing={2}>
			<Caption color={theme.colors.black}>{label}:</Caption>
			<BaseLink
				inverted
				color={theme.colors.secondary}
				href={`tel:${number}`}
			>
				{`${number} `}
			</BaseLink>
		</Spacer>
	);
};

const Contact = ({
	theme = {},
	ContactFirstName = '',
	ContactLastName = '',
	ContactRoleDescription = '',
	EmailContact = {},
	XSocialContact = {},
	LinkedInContact = {},
	ContactImage = {},
	MobileNumber = '',
	TelephoneNumber = '',
}) => {
	const [showPhone, setShowPhone] = useState(false);
	const [showMail, setShowMail] = useState(false);

	return (
		<ContactWrapper>
			<Flex>
				{!!ContactImage && (
					<Box
						width="100%"
						max-width={{ base: 'unset', md: theme.spacings[22] }}
						height={theme.spacings[22]}
						margin-right={{ base: 0, md: theme.spacings.default }}
					>
						<ImageComponent
							src={ContactImage?.Url}
							width="90px"
							height="90px"
							borderRadius
							objectFit="cover"
							unoptimized
							alt={`Bild på ${ContactFirstName} ${ContactLastName}`}
						/>
					</Box>
				)}
				<Column display="flex" width="100%" flex-direction="column">
					<Spacer>
						<Caption>{ContactRoleDescription}</Caption>
					</Spacer>
					<Spacer spacing={2}>
						<MainBody>
							{`${ContactFirstName} ${ContactLastName}`}
						</MainBody>
					</Spacer>
					<AnimateHeight
						height={showMail ? 'auto' : 0}
						duration={200}
					>
						<Spacer spacing={2}>
							<BaseLink
								inverted
								color={theme.colors.secondary}
								href={`mailto:${EmailContact}`}
							>
								{EmailContact}
							</BaseLink>
						</Spacer>
					</AnimateHeight>

					<AnimateHeight
						height={showPhone ? 'auto' : 0}
						duration={200}
					>
						<Box
							display="flex"
							flex-diretion="row"
							justify-content={{
								base: 'center',
								md: 'flex-start',
							}}
							gap={theme.spacings.default}
						>
							{!!MobileNumber && (
								<GeneratePhone
									number={MobileNumber}
									label="Mobilnummer"
								/>
							)}
							{!!TelephoneNumber && (
								<GeneratePhone
									number={TelephoneNumber}
									label="Telefonnummer"
								/>
							)}
						</Box>
					</AnimateHeight>
				</Column>
			</Flex>
			<Column
				flex-direction="row"
				display="flex"
				align-items="flex-end"
				justify-content={{ base: 'center', md: 'unset' }}
				padding-top={theme.spacings[6]}
				noGutters
				gap={theme.spacings.default}
			>
				{!!EmailContact && (
					<ContactSocials
						as="button"
						onClick={() => setShowMail(!showMail)}
						aria-label={
							showMail
								? 'Dölj författarens mailadress'
								: 'Visa författarens mailadress'
						}
					>
						<Icon name="mail" />
					</ContactSocials>
				)}
				{(!!MobileNumber || !!TelephoneNumber) && (
					<ContactSocials
						as="button"
						onClick={() => setShowPhone(!showPhone)}
						aria-label={
							showPhone
								? 'Dölj författarens telefonnummer'
								: 'Visa författarens telefonnummer'
						}
					>
						<Icon name="phone" />
					</ContactSocials>
				)}
				{!!XSocialContact.Url &&
					<GenerateSocial name="x" link={XSocialContact} />
				}
				{!!LinkedInContact.url && 
					<GenerateSocial name="linkedIn" link={LinkedInContact} />
				}
			</Column>
		</ContactWrapper>
	);
};

export default withTheme(Contact);

Contact.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	workTitle: PropTypes.string,
	image: PropTypes.string,
	phone: PropTypes.string,
	mail: PropTypes.string,
	xSocial: PropTypes.string,
	linkedIn: PropTypes.string,
	mobileNumber: PropTypes.string,
	telephoneNumber: PropTypes.string,
};
