import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const StyledListLink = withResponsive(styled.a`
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => `${theme.spacings[8]} 0`};
	}
	padding: ${({ theme }) => `${theme.spacings[9]} ${theme.spacings[12]}`};

	display: block;
	box-shadow: ${({ theme, firstItem }) =>
		firstItem
			? `0px 1px 0px ${theme.colors.borderGrey}, 0px -1px 0px ${theme.colors.borderGrey}`
			: `0px 1px 0px ${theme.colors.borderGrey}`};

	transition: 0.2s ease;
	&:hover {
		background-color: ${({ theme }) => theme.colors.backgroundGrey};
		box-shadow: ${({ theme }) =>
			`0px 1px 0px ${theme.colors.primary}, 0px -1px 0px ${theme.colors.primary}`};
	}
`);
