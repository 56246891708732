import React from 'react';
import { withTheme } from 'styled-components';
import { Primary, Secondary, Ghost, Text } from './Button.styles';
import { buttonVariants } from 'shared/consts';
import PropTypes from 'prop-types';

const { PRIMARY, SECONDARY, GHOST, TEXT } = buttonVariants;
const Button = ({ variant = 'primary', children, ...props }) => {
	switch (variant) {
		case PRIMARY:
			return <Primary {...props}>{children}</Primary>;
		case SECONDARY:
			return <Secondary {...props}>{children}</Secondary>;
		case GHOST:
			return <Ghost {...props}>{children}</Ghost>;
		case TEXT:
			return <Text {...props}>{children}</Text>;

		default:
			return <Primary {...props}>{children}</Primary>;
	}
};

export default withTheme(Button);

Button.propTypes = {
	variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'text']),
};
