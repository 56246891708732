import React from 'react';
import { withTheme } from 'styled-components';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { Search } from 'shared/components';
import { SearchPageMeta } from './SearchPageMeta';
import { useRouter } from 'next/router';

const SearchPage = ({ Metadata = {}, ...props }) => {
	const { asPath } = useRouter();
	return (
		<>
			<Head>
				<meta name="robots" content="noindex" />
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							SearchPageMeta({ ...Metadata, asPath }),
						),
					}}
				/>
			</Head>
			<Search isSearchPage {...props} />
		</>
	);
};

export default withTheme(SearchPage);

SearchPage.propTypes = {
	Metadata: PropTypes.object,
};
