import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const ButtonStyle = css`
	display: inline-flex
	width: auto;
	font-size: ${({ theme }) => theme.typography.body};
	padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[16]}`};
	max-width: ${({ theme }) => theme.spacings[88]};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s ease;
	outline: none;
	height: ${({ theme }) => theme.spacings[15]};

	&:disabled {
		cursor: not-allowed;
	}

	&:hover,
	&:active {
		&& {
			color: ${({ theme }) => theme.colors.white};
		}
	}


	.is-tabbed &:focus {
		box-shadow: ${({ theme }) =>
			`${theme.boxShadows.lg} ${theme.colors.secondary}`};
	}
	
`;

export const BaseButton = styled.button`
	${ButtonStyle}
`;

export const primaryStyle = css`
	background-color: ${({ theme }) => theme.colors.primary};
	color: ${({ theme }) => theme.colors.white};

	&:hover,
	&:active {
		background-color: ${({ theme }) => theme.colors.secondary};
	}
	&:disabled {
		background-color: ${({ theme }) => theme.colors.primaryThin};
		color: ${({ theme }) => theme.colors.text2};
	}
`;

export const Primary = withResponsive(styled(BaseButton)`
	${primaryStyle}
`);

export const secondaryStyle = css`
	background-color: transparent;
	border-color: ${({ theme }) => theme.colors.secondary};
	color: ${({ theme }) => theme.colors.primary};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.sm} ${theme.colors.secondary}`};

	&:hover,
	&:active {
		background-color: ${({ theme }) => theme.colors.secondary};
	}
	&:disabled {
		color: ${({ theme }) => theme.colors.textThin};
		box-shadow: ${({ theme }) =>
			`${theme.boxShadows.sm} ${theme.colors.secondaryThin}`};
	}
`;
export const Secondary = withResponsive(styled(BaseButton)`
	${secondaryStyle}
`);

export const ghostStyle = css`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.primary};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.sm} ${theme.colors.primary}`};

	&:hover,
	&:active {
		background-color: ${({ theme }) => theme.colors.primary};
	}
	&:disabled {
		box-shadow: ${({ theme }) =>
			`${theme.boxShadows.sm} ${theme.colors.primaryThin}`};
	}
`;

export const Ghost = withResponsive(styled(BaseButton)`
	${ghostStyle}
`);

export const textStyle = css`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.primary};
	&:hover,
	&:active {
		background-color: ${({ theme }) => theme.colors.secondary};
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.primaryThin};
	}

	.is-tabbed &:focus {
		color: ${({ theme }) => theme.colors.primary};
	}
`;
export const Text = withResponsive(styled(BaseButton)`
	${textStyle}
`);
