import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const DropDownContainer = withResponsive(styled.div`
	position: relative;
	width: ${({ fullWidth }) => (fullWidth ? '100%' : '420px')};
	cursor: pointer;
	box-shadow: 0 0 0
		${({ selected, isOpen }) =>
			selected?.length && !isOpen ? '4px' : '2px'}
		${({ isOpen, theme }) =>
			isOpen ? theme.colors.secondary : theme.colors.primary};
	transition: box-shadow 0.3s ease;
`);
