const informationPageTypeVariants = {
	INFORMATIONPAGE: 'informationPage',
	PRESSRELEASEPAGE: 'pressreleasePage',
	REPORTPAGE: 'reportPage',
	REFERRALPAGE: 'referralPage',
	NEWSPAGE: 'newsPage',
	DEBATEPAGE: 'debatePage',
	BLOGPAGE: 'blogPage',
};

export default informationPageTypeVariants;
