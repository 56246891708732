const boxShadows = {
	xs: '0px 0px 0px 1px',
	sm: '0 0 0 2px inset',
	md: '0 0 0 3px inset',
	mdlg: '0 0 0 4px inset',
	lg: '0 0 0 5px inset',
	contact: '0px 4px 17px 6px',
	linkShadow: '0 0 24px 6px',
	emphasizedInset: 'inset 0px -15px 15px -2px',
};

export default boxShadows;
