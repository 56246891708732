import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'shared/layout';
import { withTheme } from 'styled-components';
import { FilterButton, FilterTextButton } from 'shared/components';
import { push } from 'shared/helpers';

const FilterTextButtons = ({
	Buttons = [],
	showAll = false,
	showMax = 11,
	handleFilterClick,
	selectedFilters = [],
	...rest
}) => {
	const slice = showAll ? Buttons.length : showMax;

	Buttons = Buttons.filter(
		(button) =>
			button.Name !== 'Ordförande och ledning' &&
			button.Name !== 'Press och kommunikation' &&
			button.Name !== 'Administration' &&
			button.Name !== 'Samverkan och utbildning' &&
			button.Name !== 'Politik och juridik' &&
			button.Name !== 'Arbetsvärlden',
	);

	return Buttons.slice(0, slice).map((button, i) => (
		<FilterTextButton
			onClick={(e) => {
				handleFilterClick(e, button);
				push({
					event: 'tags',
					buttonValue: button,
				});
			}}
			{...rest}
			key={`filtertextbutton-${i}`}
			{...button}
			isPressed={
				!!selectedFilters.length &&
				selectedFilters.some(({ Id }) => Id === button.Id)
			}
		>
			{button.Name}
		</FilterTextButton>
	));
};

const FilterSection = ({
	Buttons = [],
	theme,
	ShowMax = 11,
	selectedFilters = [],
	...rest
}) => {
	const [showAll, setShowAll] = useState(false);
	const handleClick = () => {
		setShowAll(!showAll);
	};

	return (
		<Flex
			margin-right={`-${theme.spacings[2]}`}
			margin-left={`-${theme.spacings[2]}`}
			display="flex"
			flex-direction="row"
			flex-wrap="wrap"
		>
			<FilterTextButtons
				showAll={showAll}
				showMax={ShowMax}
				{...{ Buttons }}
				margin={theme.spacings[2]}
				selectedFilters={selectedFilters}
				{...rest}
			/>
			{Buttons.length > ShowMax && (
				<FilterButton
					margin={theme.spacings[2]}
					onClick={handleClick}
					isExpanded={showAll}
					title={
						showAll
							? 'Visa färre alternativ'
							: 'Visa alla alternativ'
					}
				/>
			)}
		</Flex>
	);
};

export default withTheme(FilterSection);

FilterSection.propTypes = {
	Buttons: PropTypes.array,
	theme: PropTypes.object,
	ShowMax: PropTypes.number,
	selectedFilters: PropTypes.array,
};
