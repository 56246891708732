import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { MainBody, BaseLink } from 'shared/components';

export const StyledFooter = withResponsive(styled.footer`
	margin-top: ${({ theme }) => theme.spacings[6]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: ${({ theme }) => theme.spacings[8]};
	}
`);
export const StyledParagraph = withResponsive(styled(MainBody)`
	color: ${({ theme }) => theme.colors.white};
	margin-bottom: ${({ theme }) => theme.spacings[2]};
`);

export const FooterBaseLink = styled(BaseLink)`
	transition: all 0.3s ease;
	margin: ${({ theme }) => `0 ${theme.spacings[4]} 0 0`};

	&:hover,
	&:focus {
		transform: scale(1.2);
	}
`;
