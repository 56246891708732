import React from 'react';
import styled, { keyframes, withTheme } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import PropTypes from 'prop-types';

const defaultAnimation = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
`;
const Wrapper = styled(withResponsive('div'))`
	animation-name: ${({ animationName }) => animationName};
	animation-fill-mode: backwards;
	animation-duration: ${({ duration }) => `${duration}ms`};
	animation-delay: ${({ delay }) => `${delay}ms`};
`;
const FadeIn = ({
	duration = 300,
	delay = 0,
	children,
	keyFrames = defaultAnimation,
	...rest
}) => {
	return (
		<Wrapper {...{ duration, delay }} animationName={keyFrames} {...rest}>
			{children}
		</Wrapper>
	);
};

export default withTheme(FadeIn);

FadeIn.propTypes = {
	duration: PropTypes.number,
	delay: PropTypes.number,
	children: PropTypes.node,
	keyFrames: PropTypes.object,
};
