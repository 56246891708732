import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Spacer, Box } from 'shared/layout';
import BaseSkeleton from './BaseSkeleton';

const ResultSkeleton = ({ itemCount = 4, theme = {} }) => {
	let skeletonArray = [];
	for (let index = 0; index < itemCount; index++) {
		skeletonArray.push(
			<Box
				padding-left={{
					base: 0,
					md: theme.spacings[12],
				}}
				padding-bottom={theme.spacings[8]}
				box-shadow={
					index == 0
						? `0px 1px 0px ${theme.colors.borderGrey}, 0px -1px 0px ${theme.colors.borderGrey}`
						: `0px 1px 0px ${theme.colors.borderGrey}`
				}
				key={index}
			>
				<Spacer spacing={8}>
					<BaseSkeleton
						height={theme.spacings[4]}
						spacing={0}
						width={theme.spacings[24]}
					/>
					<BaseSkeleton
						height={theme.spacings[6]}
						width={theme.spacings[36]}
						spacing={1}
					/>
					<BaseSkeleton spacing={4} />
					<BaseSkeleton width={theme.spacings[20]} spacing={2} />
				</Spacer>
			</Box>,
		);
	}

	return skeletonArray;
};

export default withTheme(ResultSkeleton);

ResultSkeleton.propTypes = {
	theme: PropTypes.object,
	itemCount: PropTypes.number,
};
