import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { withTheme } from 'styled-components';

import { MaxWidthContainer, Spacer } from 'shared/layout';

import { Button } from 'shared/components';

import { PageListingBlockContainer } from './PageListingBlock.styles';
import PageListingBlockItem from './PageListingBlockItem';

const PageListingBlock = ({
	theme = {},
	PageListingBlockButtonText = '',
	ListedPages = [],
}) => {
	const [slice, setSlice] = useState(6);
	const isSiteWidthUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.siteWidth})`,
		true,
	);
	const isTwo = !!ListedPages?.length && ListedPages.length === 2;
	const showButton = slice === 6 && !isTwo && ListedPages?.length > 6;
	return (
		<MaxWidthContainer
			display="flex"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			noGutters={isSiteWidthUp}
		>
			<PageListingBlockContainer>
				{!!ListedPages?.length &&
					ListedPages.slice(0, slice).map((item, ix) => {
						return (
							<PageListingBlockItem
								isTwo={isTwo}
								{...item}
								key={ix}
							/>
						);
					})}
			</PageListingBlockContainer>
			{showButton && (
				<Spacer spacing={{ base: 6, md: 8 }}>
					<Button onClick={() => setSlice(999)}>
						{PageListingBlockButtonText}
					</Button>
				</Spacer>
			)}
		</MaxWidthContainer>
	);
};

PageListingBlock.propTypes = {
	PageListingBlockButtonText: PropTypes.string,
	ListedPages: PropTypes.arrayOf(
		PropTypes.shape({
			Heading: PropTypes.string,
			Preamble: PropTypes.string,
			Url: PropTypes.string,
		}),
	),
};

export default withTheme(PageListingBlock);
