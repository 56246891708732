const colors = {
	navy: '#004258',
	navyThin: 'rgba(0, 66, 88, 0.7)',
	navyDark: '#003142',
	oceanBlue: '#005E92',
	tcoBlue: '#009EC3',
	tcoBlueThin: 'rgba(0, 158, 195, 0.7)',
	tcoBlueThinner: 'rgb(0, 49, 66)',
	sky: '#89CDC7',
	powder: '#FCDFEB',
	orange: '#E9581E',
	fire: '#FFA900',
	vanilla: '#F6F9E2',
	white: '#FFFFFF',
	darkGrey: '#3D3D3D',
	darkGreyThin: 'rgba(61, 61, 61, 0.7)',
	backgroundGrey: '#FBFBFB',
	lightGrey: '#F3F3F3',
	breaklineGrey: '#DDDDDD',
	frost: '#E4F2E7',
	pink: '#DF006F',
	grey: 'rgba(223, 223, 223, 0.93)',
	asphalt: '#999999',
	black: '#1B1B1B',
	shadowGrey: '#EAEAEA40',
	borderGrey: '#F5F5F5',
	frostLinkShadow: 'rgba(140, 151, 142, 0.47)',
	primaryLinkShadow: 'rgba(35, 46, 50, 0.42)',
	primaryBackgroundEvent: 'rgba(255, 255, 255, 0.85)',
};

export default colors;
