import React from 'react';
import PropTypes from 'prop-types';

import { Spacer } from 'shared/layout';
import {
	RegistryComponent,
	capitalizeFirstCharacter,
	noSpacingHelper,
} from 'shared/helpers';

const BlockList = ({
	blocks = [],
	spacing = 12,
	children,
	isCampaignPage,
	...rest
}) => {
	if (!blocks || !blocks?.length) return null;
	return blocks.map((block, i) => (
		<Spacer
			key={i}
			spacing={isCampaignPage ? 0 : noSpacingHelper(block.Alias, spacing)}
		>
			{children}
			<RegistryComponent
				alias={capitalizeFirstCharacter(block.Alias)}
				{...block}
				{...rest}
			/>
		</Spacer>
	));
};

BlockList.propTypes = {
	blocks: PropTypes.array,
};

export default BlockList;
