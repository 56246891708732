import styled from 'styled-components';

export const HeaderWrapper = styled.header`
	transition: 0.5s ease all;
	z-index: 1;
	z-index: ${({ theme }) => theme.zIndexes.banner};
	background: ${({ offset, theme }) =>
		offset === 'true' ? theme.colors.white : 'transparent'};
	width: 100%;
	height: ${({ theme }) => theme.spacings[24]};
	top: 0;
	position: sticky;
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		height: ${({ theme }) => theme.spacings[28]};
	}
`;

export const MenuContainer = styled.nav`
	background: ${({ theme }) => theme.colors.primary};
	padding: ${({ theme }) =>
		`${theme.spacings[4]} ${theme.spacings[6]} ${theme.spacings[6]}`};
	color: ${({ theme }) => theme.colors.interaction};
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;

	.zindex {
		z-index: 2000;
	}
`;

export const HeaderIcon = styled.a`
	padding: ${({ theme }) => theme.spacings[4]};
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: 0;
	}
`;

export const HeaderLinkContainer = styled.a`
	display: flex;
	align-items: center;
	text-align: center;
`;
