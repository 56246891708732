// Add class to body-element on tab click

const tabHighlight = () => {
	const highLightClass = 'is-tabbed';
	const $body = document.body;

	const self = {
		init() {
			self.addEventListeners();
		},
		onKeyDown(e) {
			const keyCode = e.keyCode || e.which;
			const tabKey = 9;

			if (keyCode === tabKey) {
				$body.classList.add(highLightClass);
			}
		},
		onMouseDown() {
			$body.classList.remove(highLightClass);
		},
		addEventListeners() {
			$body.addEventListener('keydown', self.onKeyDown);
			$body.addEventListener('mousedown', self.onMouseDown);
		},
	};

	return self;
};

export default tabHighlight;
