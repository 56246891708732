import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const EventInfoWrapper = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		height: ${({ theme }) => theme.spacings[104]};
		padding: ${({ theme }) => `${theme.spacings[15]} ${theme.spacings[6]}`};
		background-color: ${({ theme }) => theme.colors.puffText};
		background-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : '')});
		background-repeat: no-repeat;
		background-size: cover;
	}
	padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[0]}`};
	width: 50%;
`);

export const EventInfoContainer = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) =>
			`${theme.spacings[6]} ${theme.spacings[6]} ${theme.spacings[8]}`};
		flex-direction: column;
		display: inline-table;

		width: 50%;
		background-color: ${({ theme }) => theme.colors.eventShadow};
		background-image: url(${({ imageSrc }) => (imageSrc ? '' : '')});
	}
	background-color: ${({ theme }) => theme.colors.puffText};
	background-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : '')});
	background-repeat: no-repeat;
	background-size: cover;
	padding: ${({ theme }) => theme.spacings[0]};
`);

export const EventInfoContainerOverlay = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		background-color: rgba(0, 0, 0, 0);
		height: fit-content;
	}
	background-color: ${({ theme, imageSrc }) =>
		imageSrc ? theme.colors.eventShadow : ''};
`);

export const EventTextInfoWrapper = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[0]}`};
	}
	padding: ${({ theme }) => `${theme.spacings[5.5]} ${theme.spacings[6]}`};
	margin-top: ${({ theme }) => theme.spacings[0]};
`);

export const EventButtonInfoWrapper = withResponsive(styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) =>
			`${theme.spacings[4]} ${theme.spacings[0]} ${theme.spacings[0]}`};
	}
	margin-top: ${({ theme }) => theme.spacings[2]};
`);
