import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';
import { withTheme } from 'styled-components';
import { BlockList } from 'shared/components';
import { StartPageMeta } from './StartPageMeta';
const StartPage = ({ BottomBlocks = [], Metadata = [] }) => {
	const { asPath } = useRouter();
	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							StartPageMeta({ ...Metadata, asPath }),
						),
					}}
				/>
			</Head>
			{!!BottomBlocks.length && <BlockList blocks={BottomBlocks} />}
		</>
	);
};

export default withTheme(StartPage);
