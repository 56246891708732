import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { withTheme } from 'styled-components';
import DesktopCampaignBlock from './DesktopCampaignBlock';
import MobileCampaignBlock from './MobileCampaignBlock';

const CampaignContentBlock = ({
	theme = {},
	Heading = '',
	Text = '',
	Image = {},
	RightAlignedText = false,
	ContentLink = {},
}) => {
	const isLargeUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.lg})`,
		true,
	);
	return (
		<>
			{isLargeUp ? (
				<DesktopCampaignBlock
					Heading={Heading}
					Text={Text}
					Image={Image}
					RightText={RightAlignedText}
					ContentLink={ContentLink}
				/>
			) : (
				<MobileCampaignBlock
					Heading={Heading}
					Text={Text}
					Image={Image}
					RightText={RightAlignedText}
					ContentLink={ContentLink}
				/>
			)}
		</>
	);
};

CampaignContentBlock.propTypes = {
	Heading: PropTypes.string,
	Text: PropTypes.string,
	Image: PropTypes.object,
	RightAlignedText: PropTypes.bool,
	ContentLink: PropTypes.object,
};

export default withTheme(CampaignContentBlock);
