import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const StyledLink = withResponsive(styled.a.attrs(({ download }) => ({
	download: download,
}))`
	${({ isDocument, theme }) =>
		isDocument &&
		css`
			&& {
				display: flex;
				align-items: center;
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: underline;
					& {
						color: ${theme.colors.documentLink};
					}
				}

				color: ${theme.colors.documentLink};
				font-weight: ${theme.typography.fontWeights.normal};
				padding-top: ${theme.spacings[2]};
				&:hover {
					&& {
						color: ${theme.colors.documentLink};
					}
				}
				span {
				}
				.icon-document {
					margin-right: ${theme.spacings[3]};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
					font-size: ${theme.typography.fontSizes.desktop.body};
				}
			}
		`}

	${({ inverted }) =>
		inverted &&
		css`
			text-decoration: underline;

			&:hover {
				&& {
					text-decoration: none;
				}
			}
		`}

	&:hover {
		text-decoration: ${({ noUnderline }) =>
			noUnderline ? 'none' : 'underline'};
		color: ${({ hoverColor }) => hoverColor && hoverColor};

		transition: ${({ theme, hoverColor }) =>
			hoverColor && theme.transitions.default};
	}
`);
