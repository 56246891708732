import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'shared/components';
import { Flex } from 'shared/layout';
import { withTheme } from 'styled-components';
import { FooterBaseLink } from './Footer.styles';

const FooterSocials = ({
	facebookLink = {},
	instagramLink = {},
	linkedInLink = {},
	xSocialLink = {},
	youtubeLink = {},
}) => {
	const SocialLinkWithIcon = ({ name = '', link = {} }) => {
		if (!link || !link.Url) return null;
		return (
			<FooterBaseLink
				className="white-tab"
				target="_blank"
				href={link.Url}
			>
				<Icon font-size="2rem" name={name} />
			</FooterBaseLink>
		);
	};
	return (
		<Flex
			flex-direction="row"
			justify-content={{ base: 'center', md: 'flex-start' }}
		>
			<SocialLinkWithIcon name="facebook" link={facebookLink} />
			<SocialLinkWithIcon name="instagram" link={instagramLink} />
			<SocialLinkWithIcon name="linkedIn" link={linkedInLink} />
			<SocialLinkWithIcon name="x" link={xSocialLink} />
			<SocialLinkWithIcon name="youtube" link={youtubeLink} />
		</Flex>
	);
};

FooterSocials.propTypes = {
	facebookLink: PropTypes.object,
	instagramLink: PropTypes.object,
	linkedInLink: PropTypes.object,
	xSocialLink: PropTypes.object,
	youtubeLink: PropTypes.object,
};

export default withTheme(FooterSocials);
