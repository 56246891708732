import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Image from 'next/image';

const ImageComponent = ({
	src = '/',
	width = '',
	height = '',
	layout = 'intrinsic',
	borderRadius = false,
	...rest
}) => {
	const theme = useContext(ThemeContext);
	return (
		<StyledImage
			src={src}
			layout={layout}
			width={layout !== 'fill' && width}
			height={layout !== 'fill' && height}
			borderradius={borderRadius ? theme.borderRadius.image : 0}
			{...rest}
		/>
	);
};

export default ImageComponent;

const StyledImage = styled(Image)`
	border-radius: ${({ borderradius }) => borderradius};
`;
