import { typography } from '.';
const fontsPath = '/fonts';

export default `@font-face{
                    font-family: ${typography.fonts.heading};
                    src: url("${fontsPath}/WorkSans-Black.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.heading};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.heading};
                    src: url("${fontsPath}/WorkSans-Regular.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.normal};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.heading};
                    src: url("${fontsPath}/WorkSans-RegularItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.normal};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.heading};
                    src: url("${fontsPath}/WorkSans-Medium.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.medium};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.heading};
                    src: url("${fontsPath}/WorkSans-MediumItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.medium};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-Light.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.light};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-LightItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.light};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-Regular.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.normal};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-RegularItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.normal};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-Medium.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.medium};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-MediumItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.medium};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-Bold.woff") format("woff");
                    font-style: normal;
                    font-weight: ${typography.fontWeights.bold};
                    font-display: swap;
                }

                @font-face{
                    font-family: ${typography.fonts.text};
                    src: url("${fontsPath}/BrioniSansStd-BoldItalic.woff") format("woff");
                    font-style: italic;
                    font-weight: ${typography.fontWeights.bold};
                    font-display: swap;
                }
            `;
