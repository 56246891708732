import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { Spacer } from 'shared/layout';
import PropTypes from 'prop-types';
import { H1 } from 'shared/components';
import {
	FullWidthColoredBackground,
	CampaignBlockContainer,
	PrefixHeader,
	CampaignButton,
} from '../../pages/CampaignPage/CampaignPage.styles';
import Link from 'next/link';

const CampaignCtaBlock = ({
	Heading = 'Vikten av kompoetensutveckling',
	Text = '',
	Button = {},
}) => {
	const theme = useContext(ThemeContext);

	return (
		<FullWidthColoredBackground $color={theme.color[4]}>
			<CampaignBlockContainer $theme={theme}>
				{!!Heading && (
					<PrefixHeader
						$theme={theme}
						word-break={{ base: 'break-word', md: 'unset' }}
						as="h2"
						$color={theme.color[1]}
					>
						{Heading}
					</PrefixHeader>
				)}
				{!!Text && (
					<Spacer spacing={{ base: 6.5, md: 8 }}>
						<H1
							word-break={{ base: 'break-word', md: 'unset' }}
							as="h2"
							color={theme.color[1]}
						>
							{Text}
						</H1>
					</Spacer>
				)}
				{!!Button && (
					<Spacer>
						<Link href={Button?.Url} passHref>
							<CampaignButton
								target={Button?.Target}
								$theme={theme}
								as="button"
							>
								{Button?.Name}
							</CampaignButton>
						</Link>
					</Spacer>
				)}
			</CampaignBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignCtaBlock;

CampaignCtaBlock.propTypes = {
	Heading: PropTypes.string,
	Text: PropTypes.string,
	theme: PropTypes.object,
};
