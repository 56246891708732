import PropTypes from 'prop-types';
import React, { useState, useEffect, createRef } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { withTheme } from 'styled-components';
import { useSearchParam } from 'react-use';
import { useQuery } from 'react-query';

import {
	H1,
	H4,
	Input,
	MainBody,
	Button,
	BaseLink,
	Breadcrumbs,
	RteContent,
} from 'shared/components';

import { MaxWidthContainer, Spacer, Box } from 'shared/layout';

import { useBlogItem } from 'shared/hooks';

import BlogItem from './BlogItem';

import { getSubscription, deleteSubscription } from 'api';
import { DropDown } from '../../components/Dropdown/DropDown';
import Head from 'next/head';

const SubscriptionPage = ({
	theme = {},
	BreadcrumbLinks = {},
	Heading = '',
	Preamble = '',
	NewsLetterBlogHeading = '',
	NewsLetters = [],
	ErrorText = '',
	Blogs = [],
	BlogSignUpParameters = [],
	BlogSignUpUrl = '',
	BlogUpdateUrl = '',
	ThankYouPage = '',
	EmailValidationText = '',
	Link = {},
	SubscriptionCategories = {},
	SearchEngineSettings = {},
	ButtonTextSubscription,
	EmailFieldHeading,
}) => {
	const [unsubscribeMode, setUnsubscribeMode] = useState(false);
	const [unsubscribeId, setUnsubscribeId] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [honeyTrapValue, setHoneyTrapValue] = useState('');
	const [inputError, setInputError] = useState(false);
	const [postError, setPostError] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [_subscriptionCategories, _setSubscriptionCategories] = useState([]);
	const [initialCategories, setInitialCategories] = useState(null);
	const inputRef = createRef(null);
	const emailValidator =
		/^\w+([.-]?\w+)+@\w+([.:]?\w+)+(.[a-zA-Z0-9]{2,3})+$/;
	const axHeader = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};
	const { selectedIds, expandedIds, handleExpand, handleSelect } =
		useBlogItem();
	const { push } = useRouter();

	//couldn't use next query reliably since we end up on this page from an external url
	const subscriptionId = useSearchParam('q');

	//set unsubscribe mode before layout to prevent layout shift if true
	useEffect(() => {
		if (subscriptionId) {
			setUnsubscribeMode(true);
		}
	}, []);

	/* Page is to be used both by users who wants to subscribe to blogs/newsletters, as well
	as a place to unsubscribe from blogs. Depending on queryparam subscriptionId, we know
	if we should subscribe or unsubscribe. */
	const { data: _subscriptionData } = useQuery(
		['getSubscription', subscriptionId],
		() => getSubscription(subscriptionId),
		{ enabled: !!subscriptionId },
	);

	//if we have data, pre-select the users already subscribed blogs
	useEffect(() => {
		if (_subscriptionData) {
			if (!_subscriptionData.IsFound) return setUnsubscribeMode(false);
			/* Preselect blogs */
			_subscriptionData?.Subscriptions?.forEach((item) => {
				if (Blogs?.find((x) => x.Id === +item)) handleSelect(+item);
			});

			/* Map through subscriptions fetched from API, match to backend categories and fit data to dropdown */
			setInitialCategories(
				_subscriptionData?.Subscriptions?.map((item) => {
					return SubscriptionCategories?.find((x) => x.Id === +item);
				})
					.filter((el) => {
						return !!el;
					})
					.map((item) => {
						return { title: item?.Heading, value: item?.Id };
					}),
			);
			setInputValue(_subscriptionData.Email);
			setUnsubscribeId(_subscriptionData.Id);
		}
	}, [_subscriptionData]);

	//Manual validation of email
	const handleInputChange = (e) => {
		if (!e.target.value) {
			setInputError(true);
			setIsValid(false);
		} else {
			setInputError(false);
		}
		setInputValue(e.target.value);
	};

	//Manual validation of email
	const handleBlur = (e) => {
		if (!e.target.value || !emailValidator.test(inputValue)) {
			setInputError(true);
			setIsValid(false);
		} else {
			setInputError(false);
			setIsValid(true);
		}
	};

	//Append queryparam to let thankyou-page know if it is unsubscribe or not
	const handleRedirect = (deleteText = false) => {
		push(deleteText ? `${ThankYouPage}?unsubscribe=true` : ThankYouPage);
	};

	//Append queryparam to let thankyou-page know user has updated preferences
	const handleUpdateRedirect = () => {
		push(`${ThankYouPage}?update=true`);
	};
	//Check for chosen blogs/newsletters, post accordingly, should be redirected if done correctly
	const handleSubmit = () => {
		const _blogsToSubscribe = Blogs?.filter((x) =>
			selectedIds?.find((y) => y === x.Id),
		);
		const _blogItems = _blogsToSubscribe?.map((item) => item.Id);

		//Need to pass info as urlsearchparams in order to use x-www-form. Honeypot trap is used to avoid bots
		let urlData = new URLSearchParams();
		urlData.append('websiteurl', honeyTrapValue);
		urlData.append(BlogSignUpParameters[0], inputValue);
		_blogItems?.forEach((it) => {
			urlData.append(BlogSignUpParameters[1], it);
		});
		_subscriptionCategories?.forEach((it) => {
			urlData.append(BlogSignUpParameters[1], it.value);
		});

		//if we are in unsubscribe mode and have any checked items we use the update-endpoint, if no checked items use delete-endpoint,
		//otherwise use the signup-endpoint.
		if (
			unsubscribeMode &&
			(!!_blogItems?.length || !!_subscriptionCategories?.length)
		) {
			axios
				.post(BlogUpdateUrl, urlData, axHeader)
				.then(() => handleUpdateRedirect());
		} else if (
			unsubscribeMode &&
			(!_blogItems?.length || !_subscriptionCategories?.length)
		) {
			deleteSubscription(unsubscribeId).then((res) => {
				//doesn't return 400, returns 200 with a bool for success
				res.IsSuccessStatusCode
					? handleRedirect(true)
					: setPostError(true);
			});
		} else if (
			!unsubscribeMode &&
			(!!_blogItems?.length || !!_subscriptionCategories?.length)
		) {
			axios
				.post(BlogSignUpUrl, urlData, axHeader)
				.then(() => handleRedirect());
		}

		//If user is not in unsubscribe mode, post selected newsletters. Idrelay (api-provider) handles the unsubscription from these via email.
		if (!unsubscribeMode) {
			const _newsLettersToSubscribe = NewsLetters?.filter((x) =>
				selectedIds?.find((y) => y === x.Url),
			);

			if (_newsLettersToSubscribe?.length) {
				let newsLetterUrlData = new URLSearchParams();
				newsLetterUrlData.append('email', inputValue);
				_newsLettersToSubscribe?.forEach((item) => {
					axios
						.post(item.Url, newsLetterUrlData, axHeader)
						.then(() => handleRedirect());
				});
			}
		}
	};

	const disabledInput =
		inputError ||
		!inputValue ||
		(!selectedIds?.length && !_subscriptionCategories?.length);

	return (
		<>
			<Head>
				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>

			<MaxWidthContainer narrow>
				<Breadcrumbs breadcrumbLinks={BreadcrumbLinks} />
				{!!Heading && (
					<Spacer>
						<H1>{Heading}</H1>
					</Spacer>
				)}
				{!!Preamble && (
					<Spacer>
						<RteContent text={Preamble} />
					</Spacer>
				)}
				<Spacer>
					<Box max-width={{ lg: theme.spacings[92] }}>
						<DropDown
							identifier="subscription-topic-selection"
							options={SubscriptionCategories?.map(
								({ Id, Heading }) => {
									return { title: Heading, value: Id };
								},
							)}
							initialSelected={initialCategories}
							placeholder="Välj ämne"
							searchPlaceholder="Sök på ämne"
							isMultiChoice
							onChange={(val) => _setSubscriptionCategories(val)}
							fullWidth
						/>
					</Box>
				</Spacer>
				{!unsubscribeMode && (
					<Spacer>
						<Spacer spacing={{ base: 7, md: 10 }}>
							<H4>{NewsLetterBlogHeading}</H4>
						</Spacer>
						{!!NewsLetters?.length &&
							NewsLetters?.map((item, ix) => {
								return (
									<BlogItem
										{...item}
										{...{
											selectedIds,
											expandedIds,
											theme,
											handleExpand,
											handleSelect,
										}}
										Id={item.Url}
										key={ix}
										first={ix === 0}
									/>
								);
							})}
						{!!Blogs?.length &&
							Blogs?.map((item, ix) => {
								return (
									<BlogItem
										{...item}
										{...{
											selectedIds,
											expandedIds,
											theme,
											handleExpand,
											handleSelect,
										}}
										key={ix}
									/>
								);
							})}
					</Spacer>
				)}
				{EmailFieldHeading && (
					<Spacer spacing={10}>
						<H4>{EmailFieldHeading}</H4>
					</Spacer>
				)}
				<Spacer>
					<Input
						display="none"
						onChange={(e) => setHoneyTrapValue(e.target.value)}
						value={honeyTrapValue}
					/>
					<Input
						value={inputValue}
						onChange={(e) => handleInputChange(e)}
						onBlur={(e) => handleBlur(e)}
						placeholder="Mejladress"
						type="email"
						error={inputError}
						valid={isValid}
						disabled={!!_subscriptionData?.Email}
						inputRef={inputRef}
					/>
					{inputError && (
						<MainBody color={theme.colors.error}>
							{EmailValidationText ??
								'Fyll i en korrekt mailadress'}
						</MainBody>
					)}
				</Spacer>
				<Spacer spacing={12}>
					<Box
						display="flex"
						flex-direction={{ base: 'column', md: 'row' }}
						align-items={{ base: 'flex-start', md: 'center' }}
						gap={theme.spacings.default}
					>
						<Box
							display="flex"
							flex-direction="column"
							align-items={{ base: 'flex-start', md: 'flex-end' }}
						>
							<Button
								disabled={
									unsubscribeMode ? false : disabledInput
								}
								onClick={() => handleSubmit()}
							>
								{ButtonTextSubscription ?? 'Skicka'}
							</Button>
							{postError && (
								<MainBody
									color={theme.colors.error}
									padding-top={theme.spacings.default}
								>
									{ErrorText ??
										'Något gick fel, vänligen försök igen'}
								</MainBody>
							)}
						</Box>
						{!!Link && (
							<BaseLink
								color={theme.colors.primaryLight}
								href={Link?.Url}
								target={Link?.target}
								font-size={{ md: '1.125rem' }}
							>
								{Link?.Name}
							</BaseLink>
						)}
					</Box>
				</Spacer>
			</MaxWidthContainer>
		</>
	);
};

export default withTheme(SubscriptionPage);

SubscriptionPage.propTypes = {
	BreadcrumbLinks: PropTypes.arrayOf(
		PropTypes.shape({
			CurrentPage: PropTypes.bool,
			IsDirectParent: PropTypes.bool,
			Name: PropTypes.string,
			Url: PropTypes.string,
		}),
	),
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	NewsLetterBlogHeading: PropTypes.string,
	NewsLetters: PropTypes.arrayOf(
		PropTypes.shape({
			Heading: PropTypes.string,
			Preamble: PropTypes.string,
			Url: PropTypes.string,
			Parameter: PropTypes.string,
		}),
	),
	Blogs: PropTypes.arrayOf(
		PropTypes.shape({
			Heading: PropTypes.string,
			Id: PropTypes.number,
			Preamble: PropTypes.string,
		}),
	),
	EmailValidationText: PropTypes.string,
	ButtonTextSubscription: PropTypes.string,
	EmailFieldHeading: PropTypes.string,
	BlogSignUpParameters: PropTypes.arrayOf(PropTypes.string),
	BlogSignUpUrl: PropTypes.string,
	ErrorText: PropTypes.string,
	BlogUpdateUrl: PropTypes.string,
	ThankYouPage: PropTypes.string,
	Link: PropTypes.object,
	SubscriptionCategories: PropTypes.arrayOf(
		PropTypes.shape({ Heading: PropTypes.string, Id: PropTypes.number }),
	),
};
