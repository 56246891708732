import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { mainBody2 } from 'shared/components';
export const StyledInput = withResponsive(styled.input`
	height: ${({ theme }) => theme.spacings[12]};
	border-radius: ${({ theme }) => theme.spacings[0.5]};
	${mainBody2};
	margin: 0;
	color: ${({ theme }) => theme.colors.textBlack};
	transition: all 0.3s ease;
	transition-property: border, box-shadow;
	border: ${({ theme, valid, error }) => `${theme.spacings[0.5]} solid
			${
				valid
					? theme.colors.success
					: error
						? theme.colors.error
						: theme.colors.primary
			}`};
	padding: ${({ theme }) => theme.spacings.default};
	padding-right: ${({ icon, theme }) => icon && theme.spacings[10]};
	margin-right: -${({ icon, theme }) => icon && theme.spacings[8]};
	width: 100%;
	&:disabled {
		color: ${({ theme }) => theme.colors.disabled};
		border: ${({ theme }) =>
			`${theme.spacings[0.5]} solid ${theme.colors.disabled}`};
		cursor: not-allowed;
	}

	&:focus {
		${({ noFocus }) =>
			!noFocus
				? css`
						border-color: ${({ theme }) => theme.colors.secondary};
						box-shadow: ${({ theme }) =>
							`${theme.boxShadows.xs} ${theme.colors.secondary}`};
					`
				: css`
						outline: none;
					`}
	}

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		margin: 0;
		color: ${({ theme }) => theme.colors.disabled};
	}

	~ i {
		position: absolute;
		right: ${({ theme }) => theme.spacings[4]};
	}
`);
