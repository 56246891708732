import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../../layout';
import DropDownCheckbox from '../Checkbox/DropDownCheckbox';

export const DropDownOption = ({
	value,
	title,
	innerRef,
	name,
	optionId,
	isSelected,
	isMultiChoice,
	onChange,
}) => {
	const theme = useTheme();
	const withRef = innerRef ? { ref: innerRef } : {};
	const [focus, setFocus] = useState(false);

	const handleClick = () => {
		setFocus(false);
		onChange();
	};

	return (
		<Box
			display="flex"
			position="relative"
			background={focus ? theme.colors.lightGrey : 'white'}
			cursor="pointer"
			padding="6px 12px"
			{...withRef}
		>
			{isMultiChoice && (
				<DropDownCheckbox
					id={optionId}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					onClick={() => handleClick()}
					isChecked={isSelected}
					label={title}
					{...{ onChange, name, value }}
				/>
			)}
		</Box>
	);
};
