import styled from 'styled-components';
import PropTypes from 'prop-types';
import withResponsive from 'shared/helpers/withResponsive';

const Spacer = styled.div`
	padding-${({ direction }) => direction || 'top'}: ${({ spacing, theme }) =>
		spacing && typeof spacing.base != 'undefined'
			? theme.spacings[spacing.base]
			: spacing != undefined && typeof spacing == 'number'
				? theme.spacings[spacing]
				: theme.spacings.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-${({ direction }) => direction || 'top'}: ${({
			spacing,
			theme,
		}) =>
			spacing && typeof spacing.sm != 'undefined'
				? theme.spacings[spacing.sm]
				: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-${({ direction }) => direction || 'top'}: ${({
			spacing,
			theme,
		}) =>
			spacing && typeof spacing.md != 'undefined'
				? theme.spacings[spacing.md]
				: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding-${({ direction }) => direction || 'top'}: ${({
			spacing,
			theme,
		}) =>
			spacing && typeof spacing.lg != 'undefined'
				? theme.spacings[spacing.lg]
				: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        padding-${({ direction }) => direction || 'top'}: ${({
			spacing,
			theme,
		}) =>
			spacing && typeof spacing.xl != 'undefined'
				? theme.spacings[spacing.xl]
				: undefined};
    }
`;

Spacer.propTypes = {
	spacing: PropTypes.object,
	direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

export default withResponsive(Spacer);
