import React, { useContext } from 'react';
import { withTheme } from 'styled-components';
import { ThemeContext } from '../../themes/campaignThemes';
import { H1, ImageComponent, RteContent } from 'shared/components';
import { Box, MaxWidthContainer, Spacer } from 'shared/layout';
import Link from 'next/link';
import { CampaignButton } from '../../pages/CampaignPage/CampaignPage.styles';

const MobileCampaignBlock = ({
	Heading = '',
	Text = '',
	Image = {},
	theme = {},
	ContentLink = {},
}) => {
	const newTheme = useContext(ThemeContext);

	return (
		<Box background={newTheme.color[1]} padding-bottom={theme.spacings[8]}>
			{!!Image && (
				<ImageComponent
					src={Image?.Url}
					width={Image?.Width}
					height={Image?.Height}
				/>
			)}
			<MaxWidthContainer>
				{!!Heading && (
					<H1
						word-break={{ base: 'break-word', md: 'unset' }}
						as="h2"
						color={newTheme.color[3]}
					>
						{Heading}
					</H1>
				)}
				{!!Text && (
					<>
						<Spacer spacing={{ base: 6.5, md: 8 }}>
							<RteContent
								as="div"
								text={Text}
								color={newTheme.color[3]}
							/>
						</Spacer>
						<Spacer spacing={{ base: 4, lg: 8 }} />
					</>
				)}
				{!!ContentLink && ContentLink.Url && (
					<Spacer>
						<Link href={ContentLink?.Url} passHref>
							<CampaignButton
								target={ContentLink?.Target}
								$theme={newTheme}
								$altVersion
								as="button"
							>
								{ContentLink?.Name}
							</CampaignButton>
						</Link>
					</Spacer>
				)}
			</MaxWidthContainer>
		</Box>
	);
};

export default withTheme(MobileCampaignBlock);
