import styled from 'styled-components';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const StyledCheckbox = styled.div`
	transition: ease all 0.3s;
	display: inline-flex;
	width: ${({ theme }) => theme.spacings.default};
	height: ${({ theme }) => theme.spacings.default};
	background: ${({ theme, checked }) =>
		checked ? theme.colors.secondary : theme.colors.white};
	border-radius: 3px;
	border: 2px solid
		${({ theme, checked }) =>
			checked ? theme.colors.secondary : theme.colors.primary};
	color: ${({ theme }) => theme.colors.white};
	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.secondary};
	}

	${HiddenCheckbox}:focus + & {
		border-color: ${({ theme }) => theme.colors.secondary};
	}

	svg {
		visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
	}
`;

export const CheckboxContainer = styled.div`
	display: ${({ flex }) => (flex ? 'inline-flex' : 'inline')};
	vertical-align: middle;
`;
