import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BaseLink, Icon } from 'shared/components';
import { withTheme } from 'styled-components';
import { MenuLinkText, ExpandButton } from './HamburgerMenu.styles';
import AnimateHeight from 'react-animate-height';

const HamburgerMenuItem = ({ items = {}, handleMenuClick = () => {} }) => {
	const [isOpen, setIsOpen] = useState(items.Selected);

	const hasChildren = !!items?.Children && !!items?.Children.length;
	useEffect(() => {
		if (hasChildren && items.Children.find((x) => x.Selected))
			setIsOpen(true);
	}, []);

	const headerLinkClick = (e) => {
		e.stopPropagation();
		handleMenuClick();
	};

	const handleKeyPress = (e) => {
		e.preventDefault();
		if (e.key === 'Enter') setIsOpen(!isOpen);
	};

	return (
		<>
			<MenuLinkText
				onClick={() => setIsOpen(!isOpen)}
				active={items.Selected}
				aria-expanded={isOpen}
			>
				<BaseLink
					href={items.Url}
					onClick={(e) => headerLinkClick(e)}
					className="white-tab"
				>
					{items.Name}
				</BaseLink>
				{hasChildren && (
					<ExpandButton
						className="white-tab"
						aria-expanded={isOpen ? 'true' : 'false'}
						onKeyPress={(e) => handleKeyPress(e)}
						onClick={() => setIsOpen(!isOpen)}
					>
						<Icon name={isOpen ? 'minus' : 'plus'} />
					</ExpandButton>
				)}
			</MenuLinkText>
			<AnimateHeight height={isOpen ? 'auto' : 0} duration={500}>
				{hasChildren &&
					items.Children.map((item, ix) => {
						return (
							<MenuLinkText key={ix} child active={item.Selected}>
								<BaseLink
									className="white-tab"
									href={item.Url}
									onClick={(e) => headerLinkClick(e)}
								>
									{item.Name}
								</BaseLink>
							</MenuLinkText>
						);
					})}
			</AnimateHeight>
		</>
	);
};

HamburgerMenuItem.propTypes = {
	items: PropTypes.object,
};

export default withTheme(HamburgerMenuItem);
