import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import { Spacer } from 'shared/layout';
import PropTypes from 'prop-types';
import { H1, Video, MainBody } from 'shared/components';
import {
	FullWidthColoredBackground,
	CampaignBlockContainer,
} from '../../pages/CampaignPage/CampaignPage.styles';

const CampaignVideoBlock = ({
	Heading = 'Vikten av kompoetensutveckling',
	Text = '',
	...rest
}) => {
	const newTheme = useContext(ThemeContext);

	return (
		<FullWidthColoredBackground $color={newTheme.color[1]}>
			<CampaignBlockContainer $theme={newTheme}>
				{!!Heading && (
					<H1
						word-break={{ base: 'break-word', md: 'unset' }}
						as="h2"
						color={newTheme.color[3]}
					>
						{Heading}
					</H1>
				)}
				{!!Text && (
					<>
						<Spacer spacing={{ base: 6.5, md: 8 }}>
							<MainBody as="p" color={newTheme.color[3]}>
								{Text}
							</MainBody>
						</Spacer>
						<Spacer spacing={{ base: 4, lg: 8 }} />
					</>
				)}
				<Video noPadding {...rest}></Video>
			</CampaignBlockContainer>
		</FullWidthColoredBackground>
	);
};

export default CampaignVideoBlock;

CampaignVideoBlock.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	theme: PropTypes.object,
};
