import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import Script from 'next/script';

import { MaxWidthContainer, Spacer } from 'shared/layout';
import { H1, RteContent } from 'shared/components';
import { CookieWrapper } from './CookiePage.styles';
import Head from 'next/head';

const CookiePage = ({
	Heading = '',
	CookieText = '',
	SearchEngineSettings = {},
}) => {
	const [cookieState, setCookieState] = useState(false);
	let cookieExists = false;

	useEffect(() => {
		cookieExists = document.querySelector('.CookieDeclaration');
	}, []);

	//Slow loading caused some errors in test environments, implemented continuous check for cookie-components
	useEffect(() => {
		return cookieExists
			? moveCookieElement()
			: setTimeout(() => {
					setCookieState(!cookieState);
				}, 1000);
	}, [cookieState]);

	// If we find the cookiedeclaration, it means it is placed at the bottom of the document, remove it and move into our page
	const moveCookieElement = () => {
		var referenceNode = document.querySelector('#cookie-placement');
		let sibling = cookieExists.parentNode.firstChild;
		sibling.remove();
		referenceNode.prepend(cookieExists);
	};

	return (
		<>
			<Head>
				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>

			<MaxWidthContainer narrow>
				<H1>{Heading}</H1>
				<Spacer>
					<RteContent text={CookieText} />
				</Spacer>
				<Script
					src="https://consent.cookiebot.com/8b4ee50f-210c-4217-a85d-ddfd30cd0a9a/cd.js"
					type="text/javascript"
					strategy="lazyOnload"
				/>
				<div id="CookieDeclaration"></div>
				<Spacer>
					<CookieWrapper id="cookie-placement" />
				</Spacer>
			</MaxWidthContainer>
		</>
	);
};

CookiePage.propTypes = {
	Heading: PropTypes.string,
	CookieText: PropTypes.string,
};

export default withTheme(CookiePage);
