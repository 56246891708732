import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import { MaxWidthContainer, ColumnContainer, Column } from 'shared/layout';

import { HighlightWrapper } from './ContentBlock.styles';
import ContentBlockDesktop from './ContentBlockDesktop';
import ContentBlockMobile from './ContentBlockMobile';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const ContentInformationMobile = ({ Items = [] }) => {
	return (
		<MaxWidthContainer>
			{Items.map((item, ix) => {
				return <ContentBlockMobile key={ix} {...item} />;
			})}
		</MaxWidthContainer>
	);
};

const ContentInformationDesktop = ({ Items = [], theme = {} }) => {
	const isSingle = Items.length === 1;
	const isSiteWidthUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.siteWidth})`,
		true,
	);
	return isSingle ? (
		Items.map((item, ix) => {
			return (
				<ContentBlockDesktop
					isSiteWidthUp={isSiteWidthUp}
					key={ix}
					{...item}
				/>
			);
		})
	) : (
		<MaxWidthContainer noGutters={isSiteWidthUp}>
			<ColumnContainer>
				{Items.map((item, ix) => {
					return (
						<Column key={ix} flex-basis="50%" max-width="50%">
							<ContentBlockMobile {...item} />
						</Column>
					);
				})}
			</ColumnContainer>
		</MaxWidthContainer>
	);
};

const ContentBlock = ({ Items = [], theme = {} }) => {
	if (!Items.length) return null;

	const isMobile = useMediaQuery(
		`(max-width: ${theme.breakpoints.md})`,
		true,
	);

	return (
		<HighlightWrapper>
			{isMobile ? (
				<ContentInformationMobile Items={Items} />
			) : (
				<ContentInformationDesktop Items={Items} theme={theme} />
			)}
		</HighlightWrapper>
	);
};

ContentBlock.propTypes = {
	Items: PropTypes.arrayOf(
		PropTypes.shape({
			ContentLink: PropTypes.object,
			Heading: PropTypes.string,
			Image: PropTypes.object,
			Preamble: PropTypes.string,
			ImageToLeft: PropTypes.bool,
		}),
	),
};

export default withTheme(ContentBlock);
