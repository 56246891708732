import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Container, SocialLink, CopyDialog } from './ShareComponent.styles';
import { Caption, Icon, FadeIn } from 'shared/components';

import { Box } from 'shared/layout';

const handleKeyPress = (e) => {
	e.preventDefault();

	e.key === 'Enter' && navigator.clipboard.writeText(window.location.href);
};

const ShareComponent = ({
	ShareComponentHeading = 'Dela innehållet',
	ShareComponentHideAll = false,
	ShareComponentHideFacebook = false,
	ShareComponentHideLinkedin = false,
	ShareComponentHideMail = false,
	ShareComponentHideX = false,
	ShareComponentShareUrl = false,
	mailTitle = '',
	CopiedLabel = 'Kopierat',
	theme = {},
}) => {
	if (
		ShareComponentHideAll ||
		(ShareComponentHideFacebook &&
			ShareComponentHideLinkedin &&
			ShareComponentHideMail &&
			ShareComponentHideX &&
			ShareComponentShareUrl)
	)
		return null;
	const [showCopyDialog, setShowCopyDialog] = useState(false);
	const [render, setRender] = useState(false);
	useEffect(() => {
		setRender(true);
	}, []);

	const openDialog = () => {
		setShowCopyDialog(true);

		setTimeout(() => {
			setShowCopyDialog(false);
		}, 2000);
	};
	const ShareList = () => {
		return (
			<Box
				display="flex"
				padding-top={{ base: theme.spacings[4], lg: 0 }}
				align-items="center"
				gap={theme.spacings.default}
			>
				{!ShareComponentHideFacebook && render && (
					<SocialLink
						target="_blank"
						href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
						aria-label={`Dela sida på Facebook`}
					>
						<Icon font-size="3rem" name="facebook" />
					</SocialLink>
				)}
				{!ShareComponentHideX && render && (
					<SocialLink
						target="_blank"
						href={`https://x.com/share?url=${window.location.href}`}
						aria-label={`Dela sida på X`}
					>
						<Icon font-size="3rem" name="x" />
					</SocialLink>
				)}

				{!ShareComponentHideLinkedin && render && (
					<SocialLink
						target="_blank"
						href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
						aria-label={`Dela sida på Linkedin`}
					>
						<Icon font-size="3rem" name="linkedIn" />
					</SocialLink>
				)}
				{!ShareComponentHideMail && render && (
					<SocialLink
						target="_blank"
						href={`mailto:?body=${window.location.href}&subject=${mailTitle}`}
						aria-label={`Dela sida via Mail`}
					>
						<Icon font-size="3rem" name="mail" />
					</SocialLink>
				)}
				{!ShareComponentShareUrl && render && (
					<>
						<SocialLink
							href={window.location.href}
							margin="0"
							aria-label={`Kopiera sidans url`}
							onClick={(e) => {
								e.preventDefault();
								openDialog();
								navigator.clipboard.writeText(
									window.location.href,
								);
							}}
							onKeyPress={(e) => handleKeyPress(e)}
						>
							<Icon font-size="3rem" name="link" />
						</SocialLink>
						{showCopyDialog && (
							<FadeIn display="flex" justify-content="center">
								<CopyDialog>
									<Caption textTransform="none">
										{CopiedLabel}
									</Caption>
								</CopyDialog>
							</FadeIn>
						)}
					</>
				)}
			</Box>
		);
	};
	return (
		<Container
			padding={`${theme.spacings[7]}`}
			align-items="center"
			justify-content={{ base: 'space-around', lg: 'auto' }}
			flex-direction={{ base: 'column', lg: 'row' }}
		>
			<Box
				max-width={theme.spacings[22]}
				margin-right={theme.spacings[8]}
			>
				<Caption text-align="center">{ShareComponentHeading}</Caption>
			</Box>
			<ShareList />
		</Container>
	);
};

export default withTheme(ShareComponent);
