import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { BaseLink } from 'shared/components';

export const BreadcrumbLink = styled(BaseLink)`
	display: inline-flex;
	align-items: center;
	color: ${({ theme, color }) => color ?? theme.colors.secondary};
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`;

export const BreadCrumbsWrapper = withResponsive(styled.div`
	display: flex;
	flex-direction: row;
	font-size: ${({ theme }) => theme.typography.fontSizes.mobile.body};
`);
