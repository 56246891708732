import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';
import { CampaignBlockContainer } from '../../pages/CampaignPage/CampaignPage.styles';

export const CampaignContentBlockContainer = styled(CampaignBlockContainer)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: ${({ $theme }) => $theme.size.large};
	align-items: center;
	min-height: ${({ $theme }) =>
		`${
			(parseFloat($theme.size.siteWidth) -
				parseFloat($theme.size.large)) /
				2 +
			parseFloat($theme.size.large) +
			parseFloat($theme.size.large)
		}rem`};

	${({ $rightText }) =>
		$rightText &&
		`
    & > :first-child {
      order: 2;
    }

    & > :last-child {
      order: 1;
    }
  `}
`;

export const CampaignContentBlockText = withResponsive(styled.div`
	margin: ${({ $theme }) => $theme.size.medium} 0;
`);

export const CampaignContentBlockImage = withResponsive(styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`);
