import React from 'react';
import PropTypes from 'prop-types';
import { componentRegistry } from '../../bin/componentRegistry';

export const RegistryComponent = ({ alias, ...props }) => {
	const isInDevelopmentEnvironment = process.env.NODE_ENV === 'development';

	const capitalized = alias.replace(/^./, (char) => char.toUpperCase());

	if (!alias) {
		return <NoComponentFound {...props} />;
	}

	const ContentTypeComponent = componentRegistry[capitalized];

	if (!ContentTypeComponent && isInDevelopmentEnvironment) {
		return <NoComponentFound alias={alias} {...props} />;
	}

	if (!ContentTypeComponent) {
		return null;
	}

	return <ContentTypeComponent {...props} />;
};

RegistryComponent.propTypes = {
	alias: PropTypes.string,
};

// TODO: Extract to own file
const NoComponentFound = ({ alias }) => {
	return (
		<section
			style={{
				boxSizing: 'border-box',
				border: '1px solid #f5f5f5',
				backgroundImage:
					'linear-gradient(45deg, #ffffff 25%, #f5f5f5 25%, #f5f5f5 50%, #ffffff 50%, #ffffff 75%, #f5f5f5 75%, #f5f5f5 100%)',
				backgroundSize: '56.57px 56.57px',
				padding: '1rem',
				color: '#a5a5a5',
				textAlign: 'center',
				fontWeight: '600',
			}}
		>
			<p>
				Could not find component for:{' '}
				<span style={{ color: 'black' }}>{alias}</span>
			</p>
		</section>
	);
};
NoComponentFound.propTypes = {
	contentType: PropTypes.string,
};
