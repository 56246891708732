import React from 'react';
import { BaseLink, Icon } from 'shared/components';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

const LanguageLink = ({ children, theme, color = '', ...props }) => {
	return (
		<BaseLink
			className="white-tab"
			font-weight={theme.typography.fontWeights.medium}
			display="inline-flex"
			align-items="center"
			lang="en"
			width="100%"
			font-size={{
				base: theme.typography.fontSizes.desktop.caption,
				md: theme.typography.fontSizes.desktop.body2,
			}}
			color={theme.colors.interaction}
			{...props}
		>
			<Icon
				height={{ base: '1.85rem', md: '2.45rem' }}
				width={{ base: '1.85rem', md: '2.45rem' }}
				color={color}
				name="language"
				margin={` 0 ${theme.spacings[2]} 0 0`}
			/>
			{children}
		</BaseLink>
	);
};
export default withTheme(LanguageLink);

LanguageLink.propTypes = {
	href: PropTypes.string,
	children: PropTypes.node,
};
