import React from 'react';
import { MaxWidthContainer, Spacer } from 'shared/layout';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import {
	EventInfoWrapper,
	EventInfoContainer,
	EventButtonInfoWrapper,
	EventTextInfoWrapper,
	EventInfoContainerOverlay,
} from './EventInfo.styles';

import { H2, MainBody, LinkButton } from 'shared/components';

const EventInfo = ({
	EventInfoHeading = '',
	EventDate = '',
	EventTime = '',
	EventLocation = '',
	SignUpLink = '',
	BackgroundImageEventInfo = {},
}) => {
	const Time = new Date(EventTime);
	const formattedTime = Time.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	return (
		<>
			<MaxWidthContainer noGutters>
				<EventInfoWrapper
					width="100%"
					imageSrc={BackgroundImageEventInfo}
				>
					<EventInfoContainer
						flex-direction="column"
						imageSrc={BackgroundImageEventInfo}
					>
						<EventInfoContainerOverlay
							height="100%"
							width="100%"
							imageSrc={BackgroundImageEventInfo}
						>
							<EventTextInfoWrapper>
								<H2 font-size="24px">{EventInfoHeading}</H2>
								<Spacer spacing={3.5}>
									<MainBody>
										<strong>Datum: </strong>
										{EventDate}
									</MainBody>
								</Spacer>
								<Spacer spacing={3.5}>
									<MainBody>
										<strong>Tid: </strong>
										{formattedTime}
									</MainBody>
								</Spacer>
								<Spacer spacing={3.5}>
									<MainBody>
										<strong>Plats: </strong>
										{EventLocation}
									</MainBody>
								</Spacer>
							</EventTextInfoWrapper>
							<EventButtonInfoWrapper>
								{!!SignUpLink && (
									<LinkButton
										target={SignUpLink?.Target}
										href={SignUpLink?.Url}
										width="100%"
										max-width="none"
									>
										Anmäl dig här
									</LinkButton>
								)}
							</EventButtonInfoWrapper>
						</EventInfoContainerOverlay>
					</EventInfoContainer>
				</EventInfoWrapper>
			</MaxWidthContainer>
		</>
	);
};

EventInfo.proptypes = {
	EventInfoHeading: PropTypes.string,
	EventDate: PropTypes.string,
	EventTime: PropTypes.string,
	EventLocation: PropTypes.string,
	SignUpLink: PropTypes.object,
	BackgroundImageEventInfo: PropTypes.string,
};

export default withTheme(EventInfo);
