import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/campaignThemes';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

import { Flex, Column, ColumnContainer, Spacer } from 'shared/layout';

import { RteContent, FillerImage, Headline, Icon } from 'shared/components';

import { StyledTextWrapper, ScrollButton } from './CampaignHeroBlock.styles';

import { MapTheme } from 'shared/helpers';

import { themeVariants } from 'shared/consts';

import { ScreenReaderOnly } from '../../pages/CampaignPage/CampaignPage.styles';

const scrollToNext = (id = '') => {
	const nextEl = document.querySelector(`#${id}`)?.parentNode?.nextSibling;
	nextEl.scrollIntoView({ behavior: 'smooth' });
};

const CampaignHeroBlock = ({
	theme = {},
	HeroImageDesktop = {},
	HeroImagePhone = {},
	Heading = '',
	HideTopArrow = false,
	HideHeading = false,
	CampaignArrowTopText = '',
}) => {
	const newTheme = useContext(ThemeContext);
	const isMediumUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.md})`,
		true,
	);
	const heroId = 'campaign-hero';
	const imageUrlExists = isMediumUp
		? !!HeroImageDesktop?.Url
		: !!HeroImagePhone?.Url;
	return (
		<MapTheme theme={themeVariants.FROST}>
			<Flex
				justify-content={{ base: 'flex-end', md: 'space-between' }}
				position="relative"
				min-height={{ md: '40vw' }}
				flex-direction="column"
				id={heroId}
				background={newTheme.color[1]}
			>
				{imageUrlExists && (
					<FillerImage
						priority={true}
						src={
							isMediumUp
								? HeroImageDesktop?.Url
								: HeroImagePhone?.Url
						}
					/>
				)}
				<ScreenReaderOnly $inactive={!HideHeading}>
					<StyledTextWrapper $theme={newTheme} width="100%">
						<ColumnContainer
							max-width={theme.spacings.siteWidth}
							margin="0 auto"
							width="100%"
							padding={{
								base: `${theme.spacings[4]} ${theme.spacings[6]}`,
								md: `${theme.spacings[8]} ${theme.spacings[4]}`,
								lg: `${theme.spacings[12]} 0`,
							}}
						>
							<Column
								padding={{
									base: 0,
									md: `0 ${theme.spacings[4]} 0 0`,
								}}
								flex-basis={{ base: '100%', lg: '65%' }}
							>
								<Headline noColor>
									<RteContent
										headLine
										color={newTheme.color[1]}
										text={Heading}
									/>
								</Headline>
							</Column>
						</ColumnContainer>
					</StyledTextWrapper>
				</ScreenReaderOnly>
				<StyledTextWrapper
					$theme={newTheme}
					width="100%"
					style={{
						visibility: HideHeading ? 'hidden' : 'visible',
					}}
				>
					<ColumnContainer
						max-width={theme.spacings.siteWidth}
						margin="0 auto"
						width="100%"
						padding={{
							base: `${theme.spacings[4]} ${theme.spacings[6]}`,
							md: `${theme.spacings[8]} ${theme.spacings[4]}`,
							lg: `${theme.spacings[12]} 0`,
						}}
					>
						<Column
							padding={{
								base: 0,
								md: `0 ${theme.spacings[4]} 0 0`,
							}}
							flex-basis={{ base: '100%', lg: '65%' }}
						>
							<Headline noColor>
								<RteContent
									forceh2
									color={newTheme.color[1]}
									text={Heading}
								/>
							</Headline>
						</Column>
					</ColumnContainer>
				</StyledTextWrapper>
				<Spacer spacing={{ base: 8, md: 12 }}>
					{!HideTopArrow && (
						<Flex
							width="100%"
							align-items="center"
							justify-content="center"
							z-index={theme.zIndexes.aboveBase}
						>
							<ScrollButton
								onClick={() => scrollToNext(heroId)}
								title={CampaignArrowTopText}
								aria-label={CampaignArrowTopText}
								bgcolor={newTheme.color[2]}
							>
								<Icon
									name="arrow-down"
									color={theme.colors.white}
								/>
							</ScrollButton>
						</Flex>
					)}
				</Spacer>
			</Flex>
		</MapTheme>
	);
};

CampaignHeroBlock.propTypes = {
	HeroImageDesktop: PropTypes.object,
	HeroImagePhone: PropTypes.object,
	Heading: PropTypes.string,
	theme: PropTypes.object,
};

export default withTheme(CampaignHeroBlock);
