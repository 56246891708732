import React from 'react';
import PropTypes from 'prop-types';
import { MaxWidthContainer, Spacer, Flex } from 'shared/layout';
import Head from 'next/head';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { concatenateIfMany } from 'shared/helpers';

import {
	H1,
	MainBody,
	Breadcrumbs,
	RteContent,
	BlockList,
	Contact,
	ShareComponent,
	Caption,
	Video,
	EmphasizedContent,
	MainBody2,
	SearchResults,
} from 'shared/components';
import { withTheme } from 'styled-components';
import { PuffBlock } from 'shared/blocks';
import { TagListPageMeta } from './TagListPageMeta';
import { useRouter } from 'next/router';

const TagListPage = ({
	Heading = '',
	Preamble = '',
	BottomBlocks = [],
	MainBlocks = [],
	MainContent = '',
	Contacts = [],
	BreadcrumbLinks = [],
	ShareComponent: shareComponentData = {},
	EmphasizedHeading = '',
	EmphasizedIsOpen = false,
	EmphasizedMainContent = '',
	Metadata = [],
	Categories = [],
	theme = {},
	VideoLink = '',
	VideoType = 'youtube',
	VideoText = '',
	hasAcceptedCookies = true,
	RelatedContent = {},
	LastPublishedDate = '',
	SearchResult: { SearchHits = [] } = {},
}) => {
	const isMobile = useMediaQuery(
		`(max-width: ${theme.breakpoints.md})`,
		true,
	);
	const { asPath } = useRouter();
	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							TagListPageMeta({ ...Metadata, asPath }),
						),
					}}
				/>
			</Head>
			<MaxWidthContainer noGutters>
				<Breadcrumbs breadcrumbLinks={BreadcrumbLinks}></Breadcrumbs>
			</MaxWidthContainer>
			<MaxWidthContainer
				id="information-page-top"
				noGutters
				display={{ base: 'flex', lg: 'block' }}
				flex-direction={{ base: 'column-reverse', lg: 'column' }}
			>
				{!isMobile && (
					<ShareComponent
						{...shareComponentData}
						mailTitle={Heading}
					/>
				)}
				<MaxWidthContainer narrow>
					<Spacer spacing={{ base: 8, md: 10 }}>
						<H1>{Heading}</H1>
					</Spacer>

					{(!!Categories?.length || !!LastPublishedDate) && (
						<Spacer spacing={{ base: 6, md: 8 }}>
							<Flex flex-direction="row" align-items="center">
								{!!Categories?.length && (
									<Caption
										margin-right={theme.spacings[4]}
										color={theme.colors.pink}
									>
										{concatenateIfMany(Categories)}
									</Caption>
								)}
								{!!LastPublishedDate && (
									<MainBody2 textTransform="none">
										{LastPublishedDate}
									</MainBody2>
								)}
							</Flex>
						</Spacer>
					)}
					{!!Preamble.length && (
						<Spacer spacing={{ base: 4, md: 6 }}>
							<MainBody color={theme?.colors?.primary}>
								{Preamble}
							</MainBody>
						</Spacer>
					)}
					<EmphasizedContent
						EmphasizedHeading={EmphasizedHeading}
						EmphasizedIsOpen={EmphasizedIsOpen}
						EmphasizedMainContent={EmphasizedMainContent}
					/>
					{!!MainContent.length && (
						<Spacer spacing={{ base: 6 }}>
							<RteContent text={MainContent} />
						</Spacer>
					)}
					{!!Contacts?.length &&
						!MainBlocks &&
						Contacts.map((item, ix) => {
							if (ix === 0)
								return (
									<Spacer
										key={ix}
										spacing={{ base: 10, md: 14 }}
									>
										<Contact {...item} />
									</Spacer>
								);
							return (
								<Spacer key={ix} spacing={10}>
									<Contact {...item} />
								</Spacer>
							);
						})}
					{!!VideoLink.length && hasAcceptedCookies && (
						<Spacer spacing={8}>
							<Video
								VideoType={VideoType}
								VideoLink={VideoLink}
								VideoText={VideoText}
							/>
						</Spacer>
					)}
				</MaxWidthContainer>
			</MaxWidthContainer>
			{!!MainBlocks.length && <BlockList blocks={MainBlocks} />}
			{!!Contacts?.length && MainBlocks && (
				<MaxWidthContainer narrow>
					{Contacts.map((item, ix) => {
						if (ix === 0)
							return (
								<Spacer key={ix} spacing={{ base: 10, md: 14 }}>
									<Contact {...item} />
								</Spacer>
							);
						return (
							<Spacer key={ix} spacing={10}>
								<Contact {...item} />
							</Spacer>
						);
					})}
				</MaxWidthContainer>
			)}
			{RelatedContent && !!Object.keys(RelatedContent).length && (
				<Spacer spacing={8}>
					<PuffBlock {...{ Categories }} {...RelatedContent} />
				</Spacer>
			)}
			{!!BottomBlocks.length && <BlockList blocks={BottomBlocks} />}
			<MaxWidthContainer>
				{!!SearchHits.length && (
					<Spacer spacing={{ base: 8 }}>
						<SearchResults {...{ SearchHits }} />
					</Spacer>
				)}
			</MaxWidthContainer>
			{isMobile && (
				<Flex>
					<ShareComponent
						{...shareComponentData}
						mailTitle={Heading}
					/>
				</Flex>
			)}
		</>
	);
};

export default withTheme(TagListPage);

TagListPage.propTypes = {
	Heading: PropTypes.string,
	Contacts: PropTypes.array,
	BottomBlocks: PropTypes.array,
	MainContent: PropTypes.string,
	Preamble: PropTypes.string,
	Categories: PropTypes.array,
	EmphasizedHeading: PropTypes.string,
	EmphasizedIsOpen: PropTypes.bool,
	EmphasizedMainContent: PropTypes.string,
	RelatedContent: PropTypes.instanceOf(PuffBlock),
	LastPublishedDate: PropTypes.string,
	ContentList: PropTypes.array,
};
