import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const PageButton = withResponsive(styled.button`
	width: ${({ theme }) => theme.spacings[8]};
	height: ${({ theme }) => theme.spacings[8]};
	background-color: white;
	color: ${({ theme }) => theme.colors.primary};
	border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
	display: flex;
	justify-content: center;
	font-size: ${({ theme }) =>
		theme.typography.fontSizes.desktop.captionSmall};
	align-items: center;
	transition: 0.2s ease;

	${({ isNavButton }) =>
		isNavButton &&
		css`
			background-color: ${({ theme }) => theme.colors.secondary};
			color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.secondary};

			&:hover {
				color: ${({ theme }) => theme.colors.secondary};
				background-color: ${({ theme }) => theme.colors.white};
			}
		`}

	${({ isActive }) =>
		isActive &&
		css`
			background-color: ${({ theme }) => theme.colors.primary};
			color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.primary};

			&:hover {
				color: ${({ theme }) => theme.colors.primary};
				background-color: ${({ theme }) => theme.colors.white};
			}
		`}

	&:disabled {
		background-color: transparent;
		border-color: ${({ theme }) => theme.colors.text};
		color: ${({ theme }) => theme.colors.text};
		opacity: 0.31;
	}
`);

export const DotsStyle = styled.li`
	&& {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin: 0;
	}
`;
