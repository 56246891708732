import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Spacer } from 'shared/layout';
import { MainBody, Icon } from 'shared/components';
import { BreadcrumbLink, BreadCrumbsWrapper } from './Breadcrumbs.styles';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

export const DesktopBreadcrumbs = ({ breadcrumbLinks, theme }) => {
	return breadcrumbLinks.map((item, ix) => {
		return ix === breadcrumbLinks.length - 1 ? (
			<MainBody
				font-size={{
					lg: theme.typography.fontSizes.mobile.body,
				}}
				key={ix}
			>
				{item.Name}
			</MainBody>
		) : (
			<BreadcrumbLink
				key={ix}
				href={item.Url}
				color={theme.colors.secondary}
			>
				{item.Name}
				<Icon
					color={theme.colors.textBlack}
					name="arrow-right"
					margin={{
						base: `0 ${theme.spacings[3]} 0 0`,
						md: `0 ${theme.spacings[3]}`,
					}}
					font-size={theme.spacings[3]}
				/>
			</BreadcrumbLink>
		);
	});
};

export const MobileBreadcrumbs = ({ breadcrumbLinks, theme }) => {
	return breadcrumbLinks.map((item, ix) => {
		if (!item?.IsDirectParent) return null;
		return (
			<BreadcrumbLink
				key={ix}
				href={item.Url}
				color={theme.colors.secondary}
			>
				<Icon
					color={theme.colors.textBlack}
					name="arrow-left"
					font-size={theme.spacings[3]}
					margin={`0 ${theme.spacings[3]}`}
				/>
				{item.Name}
			</BreadcrumbLink>
		);
	});
};
const Breadcrumbs = ({ breadcrumbLinks = [], theme = {} }) => {
	if (!breadcrumbLinks.length) return null;
	const isLargeUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.lg})`,
		true,
	);

	return (
		<Spacer spacing={{ base: 6, md: 8 }}>
			<BreadCrumbsWrapper>
				{isLargeUp ? (
					<DesktopBreadcrumbs {...{ breadcrumbLinks, theme }} />
				) : (
					<MobileBreadcrumbs {...{ breadcrumbLinks, theme }} />
				)}
			</BreadCrumbsWrapper>
		</Spacer>
	);
};

export default withTheme(Breadcrumbs);

Breadcrumbs.propTypes = {
	breadcrumbLinks: PropTypes.array,
};
