import React from 'react';
import PropTypes from 'prop-types';
import {
	MainBody,
	UnorderedList,
	ListItem,
	Caption,
	H3,
	ListLink,
	RteContent,
} from 'shared/components';
import { Spacer, Flex, Box } from 'shared/layout';
import { withTheme } from 'styled-components';
import { concatenateIfMany } from 'shared/helpers';

const SearchResult = withTheme(
	({
		Categories = [],
		Heading = '',
		Description = '',
		Url = '',
		categoryColor = '',
		firstItem = false,
		Date: ResultDate = '',
		dateColor = '',
		DocumentType = '',
		theme = {},
	}) => {
		const dateTime = new Date(ResultDate);
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};

		const hasCategory = !!Categories.length;
		const hasDocumentType = !!DocumentType.length;

		const dateText = dateTime.toLocaleDateString('sv-se', options);

		return (
			<UnorderedList>
				<ListItem padding="0" margin="0" list-style-type="none">
					<ListLink firstItem={firstItem} passHref href={Url}>
						<Flex>
							{!!dateText.length && (
								<Spacer spacing={{ base: 2, md: 0 }}>
									<Caption
										textTransform="n"
										font-weight="normal"
										color={dateColor}
										margin-right="1.5rem"
									>
										{dateText}
									</Caption>
								</Spacer>
							)}

							{(hasCategory || hasDocumentType) && (
								<Spacer spacing={{ base: 2, md: 0 }}>
									<Flex flex-direction="row">
										{hasCategory && (
											<Caption
												margin-right={
													hasDocumentType && '1.5rem'
												}
												color={categoryColor}
											>
												{concatenateIfMany(Categories)}
											</Caption>
										)}
										{hasDocumentType && (
											<Caption
												color={theme.colors.secondary}
											>
												{DocumentType}
											</Caption>
										)}
									</Flex>
								</Spacer>
							)}
						</Flex>
						{!!Heading.length && (
							<Spacer spacing={2}>
								<H3>{Heading}</H3>
							</Spacer>
						)}
						<Spacer spacing={4}>
							<MainBody>{Description}</MainBody>
						</Spacer>
					</ListLink>
				</ListItem>
			</UnorderedList>
		);
	},
);

const SearchResults = ({
	SearchHits = [],
	theme = {},
	NoHitsHeading = '',
	NoHitsText = '',
}) => {
	const NoHits = () => (
		<Box>
			<Box border-top={`1px solid ${theme.colors.borderGrey}`} />
			{!!NoHitsHeading?.length && (
				<Spacer spacing={8}>
					<H3
						font-weight={theme.typography.fontWeights.bold}
						color={theme.colors.primary}
					>
						{NoHitsHeading}
					</H3>
				</Spacer>
			)}
			{!!NoHitsText?.length && (
				<Spacer spacing={8}>
					<RteContent text={NoHitsText || ''} />
				</Spacer>
			)}
		</Box>
	);

	return SearchHits?.length ? (
		SearchHits.map((item, i) => (
			<SearchResult
				dateColor={theme.colors.text}
				categoryColor={theme.colors.pink}
				key={`search-result-${i}`}
				firstItem={i === 0}
				{...item}
			/>
		))
	) : (
		<NoHits />
	);
};
export default withTheme(SearchResults);
SearchResults.propTypes = {
	SearchHits: PropTypes.array,
	theme: PropTypes.object,
	NoHitsRichText: PropTypes.string,
	NoHitsText: PropTypes.string,
};
