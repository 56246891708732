import styled, { css } from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const Container = withResponsive(styled.div`
	margin-top: ${({ theme }) => theme.spacings[6]};
	border: ${({ theme }) => `1px solid ${theme.colors.borderGrey}`};
	border-top: ${({ theme }) =>
		`${theme.spacings[1.5]} ${theme.colors.bg} solid`};
	box-shadow: ${({ theme }) =>
		`${theme.boxShadows.contact} ${theme.colors.shadowGrey}`};
	padding: ${({ theme }) => theme.spacings[4]};

	i {
		transition: ${({ theme }) => theme.transitions.default};
		transform-origin: center;
		transform: ${({ isOpen }) =>
			isOpen ? 'rotateZ(0)' : 'rotateZ(90deg)'};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-top: ${({ theme }) => theme.spacings[12]};
	}
`);

export const ShadowBox = withResponsive(styled.div`
	padding-top: ${({ theme }) => theme.spacings[2]};
	max-width: none;
	position: relative;
	${({ isOpen }) =>
		!isOpen &&
		css`
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				height: ${({ theme }) => theme.spacings[6]};
				left: 0;
				right: 0;
				box-shadow: ${({ theme }) =>
					`${theme.boxShadows.emphasizedInset} ${theme.colors.white}`};
			}
		`}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		max-width: 80%;
	}
`);
