import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

export const StyledTextWrapper = withResponsive(styled.div`
	word-break: break-word;
	overflow-wrap: anywhere;
	background: ${({ theme, $theme }) =>
		$theme ? $theme.color[3] : theme.colors.primary};

	margin-top: ${({ theme }) => theme.spacings[64]};

	flex-direction: column;
	color: ${({ theme }) => theme.colors.white};
	z-index: ${({ theme }) => theme.zIndexes.base};
	min-height: 50%;
	width: auto;
	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		word-break: unset;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		background: ${({ theme, $theme }) =>
			$theme
				? `linear-gradient(to right, ${$theme.color[3]} 70%, transparent 0%)`
				: `linear-gradient(to right, ${theme.colors.primary} 70%, transparent 0%)`};
		height: fit-content;
	}

	& .highlight {
		color: ${({ $theme }) => $theme.color[2]};
	}
`);

export const ScrollButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${({ theme }) => theme.spacings[15]};
	width: ${({ theme }) => theme.spacings[15]};
	color: ${({ theme }) => theme.colors.white};
	z-index: ${({ theme }) => theme.zIndexes.aboveBase};
	background-color: ${({ bgcolor }) => bgcolor};
	margin-bottom: ${({ theme, smallMargin }) =>
		smallMargin ? theme.spacings[3] : theme.spacings[8]};
	font-size: ${({ theme }) => theme.spacings[12]};
`;
