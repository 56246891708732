import React from 'react';
import { withTheme } from 'styled-components';

import { ColumnContainer, Column, Spacer, Box } from 'shared/layout';
import { buttonVariants } from 'shared/consts';

import { ImageComponent, RteContent, H2, LinkButton } from 'shared/components';
import { DesktopBoxWrapper } from './ContentBlock.styles';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const ContentBlockDesktop = ({
	theme = {},
	Heading = '',
	ContentLink = {},
	Preamble = '',
	Image = {},
	ImageToLeft = false,
}) => {
	const isSiteWidthUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.siteWidth})`,
		true,
	);
	return (
		<Box background={theme.colors.backgroundGrey}>
			<ColumnContainer
				noGutters
				align-items={isSiteWidthUp ? 'center' : 'stretch'}
				flex-direction={ImageToLeft ? 'row-reverse' : 'row'}
			>
				<Column
					noGutters={isSiteWidthUp}
					flex-basis="50%"
					display="flex"
					justify-content={ImageToLeft ? 'flex-start' : 'flex-end'}
				>
					<DesktopBoxWrapper left={ImageToLeft}>
						<Spacer spacing={4}>
							<H2>
								<RteContent text={Heading} />
							</H2>
						</Spacer>
						<Spacer spacing={4}>
							<RteContent text={Preamble} />
						</Spacer>
						<Spacer spacing={10}>
							<LinkButton
								target={ContentLink?.Target}
								href={ContentLink?.Url}
								variant={buttonVariants.GHOST}
							>
								{ContentLink?.Name}
							</LinkButton>
						</Spacer>
					</DesktopBoxWrapper>
				</Column>
				<Column
					noGutters
					flex-basis="50%"
					display="flex"
					justify-content={ImageToLeft ? 'flex-end' : ''}
				>
					<ImageComponent
						src={Image?.Url}
						width={Image?.Width}
						height={Image?.Height}
						alt={Image?.AltText}
						objectFit="cover"
					/>
				</Column>
			</ColumnContainer>
		</Box>
	);
};

export default withTheme(ContentBlockDesktop);
