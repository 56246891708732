import React from 'react';

export const ThemeContext = React.createContext();

export const themes = {
	skyWhite: {
		get color() {
			return {
				...themes.commonColors,
				1: themes.commonColors.navyBlue,
				2: themes.commonColors.skyBlue,
				3: themes.commonColors.white,
				4: themes.commonColors.vanillaOrange,
			};
		},
		get size() {
			return themes.commonSizes;
		},
	},
	plumOrange: {
		get color() {
			return {
				...themes.commonColors,
				1: themes.commonColors.plumPurple,
				2: themes.commonColors.orange,
				3: themes.commonColors.powderPurple,
				4: themes.commonColors.rosePurple,
			};
		},
		get size() {
			return themes.commonSizes;
		},
	},
	nightBlue: {
		get color() {
			return {
				...themes.commonColors,
				1: themes.commonColors.navyBlue,
				2: themes.commonColors.tcoBlue,
				3: themes.commonColors.powderPurple,
				4: themes.commonColors.skyBlue,
			};
		},
		get size() {
			return themes.commonSizes;
		},
	},
	nightCerise: {
		get color() {
			return {
				...themes.commonColors,
				1: themes.commonColors.navyBlue,
				2: themes.commonColors.cerisePurple,
				3: themes.commonColors.frostBlue,
				4: themes.commonColors.powderPurple,
			};
		},
		get size() {
			return themes.commonSizes;
		},
	},
	nightFire: {
		get color() {
			return {
				...themes.commonColors,
				1: themes.commonColors.navyBlue,
				2: themes.commonColors.fireOrange,
				3: themes.commonColors.frostBlue,
				4: themes.commonColors.tcoBlue,
			};
		},
		get size() {
			return themes.commonSizes;
		},
	},
	commonColors: {
		white: 'rgb(255, 255, 255)',
		black: 'rgb(0, 0, 0)',
		backgroundGrey: 'rgb(251, 251, 251)',
		tcoBlue: 'rgb(0, 158, 195)',
		navyBlue: 'rgb(0, 66, 88)',
		frostBlue: 'rgb(228, 242, 231)',
		powderPurple: 'rgb(252, 223, 235)',
		rosePurple: 'rgb(240, 166, 202)',
		plumPurple: 'rgb(75, 20, 70)',
		orange: 'rgb(233, 88, 30)',
		fireOrange: 'rgb(255, 169, 0)',
		vanillaOrange: 'rgb(246, 249, 226)',
		cerisePurple: 'rgb(223, 0, 111)',
		skyBlue: 'rgb(137, 205, 199)',
	},
	commonSizes: {
		small: '1rem',
		medium: '2rem',
		large: '4rem',
		siteWidth: '70rem',
	},
};
