import React from 'react';
import { Box } from '../../layout';
import Icon from '../Icon';

export const DropDownButton = ({ isOpen, ...props }) => {
	return (
		<Box
			display="flex"
			width="52px"
			height="52px"
			justify-ontent="center"
			align-items="center"
			{...props}
		>
			<Icon name={isOpen ? 'arrow-up' : 'arrow-down'} size="32px" />
		</Box>
	);
};
