import React from 'react';
import { BaseLink, Icon } from 'shared/components';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

const ArrowLink = ({ children, theme, color = '', ...props }) => {
	return (
		<BaseLink
			font-weight={theme.typography.fontWeights.medium}
			display="inline-flex"
			align-items="center"
			color={color}
			{...props}
		>
			{children}
			<Icon
				color={color}
				name="arrow-right"
				margin={`0 0 0 ${theme.spacings[2]}`}
			/>
		</BaseLink>
	);
};
export default withTheme(ArrowLink);

ArrowLink.propTypes = {
	href: PropTypes.string,
	children: PropTypes.node,
};
