import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { withTheme } from 'styled-components';
import { MaxWidthContainer, Spacer } from 'shared/layout';
import { Breadcrumbs, RteContent, H1 } from 'shared/components';
import { useSearchParam } from 'react-use';

const ThankYouPage = ({
	theme = {},
	SubscribedText = '',
	UnsubscribedText = '',
	SubscribedHeading = '',
	UnsubscribedHeading = '',
	SearchEngineSettings = {},
	UpdateHeading = '',
	UpdateText = '',
	BreadcrumbLinks = {},
}) => {
	const isDelete = useSearchParam('unsubscribe');
	const isUpdate = useSearchParam('update');

	/* Chose correct heading based on queryparam */
	const heading = isDelete
		? UnsubscribedHeading
		: isUpdate
			? UpdateHeading
			: SubscribedHeading;

	/* Chose correct text based on queryparam */
	const text = isDelete
		? UnsubscribedText
		: isUpdate
			? UpdateText
			: SubscribedText;
	return (
		<>
			<Head>
				{!!SearchEngineSettings.AddNoIndex && (
					<meta content="noindex, nofollow" name="ROBOTS" />
				)}
			</Head>
			<MaxWidthContainer>
				<Breadcrumbs breadcrumbLinks={BreadcrumbLinks}></Breadcrumbs>
				<Spacer spacing={8}>
					<H1>{heading}</H1>
				</Spacer>

				<Spacer spacing={8}>
					<RteContent color={theme?.colors?.primary} text={text} />
				</Spacer>
			</MaxWidthContainer>
		</>
	);
};

ThankYouPage.propTypes = {
	BreadcrumbLinks: PropTypes.arrayOf(
		PropTypes.shape({
			CurrentPage: PropTypes.bool,
			IsDirectParent: PropTypes.bool,
			Name: PropTypes.string,
			Url: PropTypes.string,
		}),
	),
	UnsubscribedHeading: PropTypes.string,
	SubscribedHeading: PropTypes.string,
	SubscribedText: PropTypes.string,
	UnsubscribedText: PropTypes.string,
	UpdateHeading: PropTypes.string,
	UpdateText: PropTypes.string,
};

export default withTheme(ThankYouPage);
