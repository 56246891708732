import PropTypes from 'prop-types';
import styled from 'styled-components';
import withResponsive from 'shared/helpers/withResponsive';

const MaxWidthContainer = styled.div`
	width: 100%;
	max-width: ${({ theme, narrow, noMaxWidth }) =>
		noMaxWidth
			? 'unset'
			: narrow
				? theme.spacings.siteWidthNarrow
				: theme.spacings.siteWidth};
	margin: 0 auto;
	padding: ${({ theme, noPadding }) =>
		noPadding ? '0' : theme.spacings.siteMarginMobile};
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, noGutters }) =>
			noGutters ? undefined : `0 ${theme.spacings.siteMarginTablet}`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme, noGutters, narrow }) =>
			noGutters
				? undefined
				: narrow
					? `0 ${theme.spacings[60]} 0 ${theme.spacings.siteMargin}`
					: `0 ${theme.spacings.siteMargin}`};
	}
`;

MaxWidthContainer.propTypes = {
	narrow: PropTypes.bool,
	noGutters: PropTypes.bool,
};

export default withResponsive(MaxWidthContainer);
