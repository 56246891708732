import React from 'react';
import { MaxWidthContainer, Spacer } from 'shared/layout';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { H1, RteContent, Video } from 'shared/components';

const VideoBlock = ({
	Heading = 'Vikten av kompoetensutveckling',
	Preamble = '',
	theme = {},
	...rest
}) => {
	const isSiteWidthUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.siteWidth})`,
		true,
	);
	return (
		<MaxWidthContainer noGutters={isSiteWidthUp}>
			{!!Heading && <H1 as="h2">{Heading}</H1>}
			{!!Preamble && (
				<Spacer spacing={{ base: 6.5, md: 8 }}>
					<RteContent text={Preamble} />
				</Spacer>
			)}
			<Spacer
				spacing={(!!Heading || !!Preamble) && { base: 6.5, md: 12 }}
			>
				<Video {...rest}></Video>
			</Spacer>
		</MaxWidthContainer>
	);
};

export default withTheme(VideoBlock);

VideoBlock.propTypes = {
	Heading: PropTypes.string,
	Preamble: PropTypes.string,
	theme: PropTypes.object,
};
