import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { StyledLink } from './BaseLink.styles';

const BaseLink = ({ children, hoverColor = {}, ...props }) => {
	return (
		<Link href={props?.href ?? '/'}>
			<StyledLink
				rel="noopener noreferrer"
				{...props}
				hoverColor={hoverColor}
			>
				{children}
			</StyledLink>
		</Link>
	);
};

export default withTheme(BaseLink);

BaseLink.propTypes = {
	href: PropTypes.string,
	children: PropTypes.node,
};
