import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { useWindowScroll } from 'react-use';
import FocusLock from 'react-focus-lock';

import {
	BaseLink,
	HamburgerButton,
	SearchButton,
	Logo,
	HamburgerMenu,
} from 'shared/components';
import {
	HeaderWrapper,
	MenuContainer,
	HeaderLinkContainer,
} from './Header.styles';

import { getPageByUrl } from 'api';
import { ColumnContainer } from 'shared/layout';

import { removeQueryString } from 'shared/helpers';
import HeaderSearch from './HeaderSearch';
import { useOuterClick } from 'shared/hooks';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

const Header = ({
	theme = {},
	Items = [],
	NavigationLabel = '',
	SearchButtonLabel = '',
	MenuButtonLabel = '',
	MenuButtonCloseLabel = '',
	NavigationLinks = [],
	SearchPageUrl = '',
	SearchPlaceholder = '',
	EnglishSiteLabel = '',
	EnglishSiteUrl = '',
}) => {
	const { asPath } = useRouter();
	const urlToGet = removeQueryString(asPath);
	const { data: page } = useQuery(urlToGet, () => getPageByUrl(urlToGet));
	const [openMenu, setOpenMenu] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const isLargeUp = useMediaQuery(
		`(min-width: ${theme.breakpoints.lg})`,
		true,
	);
	const offset = useWindowScroll().y;

	const handleSearchButtonClick = (e) => {
		e.preventDefault();
		if (openMenu) setOpenMenu(false);
		setIsSearchOpen(!isSearchOpen);
	};
	const handleMenuButtonClick = (e) => {
		e.preventDefault();
		if (isSearchOpen) setIsSearchOpen(false);
		setOpenMenu(!openMenu);
	};

	const innerRef = useOuterClick(() => {
		if (isSearchOpen) setIsSearchOpen(false);
	});

	return (
		<HeaderWrapper
			offset={
				page?.navigationWhiteBackground
					? 'true'
					: (offset !== 0).toString()
			}
			aria-label={NavigationLabel}
		>
			{isSearchOpen && (
				<HeaderSearch
					ref={innerRef}
					SearchPageUrl={SearchPageUrl}
					isSearchOpen={isSearchOpen}
					SearchPlaceholder={SearchPlaceholder}
					setOpenSearch={handleSearchButtonClick}
				/>
			)}
			<ColumnContainer
				max-width={theme.spacings.siteWidth}
				margin="0 auto"
				flex-direction="row"
				flex-wrap="nowrap"
				justify-content="space-between"
				align-items="center"
				height="100%"
				noGutters
			>
				<Logo
					padding={{ base: theme.spacings[4], lg: 0 }}
					type="header-logo"
					color={
						isSearchOpen
							? theme.colors.white
							: theme.colors.secondary
					}
				/>
				{isLargeUp && !!Items.length && (
					<HeaderLinkContainer aria-label="Ingångslänkar">
						{Items.map((item, ix) => {
							return (
								<BaseLink
									display={{ base: 'none', lg: 'inline' }}
									key={ix}
									margin={`0 ${theme.spacings[5]}`}
									href={item.Url}
								>
									{item.Name}
								</BaseLink>
							);
						})}
					</HeaderLinkContainer>
				)}
				<MenuContainer>
					<SearchButton
						title={SearchButtonLabel}
						isOpen={isSearchOpen}
						margin-right={theme.spacings[10]}
						className="white-tab"
						onClick={(e) => handleSearchButtonClick(e)}
					/>
					<FocusLock
						group="hamburgerMenu"
						className="zindex"
						disabled={!openMenu}
					>
						<HamburgerButton
							title={MenuButtonLabel}
							closeTitle={MenuButtonCloseLabel}
							isOpen={openMenu}
							handleMenuClick={(e) => handleMenuButtonClick(e)}
							className="white-tab"
						/>
					</FocusLock>
				</MenuContainer>
			</ColumnContainer>
			<HamburgerMenu
				isOpen={openMenu}
				links={NavigationLinks}
				handleMenuClick={() => setOpenMenu(!openMenu)}
				{...{ EnglishSiteUrl, EnglishSiteLabel }}
			/>
		</HeaderWrapper>
	);
};

Header.propTypes = {
	Items: PropTypes.array,
	NavigationLabel: PropTypes.string,
	SearchButtonLabel: PropTypes.string,
	MenuButtonLabel: PropTypes.string,
	SearchPageUrl: PropTypes.string,
	SearchPlaceholder: PropTypes.string,
	EnglishSiteUrl: PropTypes.string,
	EnglishSiteLabel: PropTypes.string,
};

export default withTheme(Header);
