import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { StyledFilterTextButton } from './FilterTextButton.styles';

const FilterTextButton = ({ children = {}, isPressed = false, ...rest }) => {
	return (
		<StyledFilterTextButton {...{ isPressed }} {...rest}>
			<span>{children}</span>
		</StyledFilterTextButton>
	);
};

export default withTheme(FilterTextButton);
FilterTextButton.propTypes = {
	children: PropTypes.string,
	isPressed: PropTypes.bool,
};
