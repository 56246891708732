import React, { useRef, useEffect } from 'react';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'shared/themes';
import { tabHighlight } from 'shared/helpers';
import GlobalStyle from 'shared/globalStyle';
import IconSheet from 'shared/globalStyle/IconSheet';
import './style.css';

function App({ Component, pageProps }) {
	const queryClientRef = useRef();
	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient({
			defaultOptions: {
				queries: {
					staleTime: 1000 * 60 * 1,
				},
			},
		});
	}

	useEffect(() => {
		/**
		 * Initialize tab highlight helper
		 * It will add "is-tabbed" body class when the user press tab-key.
		 */
		tabHighlight().init();
	}, []);

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"
				/>
			</Head>
			<ThemeProvider theme={defaultTheme}>
				<GlobalStyle />
				<QueryClientProvider client={queryClientRef.current}>
					<Component {...pageProps} />
				</QueryClientProvider>
				<IconSheet />
			</ThemeProvider>
		</>
	);
}

export default App;
